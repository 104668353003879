import { uniq, sum, sumBy, isArray } from 'lodash'
import { getValue } from 'utils/data'
import { certificationLabel } from 'data/certificationTypes'

export const getCertifications = ({ certifications }) =>
  !certifications.items
    ? '—'
    : Object.values(certifications.items)
        .map(cert => certificationLabel(getValue(cert), false))
        .join('\n')

export const getBuildingTypes = prop =>
  uniq(
    Object.values(prop.buildings.items).map(
      building => building.buildingType.displayValue
    )
  ).join(', ')

export const getAverageEnergyClass = prop => {
  const arr = (isArray(prop) ? prop : [prop])
    .flatMap(p => Object.values(p.buildings.items ?? {}))
    .map(building => getValue(building.energyClass))
    .filter(a => a)
    .map(char => char.charCodeAt(0))

  return arr.length
    ? String.fromCharCode(Math.round(sum(arr) / arr.length))
    : undefined
}

export const getArea = prop =>
  sumBy(
    Object.values(prop.buildings.items ?? {}),
    building => getValue(building.floorArea) ?? 0
  )

import { useContext, useMemo } from 'react'
import { ThemeContext } from 'styled-components'
import { BarChart } from 'components/Chart'
import { shade, transparentize } from 'polished'
import { histogram, getValue } from 'utils/data'
import { generateLegendLabelFromSeriesConfig } from './chartUtils'

const EnergyClassBarChart = ({ buildings, areaHeight }) => {
  const theme = useContext(ThemeContext)
  const chartParams = useMemo(
    () => getChartParams(buildings, areaHeight, theme),
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [buildings]
  )

  return <BarChart {...chartParams} />
}

export default EnergyClassBarChart

const getChartParams = (buildings, areaHeight, theme) => {
  const labels = 'ABCDEFG?'.split('')

  const numberOfBuildings = histogram(
    buildings,
    labels,
    item => getValue(item.energyClass) ?? '?'
  )

  const totalArea = histogram(buildings, labels, item => ({
    bucket: getValue(item.energyClass) ?? '?',
    value: getValue(item.tempArea) ?? getValue(item.floorArea) ?? 0,
  }))

  const backgroundColor = labels.map(cls =>
    cls !== '?' ? theme.colors.energyClass[cls] : theme.colors.light
  )

  const datasets = [
    {
      label: 'Yta [m2]',
      data: totalArea,
      type: 'line',
      yAxisID: 'yArea',
      borderColor: transparentize(0.5, theme.colors.blue),
    },
    {
      label: 'Antal byggnader',
      data: numberOfBuildings,
      backgroundColor,
      borderColor: backgroundColor.map(c => shade(0.15, c)),
      borderWidth: 2,
      yAxisID: 'yNumber',
    },
  ]

  const chartOptions = {
    plugins: {
      legend: {
        position: 'top',
        align: 'end',
        labels: {
          usePointStyle: true,
          generateLabels: generateLegendLabelFromSeriesConfig,
        },
      },
    },
    scales: {
      yNumber: {
        beginAtZero: true,
        suggestedMax: 2,
        position: 'left',
        ticks: {
          callback: value => (value % 1 === 0 ? value : undefined),
        },
        afterFit: axis => {
          axis.paddingBottom = axis.height - areaHeight
        },
      },
      yArea: {
        beginAtZero: true,
        suggestedMax: 1000,
        position: 'right',
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  }

  return {
    data: { datasets, labels },
    options: chartOptions,
  }
}

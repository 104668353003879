import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { toPlural } from 'utils/data'
import Button from 'components/Button'
import Tooltip from 'components/Tooltip'
import BaseSection, {
  ListFooter,
  Empty,
  RowWrapper,
  getTooltipText,
} from 'components/Section/BaseSection'

const Title = styled.div`
  flex-shrink: 1;
  flex-grow: 0;
  margin-right: 1rem;
  font-weight: 500;
`

const Row = ({ title, missing, inadequate, link, ...args }) => {
  const tooltip = getTooltipText({ label: title, missing, inadequate })
  return (
    <RowWrapper missing={missing} inadequate={inadequate}>
      {link ? (
        <Title>
          <Link to={link}>{title}</Link>
          {tooltip && <Tooltip contents={tooltip} />}
        </Title>
      ) : (
        <Title>
          {title}
          {tooltip && <Tooltip contents={tooltip} />}
        </Title>
      )}
    </RowWrapper>
  )
}

// TODO: Delete linked object
const DocumentsSection = ({
  title,
  type,
  emptyLabel,
  createLabel,
  collection = {},
  qualificationResults,
  add,
  editable,
}) => {
  const collectionId = toPlural(type)

  // NB we only qualify for existence here, whether or not the document
  // fulfills the requirements is handled in DocumentDetails
  const { missing } = qualificationResults ?? {}
  const items = Object.entries(collection ?? {}).map(([id, item]) => ({
    id,
    title: item.label,
    link: `/document/${id}`,
  }))

  return (
    <BaseSection title={title}>
      {missing?.[collectionId] ? (
        <Row key="missing" title={emptyLabel} missing={missing[collectionId]} />
      ) : items.length === 0 && !(collection.editable && editable) ? (
        <Empty>{`(${emptyLabel})`}</Empty>
      ) : (
        ''
      )}
      {items.map((item, idx) => (
        <Row key={idx} {...item} />
      ))}
      {editable && (
        <ListFooter>
          <Button onClick={add}>{createLabel}</Button>
        </ListFooter>
      )}
    </BaseSection>
  )
}

export default DocumentsSection

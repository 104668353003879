import config from 'config'
import mixpanel from 'mixpanel-browser'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

const _context = {}

export const init = () => {
  if (config.tracking === false) {
    return
  }
  Sentry.init({
    environment: config.firebase.projectId,
    dsn: config.sentry.dsn,
    integrations: [new BrowserTracing()],
    tracesSampleRate: config.sentry.tracesSampleRate,
    release: config.sentry.release,
  })
  mixpanel.init(config.mixpanel.token, {
    debug: true,
    disable_persistence: true,
  })
}

export const setUser = async (uid, email) => {
  if (config.tracking === false) {
    return
  }
  await Sentry.setUser({ uid, email })
  console.log('Set sentry user, ', uid)
  await mixpanel.identify(uid)
  await mixpanel.people.set({ email })
}

export const setContext = async (id, data) => {
  if (!config.tracking) {
    return
  }
  await Sentry.setContext(id, data)
  console.log('Set sentry context', data)
  _context[id] = data
}

export const track = eventName =>
  config.tracking !== false ? mixpanel.track(eventName, _context) : undefined

import styled from 'styled-components'
import { useState } from 'react'
import Button from 'components/Button'
import Popup from 'components/Popup'

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  button {
    margin: 0 0.5rem;
  }
`

const ExportButton = ({ title, data, actions, disabled, className }) => {
  return (
    <Popup
      icon="download"
      title={title}
      dialogWidth="10rem"
      buttonStyle="normal"
      className={className}
      disabled={disabled}
    >
      <ExportRow actions={actions} data={data} />
    </Popup>
  )
}

const ExportRow = ({ actions, data, close }) => {
  const [loading, setLoading] = useState()

  const download = type => async () => {
    setLoading(type)
    await actions[type].download(data)
    close()
  }

  return (
    <ButtonRow>
      {Object.entries(actions).map(([type, { label, icon }]) => (
        <Button
          key={type}
          icon={icon}
          title={label}
          onClick={download(type)}
          loading={loading === type}
          disabled={!!loading}
        />
      ))}
    </ButtonRow>
  )
}

export default ExportButton

import config from '../../config'
import { Formik, Form } from 'formik'
import styled from 'styled-components'
import { mapValues } from 'lodash'
import { toOptions } from 'utils/data'
import { useUser } from 'providers/Session'
import FormField, { FieldSet } from 'components/form/FormField'
import Button from 'components/Button'
import { getInitialValue, getWriteValue } from '../form/utils'

const StyledForm = styled(Form)`
  ${FieldSet}, ${FormField} {
    margin-bottom: 1rem;
  }

  ${FieldSet} ${FormField} {
    margin-bottom: 0;
  }

  ${Button} {
    margin: 1rem 0;
  }
`

const ButtonRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
`

const AttributeForm = ({
  className,
  id,
  label,
  description,
  placeholder,
  value,
  comment,
  type,
  transform,
  unit,
  options,
  multiple,
  labels,
  section,
  fieldArray,
  source,
  sourced = true,
  commented,
  attachments,
  save,
  close,
  validate,
}) => {
  const { team } = useUser()
  const initialValues = {
    value: getInitialValue({
      value,
      section,
      fieldArray,
      unit,
      type,
      multiple,
      transform,
    }),
    attachment: source?.attachment?.id,
    comment,
  }

  const attachmentOptions = toOptions({
    none: '—',
    ...mapValues(attachments, doc => doc.displayValue),
  })

  const deleteManualInput = () => {
    save({
      id,
      value: undefined,
      attachment: undefined,
      comment: undefined,
    })
    close()
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async ({ value, attachment, comment }, { setSubmitting }) => {
        save({
          id,
          value: getWriteValue({ value, unit, section, type, transform }),
          attachment,
          comment,
        })
        setSubmitting(false)
        close()
      }}
      validateOnMount
    >
      {({ isSubmitting, values, errors, touched, setValues }) => (
        <StyledForm className={className} noValidate>
          <FormField
            name="value"
            label={label}
            description={description}
            placeholder={placeholder}
            unit={unit}
            type={type}
            transform={transform}
            multiple={multiple}
            fields={section}
            env={{ teamId: team }}
            options={options}
            labels={labels}
            validate={validate}
            autoFocus={true}
          />
          {commented && (
            <FormField name="comment" label="Kommentar" type="textArea" />
          )}
          {sourced && type !== 'file' && (
            <FormField
              name="attachment"
              label="Stödjande dokumentation"
              type="select"
              options={attachmentOptions}
            />
          )}
          <ButtonRow>
            <Button
              title={
                isUndefined(initialValues.value)
                  ? null
                  : source?.originalSource
                  ? `Återställ till verifierat värde från "${source.originalSource.label}"`
                  : 'Ta bort ifyllt värde'
              }
              onClick={e => deleteManualInput()}
              disabled={
                isUndefined(initialValues.value) && !source?.originalSource
              }
              inverted={true}
            >
              Återställ
            </Button>
            <Button
              disabled={isSubmitting || Object.keys(errors).length}
              type="submit"
            >
              Spara
            </Button>
          </ButtonRow>
          {config.debug && <pre>{JSON.stringify(initialValues, null, 2)}</pre>}
          {config.debug && <pre>{JSON.stringify(errors, null, 2)}</pre>}
        </StyledForm>
      )}
    </Formik>
  )
}

export default AttributeForm

const isUndefined = value =>
  value === undefined || value === null || value === ''

import config from 'config'
import { createElement } from 'react'
import styled from 'styled-components'
import { theme, ifProp } from 'styled-tools'
import { getValue, getAssetLabel } from 'utils/data'

import UnstyledList from 'components/List'
import { Link } from 'react-router-dom'
import Icon from 'components/Icon'
import Card from 'components/Card'

const List = styled(UnstyledList)`
  li {
    display: block;
    padding: 0;
  }
`

const Progress = styled.div`
  flex-grow: 0;
`

const RowWrapper = styled.div`
  padding: 0.6rem 1.2rem;
  display: flex;
  justify-content: stretch;
  align-items: center;

  background-color: ${props =>
    props.$focus
      ? theme('colors.primaryThin')
      : props.$complete || props.$header
      ? 'transparent'
      : theme('colors.highlight')};
  > a,
  > div:first-child {
    flex-grow: 1;
    display: flex;
    align-items: center;
    > svg {
      margin: 0;
      margin-right: 0.5rem;
    }
    p {
      ${ifProp('$header', 'font-weight: 500;', '')}
      ${ifProp('$focus', 'font-weight: 500;', '')}
      margin: 0;
    }
  }
`

const Div = styled.div``

const Row = ({
  title,
  url,
  header,
  icon,
  progressDisplay,
  focus,
  missing,
  all,
}) => (
  <RowWrapper
    $header={header}
    $focus={focus}
    $complete={missing === 0}
    title={config.debug ? JSON.stringify(all, null, 2) : ''}
  >
    {createElement(url ? Link : Div, { to: url }, [
      icon && <Icon type={icon} key="icon" />,
      <p key="p">{title}</p>,
    ])}
    <Progress>{progressDisplay ?? '—'}</Progress>
  </RowWrapper>
)

const DataStatus = ({ teamData, qualificationResults: result }) => {
  const orgId = Object.keys(teamData.organizations)[0]

  const items = result
    ? [
        {
          id: 'total',
          icon: 'pie',
          title: 'Totalt',
          progress: result.progress,
          progressDisplay: result.progressDisplay,
          header: true,
        },
        {
          id: orgId,
          icon: 'organization',
          title: getValue(teamData.organizations[orgId].name),
          url: `/organization/${orgId}`,
          progressDisplay: result.organizations[orgId]?.progressDisplay,
          progress: result.organizations[orgId]?.progress,
          missing: Object.keys(result.organizations[orgId]?.missing ?? {})
            .length,
        },
        ...Object.entries(teamData.assets)
          .map(([id, asset]) => ({
            id,
            icon: 'building',
            title: getAssetLabel(asset),
            url: `/asset/${id}`,
            progress: result.assets[id]?.progress,
            progressDisplay: result.assets[id]?.progressDisplay,
            missing: Object.keys(result.assets[id]?.missing ?? {}).length,
            all: result.assets[id],
          }))
          .sort((p1, p2) =>
            p1.focus
              ? -1
              : p2.focus
              ? 1
              : 100 * (p1.progress - p2.progress) +
                p1.title.localeCompare(p2.title, 'sv')
          ),
      ]
    : []

  return (
    <Card title="Data">
      <List data={items}>
        <Row />
      </List>
    </Card>
  )
}

export default DataStatus

import { Formik, Form } from 'formik'
import FormField from 'components/form/FormField'
import { getValue } from 'utils/data'
import { closeCase } from 'providers/Session'
import FormPopup, { ButtonRow } from 'components/Popup/FormPopup'
import { Errors } from 'components/form'
import { wrapSubmit } from 'utils/form'

const CloseCaseButton = ({ case: _case, teamId, disabled, className }) => (
  <FormPopup
    label="Avsluta"
    dialogWidth="20rem"
    buttonStyle="normal"
    className={className}
    disabled={disabled}
  >
    <CloseCaseForm case={_case} teamId={teamId} />
  </FormPopup>
)

const CloseCaseForm = ({ teamId, case: _case, close }) => {
  const submit = ({ result, note }) =>
    closeCase({
      teamId,
      result,
      note,
      caseId: _case.id,
    })

  return (
    <Formik
      initialValues={{
        result: getValue(_case.status) !== 'signed' ? 'canceled' : 'closed',
        note: '',
      }}
      onSubmit={wrapSubmit(submit, close, { timeout: 1000 })}
    >
      {({ isSubmitting, values, errors, status, setValues }) => (
        <Form>
          <h2>Avsluta ärende</h2>
          <FormField
            name="result"
            label="Anledning"
            type="select"
            options={[
              {
                value: 'closed',
                label: 'Avslutat',
                disabled: getValue(_case.status) !== 'signed',
              },
              { value: 'canceled', label: 'Avbrutet' },
            ]}
          />
          {/*<FormField name="note" placeholder="Anteckning" type="textArea" />*/}
          <Errors errors={errors} />
          <ButtonRow
            disabled={isSubmitting}
            loading={isSubmitting}
            title="Avsluta"
            done={status?.done}
            successMessage="Ärendet avslutades"
          />
        </Form>
      )}
    </Formik>
  )
}

export default CloseCaseButton

import { useMailbox } from 'providers/Messaging'
import styled from 'styled-components'
import { theme, ifProp } from 'styled-tools'
import List from 'components/List'
import { Link } from 'react-router-dom'
import { formatDate } from 'utils/date'
import Card from 'components/Card'
import Loader from 'components/Loader'

const StyledList = styled(List)``

const StyledLink = styled(Link)`
  display: flex;
  justify-content: stretch;
  width: 100%;
  padding: 0.1rem 0;
`

const Timestamp = styled.div`
  font-weight: 300;
  color: ${theme('colors.medium')};
  margin-right: 0.3rem;
  flex-grow: 0;
  flex-shrink: 0;
  width: 5rem;
`

const Subject = styled.div`
  font-weight: ${ifProp('read', '300', '500')};
  flex-grow: 1;
`

const Message = ({ id, from, subject, timestamp, read }) => (
  <StyledLink to={`/messages/${id}`}>
    <Timestamp>{formatDate(timestamp)}</Timestamp>
    <Subject read={read}>{subject}</Subject>
  </StyledLink>
)

const Inbox = () => {
  const { loading, inbox } = useMailbox()

  return (
    <Card title="Meddelanden">
      {loading ? (
        <Loader />
      ) : (
        <StyledList data={inbox} emptyLabel="(Inga meddelanden)">
          <Message />
        </StyledList>
      )}
    </Card>
  )
}

export default Inbox

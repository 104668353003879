import { getOrgName, getOrgNr } from 'utils/data'
import transform from './transform'

export const statusLabels = {
  requested: 'Efterfrågat',
  open: 'Öppet',
  denied: 'Avslaget',
  signed: 'Avstämt av kund',
  closed: 'Avslutat',
  canceled: 'Avbrutet',
}

const formatOrg = ({ name, orgNr } = {}) => `${name.value} (${orgNr.value})`

const meta = {
  snapshots: 'primitive',
  data: 'primitive',

  reporter: {
    label: 'Kund',
    editable: false,
    sourced: false,
    format: (value, { team }) => formatOrg(team.reporters[value]),
  },
  creditor: {
    label: 'Kreditgivare',
    editable: false,
    sourced: false,
    format: (value, { team }) =>
      value === team.id
        ? `${getOrgName(team)} (${getOrgNr(team)})`
        : formatOrg(team.creditors?.[value]),
  },
  timestamp: {
    label: 'Tidsstämpel',
    type: 'date',
    sourced: false,
    editable: false,
  },
  title: {
    label: 'Titel',
    sourced: false,
    editable: false,
  },
  status: {
    label: 'Status',
    sourced: false,
    editable: false,
    format: value => statusLabels[value],
  },

  caseComment: {
    label: 'Aktuellt ärende',
    description: `Sammanfattande kommentar på aktuellt ärende för att ge kontext och förklaringar till den rapporterade datan.`,
    type: 'textArea',
  },

  reporterComment: {
    label: 'Kundens hållbarhetsarbete',
    description: `Eventuella ytterligare kommentarer kring kundens nuvarande arbete med hållbarhet som inte fångas av datan i ärendet.`,
    type: 'textArea',
  },

  futurePlansComment: {
    label: 'Kundens framtida planer',
    description: `Eventuella kommentarer kring kundens framtidsplaner avseende hållbarhetsarbete.`,
    type: 'textArea',
  },

  assetSpecificComments: {
    type: 'list',
    label: 'Fastighetsspecifika kommentarer',
  },

  attachments: {
    label: 'Dokument',
    type: 'list',
    itemType: 'section',
    formatItem: ({ name }) => name,
    fields: {
      name: {
        label: 'Namn',
      },
      description: {
        label: 'Beskrivning',
        type: 'textArea',
      },
    },
    sourced: false,
  },
}

export default transform(meta)

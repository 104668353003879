import { nanoid } from 'utils/data'
import { useUser } from 'providers/Session'
import { download } from 'utils/storage'
import Popup from 'components/Popup'
import { NakedButton } from 'components/Button'
import BaseSection, {
  ToolsWrapper,
  StyledList,
  ItemTitle,
  Empty,
} from './BaseSection'
import AttributeForm from './AttributeForm'
import FileInput from 'components/form/FileInput'

const Tools = ({
  id,
  label,
  description,
  value,
  type,
  unit,
  labels,
  options,
  section,
  sourced,
  canDelete,
  editable,
  filePath,
  save,
  delete: _delete,
}) => {
  const { team } = useUser()

  const downloadAttachment = ({ hash, name, type }) => {
    download(`${filePath ?? team}/${hash}`, name, type)
  }

  return (
    <ToolsWrapper>
      <NakedButton icon="download" onClick={e => downloadAttachment(value)} />
      {editable && [
        <Popup key={0} icon="pencil">
          <AttributeForm
            id={id}
            label={'Dokumentinformation'}
            type={type}
            value={value}
            section={section}
            sourced={sourced}
            save={save}
          />
        </Popup>,
        <NakedButton
          key={1}
          icon="trash"
          kind="warning"
          onClick={e => _delete(id)}
          disabled={!canDelete}
          title={
            canDelete
              ? 'Ta bort dokument'
              : 'Kan inte ta bort, dokumentet används i en eller flera datakällor'
          }
        />,
      ]}
    </ToolsWrapper>
  )
}

const AttachmentsSection = ({
  title,
  attachments,
  parent,
  editable = true,
  save,
  filePath,
}) => {
  const { team } = useUser()

  const uploaded = async value => {
    await saveMeta({ id: nanoid(), value })
  }

  const saveMeta = ({ id, value }) =>
    save({ attrName: 'attachments', itemId: id, value })

  const deleteAttachment = id =>
    save({ attrName: 'attachments', itemId: id, value: undefined })

  const items = Object.entries(attachments?.items ?? {})
    .map(([id, item]) => ({
      id,
      canDelete: editable && !hasAttachmentLinks(parent, id),
      ...item,
    }))
    .sort((i1, i2) => i1.displayValue.localeCompare(i2.displayValue, 'sv'))

  return (
    <BaseSection title={title}>
      {items.length === 0 && !(attachments?.editable && editable) && (
        <Empty>(Inga dokument)</Empty>
      )}
      <StyledList data={items}>
        <ItemTitle />
        <Tools
          save={saveMeta}
          delete={deleteAttachment}
          editable={attachments?.editable && editable}
          filePath={filePath}
        />
      </StyledList>
      {attachments?.editable && editable && (
        <FileInput
          env={{ teamId: team }}
          onChange={e => uploaded(e.target.value)}
        />
      )}
    </BaseSection>
  )
}

export default AttachmentsSection

const hasAttachmentLinks = (obj, attachmentId) =>
  Object.values(obj).some(
    ({ source, items } = {}) =>
      source?.attachment?.id === attachmentId || // Root attribute
      (items &&
        Object.values(items).some(
          ({ source, ...item } = {}) =>
            source?.attachment?.id === attachmentId || // List item
            Object.values(item ?? {}).some(
              ({ source } = {}) => source?.attachment?.id === attachmentId // Subcollection item attribute
            )
        ))
  )

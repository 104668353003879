export const isInteger = value =>
  value !== undefined && parseInt(value) != value //eslint-disable-line eqeqeq
    ? 'Måste vara ett heltal'
    : undefined

export const isNumber = value =>
  value !== undefined && parseFloat(value) != value //eslint-disable-line eqeqeq
    ? 'Måste vara ett nummer'
    : undefined

export const isNumberBetween = (min, max) => value =>
  value !== undefined &&
  (parseFloat(value) != value || value < min || value > max) //eslint-disable-line eqeqeq
    ? `Måste vara ett nummer mellan ${min} och ${max}`
    : undefined

export const isIntegerBetween = (min, max) => value =>
  value !== undefined &&
  (parseInt(value) != value || value < min || value > max) //eslint-disable-line eqeqeq
    ? `Måste vara ett nummer mellan ${min} och ${max}`
    : undefined

export const isRequired = (value, env) =>
  (Array.isArray(value) && value.length === 0) || value === '' || value === null
    ? `Obligatoriskt fält`
    : undefined

export const matches = (pattern, message) => value =>
  value !== undefined && (!value || !String(value).match(pattern))
    ? message
    : undefined

export const isOrgNr = value =>
  matches(/^\d{6}-\d{4}$/, 'Måste ha formen XXXXXX-XXXX')

import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTeam } from 'providers/Session'
import { getFirebase } from 'utils/firebase'
import { toPlural } from 'utils/data'

const Main = styled.main`
  height: 100vh;
  overflow: auto;
`

const Debug = ({ match }) => {
  const { itemType, itemId } = match.params
  const { id } = useTeam()
  const [data, setData] = useState({})
  useEffect(() => {
    const { get } = getFirebase()
    if (id) {
      get(`teams/${id}/${toPlural(itemType)}/${itemId}`).then(setData)
    }
  }, [id, itemType, itemId])

  return (
    <Main>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </Main>
  )
}

export default Debug

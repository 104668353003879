import { createElement } from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { useTeam, useUser } from 'providers/Session'
import Page from 'components/Page'
import Loader from 'components/Loader'
import Card from 'components/Card'
import Button from 'components/Button'
import Markdown from 'components/Markdown'
import Inbox from './InboxWidget'
import AssetList from './AssetListWidget'
import Completeness from './CompletenessWidget'
import Report from './ReportWidget'
import CaseList from './CaseListWidget'
// import NewReporter from './NewReporterWidget'
import ReporterList from './ReporterListWidget'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: ${theme('breakpoints.lg')}) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${theme('breakpoints.md')}) {
    grid-template-columns: 1fr;
  }
  column-gap: 2rem;
`

const ReadMe = styled.div`
  padding: 1rem;
`

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
`

const reporterWidgets = [Report, Completeness, Inbox, AssetList]
const creditorWidgets = [CaseList, Inbox, ReporterList]

const Dashboard = () => {
  const { loading: loadingUser, preferences, setPreferences } = useUser()
  const { loading: loadingTeam, teamData } = useTeam()
  const loading = loadingUser || loadingTeam

  const hideReadMe = () => {
    console.log('Hiding readme')
    setPreferences({ hideReadMe: true })
  }

  return (
    <Page title="Hem">
      {loading ? (
        <Loader />
      ) : (
        <>
          {!preferences?.hideReadMe && false && (
            <Card title="Välkommen till Sciigo">
              <ReadMe>
                <Markdown>Här står det hur man använder sciigo</Markdown>
                <ButtonRow>
                  <Button onClick={hideReadMe}>Ok, visa inte mer</Button>
                </ButtonRow>
              </ReadMe>
            </Card>
          )}
          <Grid>
            {(teamData.type === 'creditor'
              ? creditorWidgets
              : reporterWidgets
            ).map((widget, idx) => createElement(widget, { key: idx }))}
          </Grid>
        </>
      )}
    </Page>
  )
}

export default Dashboard

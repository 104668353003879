const Building = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M6 2h12a2 2 0 0 1 2 2v18H4V4a2 2 0 0 1 2-2Zm0 18h4v-4h4v4h4V4H6v16Zm7-14h3v3h-3V6Zm-5 5h3v3H8v-3Zm5 0h3v3h-3v-3ZM8 6h3v3H8V6Z"
    ></path>
  </svg>
)

export default Building

import styled from 'styled-components'
import { useMailbox, useMessages } from 'providers/Messaging'

import Page from 'components/Page'
import Card from 'components/Card'
import Debug from 'components/Debug'
import List, { EmptyLabel } from 'components/List'
import Loader from 'components/Loader'
import Message from './Message'

const StyledList = styled(List)`
  li {
    padding: 0rem 1.2rem;
  }
  ${EmptyLabel} {
    text-align: center;
    padding: 0.5rem;
  }
`

const Messages = ({ match }) => {
  const { id: messageId } = match.params
  const { loading, inbox, outbox, setRead } = useMailbox()
  const { deleteMessage } = useMessages()

  return (
    <Page title="Meddelanden">
      {loading ? (
        <Loader />
      ) : (
        <>
          <Card title="Inkorg">
            <StyledList data={inbox} emptyLabel="(Inga meddelanden)">
              <Message
                deleteMessage={deleteMessage}
                openMessageId={messageId}
                setRead={setRead}
              />
            </StyledList>
          </Card>
          <Card title="Utkorg">
            <StyledList data={outbox} emptyLabel="(Inga meddelanden)">
              <Message
                deleteMessage={deleteMessage}
                openMessageId={messageId}
                setRead={setRead}
              />
            </StyledList>
          </Card>
          <Debug value={{ inbox, outbox }} />
        </>
      )}
    </Page>
  )
}

export default Messages

import { findKey } from 'lodash'

const certificationTypes = {
  breeam: {
    label: 'BREEAM',
    levels: {
      unclassified: 'Unclassified',
      pass: 'Pass',
      good: 'Good',
      veryGood: 'Very Good',
      excellent: 'Excellent',
      outstanding: 'Outstanding',
    },
  },
  breeamSe: {
    label: 'BREEAM-SE',
    levels: {
      unclassified: 'Unclassified',
      pass: 'Pass',
      good: 'Good',
      veryGood: 'Very Good',
      excellent: 'Excellent',
      outstanding: 'Outstanding',
    },
  },
  breeamInUse: {
    label: 'BREEAM In-Use',
    levels: {
      unclassified: 'Unclassified',
      pass: 'Pass',
      good: 'Good',
      veryGood: 'Very Good',
      excellent: 'Excellent',
    },
  },
  greenBuilding: { label: 'Green Building' },
  leed: {
    label: 'LEED',
    levels: {
      certified: 'Certified',
      silver: 'Silver',
      gold: 'Gold',
      platinum: 'Platinum',
    },
  },
  miljobyggnad: {
    label: 'Miljöbyggnad',
    levels: {
      bronze: 'Brons',
      silver: 'Silver',
      gold: 'Guld',
    },
  },
  miljobyggnadIDrift: {
    label: 'Miljöbyggnad iDrift',
    levels: {
      bronze: 'Brons',
      silver: 'Silver',
      gold: 'Guld',
    },
  },
  miljobyggnadPreliminar: {
    label: 'Miljöbyggnad (preliminär cerifiering)',
    levels: {
      bronze: 'Brons',
      silver: 'Silver',
      gold: 'Guld',
    },
  },
  nollCo2: {
    label: 'Noll CO2',
  },
  svanen: { label: 'Svanen' },
}

export const unifiedLevels = {
  unclassified: {
    label: 'Unclassified',
    fit: [
      { types: ['breeam', 'breeamSe', 'breeamInUse'], level: 'unclassified' },
    ],
  },
  pass: {
    label: 'Pass/Brons/Godkänd',
    fit: [
      { types: ['breeam', 'breeamSe', 'breeamInUse'], level: 'pass' },
      { types: ['greenBuilding', 'svanen'] },
      { types: ['lead'], level: 'certified' },
      {
        types: ['miljobyggnad', 'miljobyggnadIDrift', 'miljobyggnadPreliminar'],
        level: 'brons',
      },
    ],
  },
  good: {
    label: 'Good/Silver/Godkänd',
    fit: [
      { types: ['breeam', 'breeamSe', 'breeamInUse'], level: 'good' },
      { types: ['nollCo2'] },
      { types: ['lead'], level: 'silver' },
      {
        types: ['miljobyggnad', 'miljobyggnadIDrift', 'miljobyggnadPreliminar'],
        level: 'silver',
      },
    ],
  },
  veryGood: {
    label: 'Very Good/Gold/Guld',
    fit: [
      { types: ['breeam', 'breeamSe', 'breeamInUse'], level: 'veryGood' },
      { types: ['lead'], level: 'gold' },
      {
        types: ['miljobyggnad', 'miljobyggnadIDrift', 'miljobyggnadPreliminar'],
        level: 'gold',
      },
    ],
  },
  excellent: {
    label: 'Excellent/Platinum',
    fit: [
      { types: ['breeam', 'breeamSe', 'breeamInUse'], level: 'excellent' },
      { types: ['lead'], level: 'platinum' },
    ],
  },
  outstanding: {
    label: 'Outstanding',
    fit: [
      { types: ['breeam', 'breeamSe', 'breeamInUse'], level: 'outstanding' },
    ],
  },
  none: {
    label: 'Ocertifierad',
    fit: [],
  },
}

// Find common cert level (for graph) for a specific cert level (from data)
export const getUnifiedLevel = (type, level) =>
  findKey(unifiedLevels, ({ fit }, key) =>
    fit.some(
      ({ types, level: _level }) =>
        types.includes(type) && (level === _level || (!level && !_level))
    )
  )

export const certificationLabel = (
  { type, level, dateIssued },
  includeDate = true
) =>
  `${certificationTypes[type]?.label}${
    certificationTypes[type]?.levels?.[level]
      ? `—${certificationTypes[type].levels?.[level]}`
      : ``
  }${includeDate ? ` (${dateIssued?.toLocaleDateString?.('sv')})` : ''}`

export default certificationTypes

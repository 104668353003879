import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { mapValues } from 'lodash'
import { Formik, Form } from 'formik'
import { getFirebase } from 'utils/firebase'
import Button from 'components/Button'
import FormField from 'components/form/FormField'
import actions from './actions'

// Fogelvik: 556628-4831

const StyledButton = styled(Button)`
  width: 10rem;
  padding: 0.6rem;
  margin: 0;
`

const StyledForm = styled(Form)`
  margin: 1rem 0;
  > div {
  }

  ${FormField} {
    display: flex;
    label {
      width: 15rem;
      flex-grow: 0;
    }
    label + div,
    input,
    textarea {
      width: auto;
      flex-grow: 1;
    }
  }

  ${StyledButton} {
    flex-grow: 0;
  }
`

const ErrorMessage = styled.div`
  color: red;
  white-space: pre-wrap;
`

const Title = styled.h2`
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1rem;
  margin-top: 2rem;
`

const Action = ({ meta }) => {
  const [result, setResult] = useState({})
  const initialValues = meta.initialValues ?? mapValues(meta.form, v => '')
  const { db } = getFirebase()
  const { fnid } = result

  useEffect(() => {
    if (fnid) {
      console.log(`Fnid is ${fnid}, subscribing`)
    }
    const unsubscribe = fnid
      ? db.doc(`functions/${fnid}`).onSnapshot(doc => {
          const data = doc.data()
          if (data.error) {
            setResult(data)
          } else if (data.done) {
            setResult(data)
          } else {
            setResult({ fnid, ...data })
          }
        })
      : null

    return () => {
      console.log('Unsubscribing')
      unsubscribe?.()
    }
  }, [db, fnid])

  return (
    <div>
      <Title>{meta.title}</Title>
      <Formik
        initialValues={initialValues}
        onSubmit={async (data, { setSubmitting, setErrors }) => {
          try {
            const result = await meta.submit(data)
            if (result?.data?.fnid) {
              setResult({ fnid: result.data.fnid })
            } else {
              setResult(result)
            }
          } catch (e) {
            console.error(e)
            setErrors({ code: e.code, message: e.message, data: e.data })
          }
          setSubmitting(false)
        }}
      >
        {({ isSubmitting, value, errors }) => (
          <StyledForm>
            <div>
              {Object.entries(meta.form).map(
                ([id, { type, label, ...field }]) => (
                  <FormField
                    {...field}
                    type={type}
                    key={id}
                    label={label ?? id}
                    name={id}
                  />
                )
              )}
              <StyledButton disabled={isSubmitting} type="submit">
                Go!
              </StyledButton>
            </div>
            <div>
              {result && <pre>{JSON.stringify(result, null, 2)}</pre>}
              {Object.keys(errors).length ? (
                <ErrorMessage>{JSON.stringify(errors, null, 2)}</ErrorMessage>
              ) : (
                ''
              )}
            </div>
          </StyledForm>
        )}
      </Formik>
    </div>
  )
}

const Page = styled.div`
  overflow: auto;
  height: 100%;
  padding-bottom: 2rem;
  > div {
    max-width: 60rem;
    margin: 1rem auto;
  }
`

const Admin = props => {
  return (
    <Page>
      <div>
        <Title>Test Error Reporting</Title>
        <StyledButton
          onClick={e => {
            throw new Error('Test Error from Admin page')
          }}
        >
          Go!
        </StyledButton>
      </div>
      {Object.entries(actions).map(([id, meta]) => (
        <Action key={id} meta={meta} />
      ))}
    </Page>
  )
}

export default Admin

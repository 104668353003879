export const wrapSubmit =
  (submit, close, { timeout }) =>
  async (data, { setSubmitting, setStatus, setErrors }) => {
    try {
      await submit(data)
      if (timeout) {
        setStatus({ done: true })
        setTimeout(close, timeout)
      } else {
        close()
      }
    } catch (e) {
      console.error(e)
      setErrors({
        [e.code ?? 'err']: {
          message: 'Något gick fel',
          code: e.code,
          originalMessage: e.message,
        },
      })
    }
    setSubmitting(false)
  }

import { Fragment } from 'react'
import styled from 'styled-components'
import { theme, ifProp } from 'styled-tools'
import { Link } from 'react-router-dom'
import { max } from 'lodash'
import { getValue, getAssetLabel } from 'utils/data'
import { formatDate } from 'utils/date'
import Card from 'components/Card'
import Icon from 'components/Icon'
import EnergyClass from 'components/EnergyClass'
import Markdown, { InlineMarkdown } from 'components/Markdown'
import QualificationPie from './QualificationPie'
import EnergyClassBarChart from './EnergyClassBarChart'
import CertificationBarChart from './CertificationBarChart'
import { getSummaryKpis } from './kpis'
import KpiTable from './KpiTable'
import {
  getCertifications,
  getBuildingTypes,
  getAverageEnergyClass,
} from './utils'
// import Debug from 'components/Debug'

const Wrapper = styled.div`
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 2rem;
  align-items: start;
`

const Kpis = styled.div``

const OrgTitleLink = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: middle;
  svg {
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0;
    margin-right: 0.5rem;
  }
  h2 {
    margin: 0;
  }
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const DataCoverage = styled.div`
  min-width: 0;
`

const GraphWrapper = styled.div`
  min-width: 0;
  > div {
    height: 350px;
  }
`

const Review = styled.div`
  grid-column-start: 1;
  grid-column-end: span 2;
  display: grid;
  grid-template-columns: 15rem auto;
  row-gap: 1rem;
  h3 {
    grid-column-start: 1;
    grid-column-end: span 2;
  }
`
const ReviewCommentLabel = styled(InlineMarkdown)`
  * {
    font-weight: 500;
  }
`
const ReviewComment = styled(Markdown)`
  p:first-child {
    margin-top: 0;
  }
`

const ObjectList = styled.div`
  grid-column-start: 1;
  grid-column-end: span 2;
`

const ObjectTable = styled.div`
  margin-top: 1rem;
  row-gap: 0.3rem;
`

const Row = styled.div`
  display: grid;
  grid-template-columns: 25% 25% 10rem auto 10rem;
  column-gap: 0;
  align-items: center;
  p {
    white-space: pre-wrap;
  }
  > a {
    display: flex;
    align-items: center;
    > svg {
      margin: 0;
      margin-right: 0.5rem;
    }
    p {
      ${ifProp('$header', 'font-weight: 500;', '')}
      margin: 0;
    }
  }
  > ${EnergyClass} {
    font-size: 1rem;
    line-height: 1.5;
    height: 1.5rem;
    width: 1.5rem;
  }
  > a > p {
    font-weight: ${ifProp('$focus', 500, 300)};
  }

  background-color: ${ifProp(
    '$focus',
    theme('colors.highlight'),
    'transparent'
  )};
`

const HeaderRow = styled(Row)`
  font-weight: 500;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid gray;
`

const SummaryReport = ({
  organizations,
  assets,
  documents,
  case: _case,
  qualificationResults: result,
}) => {
  const orgId = Object.keys(organizations)[0]
  const kpis = getSummaryKpis({
    organizations,
    assets,
    documents,
    qualificationResults: result,
  })

  const title = _case
    ? `Rapport ${formatDate(max(Object.values(_case.snapshots ?? {})))}`
    : 'Rapport'
  const path = _case ? `/case/${_case.id}` : ''

  const assetItems = [
    ...Object.entries(assets).map(([id, prop]) => ({
      id,
      icon: 'building',
      title: getAssetLabel(prop),
      type: getBuildingTypes(prop),
      energyClass: getAverageEnergyClass(prop),
      certification: getCertifications(prop),
      url: `${path}/asset/${id}`,
      dataCoverage: result.assets[id]
        ? `${(result.assets[id].progress * 100).toFixed()} %`
        : '—',
      focus: _case?.data?.asset && id === _case.data.asset,
    })),
  ].sort(({ focus: f1, energyClass: e1 }, { focus: f2, energyClass: e2 }) =>
    f1 ? -1 : f2 ? 1 : `${e1}`.localeCompare(`${e2}`, 'sv')
  )

  const allBuildings = Object.values(assets).flatMap(asset =>
    Object.values(asset.buildings.items)
  )

  return (
    <Card title={title}>
      <Wrapper>
        <Kpis>
          <OrgTitleLink to={`${path}/organization/${orgId}`}>
            <Icon type="organization" />
            <h2>{getValue(organizations[orgId].name)}</h2>
          </OrgTitleLink>
          <KpiTable items={Object.values(kpis)} />
        </Kpis>
        <DataCoverage>
          <QualificationPie
            values={[result.progress, 0, 1 - result.progress]}
            detailsPath={_case ? undefined : '/completeness'}
          />
        </DataCoverage>
        <GraphWrapper>
          <h3>Fastigheter per Energiklass</h3>
          <EnergyClassBarChart buildings={allBuildings} areaHeight={200} />
        </GraphWrapper>
        <GraphWrapper>
          <h3>Fastigheter per Certifieringsnivå</h3>
          <CertificationBarChart assets={assets} areaHeight={200} />
        </GraphWrapper>
        {_case && (
          <Review>
            <h3>Rådgivarens Kommentarer</h3>
            {['caseComment', 'reporterComment', 'futurePlansComment'].map(
              key => (
                <Fragment key={key}>
                  <ReviewCommentLabel>{_case[key].label}</ReviewCommentLabel>
                  <ReviewComment>
                    {_case[key].displayValue ?? '(Inga kommentarer)'}
                  </ReviewComment>
                </Fragment>
              )
            )}
          </Review>
        )}
        <ObjectList>
          <h3>Fastigheter</h3>
          <ObjectTable>
            <HeaderRow>
              <div>Fastighet</div>
              <div>Typ</div>
              <div>Energiklass</div>
              <div>Certifiering</div>
              <div>Datatäckning</div>
            </HeaderRow>
            {assetItems.map(
              ({
                id,
                url,
                title,
                type,
                energyClass,
                certification,
                dataCoverage,
                focus,
              }) => (
                <Row key={id} $focus={focus}>
                  <Link to={url}>
                    <Icon type="building" key="icon" />
                    <p key="p">{title}</p>
                  </Link>
                  <p>{type}</p>
                  <EnergyClass value={energyClass} />
                  <p>{certification}</p>
                  <p>{dataCoverage}</p>
                </Row>
              )
            )}
          </ObjectTable>
        </ObjectList>
      </Wrapper>
      {/*<Debug value={items}/>*/}
    </Card>
  )
}

export default SummaryReport

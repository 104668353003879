import { createElement, useEffect } from 'react'
import styled from 'styled-components'
import { theme, ifProp } from 'styled-tools'
import { Link, useHistory } from 'react-router-dom'
import { formatDate } from 'utils/date'
import { NakedButton } from 'components/Button'
import Markdown from 'components/Markdown'

import actions from './actions'

const Wrapper = styled.article`
  width: 100%;
`

const Header = styled.header`
  display: flex;
  width: 100%;
`

const StyledLink = styled(Link)`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;

  &:hover {
    text-decoration: none;
  }
  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
`

const P = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0.5rem 0;
  padding: 0;
`

const Envelope = styled(P)`
  color: ${theme('colors.mediumDark')};
  width: 14rem;
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 1rem;
`
const Title = styled(P)`
  margin-left: 0;
  font-weight: ${ifProp('read', '', 500)};
  color: ${theme('colors.primary')};
  flex-shrink: 1;
  flex-grow: 1;
`
const Timestamp = styled(P)`
  color: ${theme('colors.medium')};
  text-align: right;
  width: 5rem;
  flex-shrink: 0;
  flex-grow: 0;
`

const StyledButton = styled(NakedButton)`
  margin: 0 0 0 0.5rem;
  flex-shrink: 0;
  flex-grow: 0;
`

const Body = styled.div`
  padding: 2rem;
  > div {
    padding-bottom: 2rem;
  }
`

const Footer = styled.footer`
  padding-top: 2rem;
  border-top: 2px solid ${theme('colors.borderPrimary')};
`

const Message = ({
  id,
  to,
  from,
  read,
  subject,
  timestamp,
  message,
  data,
  openMessageId,
  deleteMessage,
  setRead,
}) => {
  const history = useHistory()
  const isOpen = openMessageId === id

  useEffect(() => {
    if (isOpen && !read) {
      setRead(id, true)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, read])

  return (
    <Wrapper>
      <Header>
        <StyledLink to={isOpen ? '/messages' : `/messages/${id}`}>
          {from?.name && (
            <Envelope title={`Från: ${from.name}`}>{from.name}</Envelope>
          )}
          {to?.name && (
            <Envelope title={`Till: ${to.name}`}>{to.name}</Envelope>
          )}
          <Title read={read} title={subject}>
            {subject}
          </Title>
          <Timestamp>{timestamp ? formatDate(timestamp) : ''}</Timestamp>
        </StyledLink>
        {isOpen ? (
          <StyledButton onClick={e => history.push('/messages')} icon="close" />
        ) : (
          <StyledButton
            icon="trash"
            kind="warning"
            onClick={() => deleteMessage(id)}
          />
        )}
      </Header>
      {isOpen && (
        <Body>
          <Markdown>{message}</Markdown>
          <Footer>
            {from &&
              data?.type &&
              actions[data.type] &&
              createElement(actions[data.type], { sender: from.id, data })}
          </Footer>
        </Body>
      )}
    </Wrapper>
  )
}

export default Message

import { backendCall } from 'utils/firebase'
import { toOptions } from 'utils/data'

// const humlegarden = '556682-1202'
const bankx = '123456-7890'
// const swedbank = '502017-7753'
// const dalslandssparbank = '562500-5243'
const dalslandsgruppen = '556919-0381'
// const fogelvik = '556628-4831'
const heba = '556057-3981'

const extractEpcs = {
  title: 'Extract EPC data with energy class (long run time)',
  form: {},
  submit: async () => backendCall('extractEpcs', { withEnergyClass: true }),
}

const extractOldEpcs = {
  title: 'Extract EPC data without energy class (pretty long run time)',
  form: {},
  submit: async () => backendCall('extractEpcs', { withEnergyClass: false }),
}

const extractCerts = {
  title: 'Extract certification data',
  form: {},
  submit: async () => backendCall('extractCertifications'),
}

const addReporterToCreditor = {
  title: 'Add reporter to creditor',
  form: {
    creditor: { label: 'Orgnr (creditor)' },
    reporter: { label: 'Orgnr (reporter)' },
  },
  initialValues: {
    reporter: heba,
    creditor: bankx,
  },
  submit: ({ reporter, creditor }) =>
    backendCall('addReporterToCreditorAdmin', {
      reporter: reporter.trim(),
      creditor: creditor.trim(),
    }),
}

const addPropertyToTeam = {
  title: 'Add property to team',
  form: {
    OrgNr: {},
    PropertyId: {},
  },
  initialValues: {
    OrgNr: dalslandsgruppen,
    PropertyId: 'Dals-Ed Kårslätt 1:34',
  },
  submit: ({ OrgNr, PropertyId }) =>
    backendCall('addPropertyToTeam', {
      orgNr: OrgNr.trim(),
      propertyId: PropertyId.trim(),
    }),
}

const addReporterTeam = {
  title: 'Add new Team (reporter)',
  form: {
    OrgNr: {},
    Email: { type: 'email' },
    Source: {
      type: 'select',
      options: toOptions({
        infotrader: 'Infotrader',
        lmvbatch: 'LMV batch',
        lmvapi: 'LMV API',
      }),
    },
    File: {
      type: 'file',
      env: {
        teamId: 'batchImports',
      },
    },
  },
  initialValues: {
    OrgNr: heba,
    Email: 'martin@sciigo.com',
    Source: 'lmvbatch',
  },
  submit: async ({ OrgNr, Email, Source, File }) =>
    backendCall('addReporter', {
      orgNr: OrgNr.trim(),
      email: Email.trim(),
      sourceType: Source,
      file: File,
    }),
}

const addCreditorTeam = {
  title: 'Add new team (creditor)',
  form: {
    OrgNr: {},
    Name: {},
    Email: { type: 'email' },
  },
  initialValues: {
    OrgNr: bankx,
    Name: 'Banken',
    Email: 'martin@sciigo.com',
  },
  submit: ({ Name, Email, OrgNr }) =>
    backendCall('addCreditor', {
      name: Name.trim(),
      email: Email.trim(),
      orgNr: OrgNr.trim(),
    }),
}

const addUsersToTeam = {
  title: 'Add users to team',
  form: {
    Emails: {
      type: 'textArea',
    },
    OrgNr: {},
  },
  initialValues: {
    Emails: 'demo+1@sciigo.com\ndemo+2@sciigo.com\ndemo+3@sciigo.com',
    OrgNr: heba,
  },
  submit: async ({ Emails, OrgNr }) =>
    backendCall('addUsersToTeam', {
      emails: Emails.split('\n')
        .map(e => e.trim())
        .filter(e => e),
      orgNr: OrgNr.trim(),
    }),
}

const setRequirementMatricesForCreditor = {
  title: 'Set requrement matrices for creditor',
  form: {
    OrgNr: {},
    matrixIds: {
      label: 'Requirement matrix ids (one per row)',
      type: 'textArea',
    },
  },
  initialValues: {
    OrgNr: bankx,
    matrixIds: 'demobank',
  },
  submit: async ({ OrgNr, matrixIds }) =>
    backendCall('setRequirementMatrices', {
      orgNr: OrgNr.trim(),
      matrixIds: matrixIds
        .split('\n')
        .map(e => e.trim())
        .filter(e => e),
    }),
}

const rebuildOrgMap = {
  title: 'Rebuild org map',
  form: {},
  submit: () => backendCall('rebuildOrgMap'),
}

const test = {
  title: 'Test Backend',
  form: {},
  submit: () => backendCall('test'),
}

const actions = {
  test,
  setRequirementMatricesForCreditor,
  addPropertyToTeam,
  addReporterToCreditor,
  addReporterTeam,
  addCreditorTeam,
  addUsersToTeam,
  extractCerts,
  extractEpcs,
  extractOldEpcs,
  rebuildOrgMap,
}

export default actions

import { useState, createElement } from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import { nanoid, sortByDate, getValue } from 'utils/data'
import { prefixStrings } from 'utils/string'
import { FieldValue } from 'utils/firebase'
import {
  BaseSection,
  StaticGroupSection as UnstyledStaticGroupSection,
} from 'components/Section'
import Button, { NakedButton } from 'components/Button'
import Markdown from 'components/Markdown'
import AccordionContext from 'components/AccordionContext'

const ButtonRow = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
`
const Description = styled(Markdown)`
  padding: 0.5rem;
`

const StaticGroupSection = styled(UnstyledStaticGroupSection)`
  > hgroup > h2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 2rem;
  }
`

const ActionSection = ({
  title,
  description,
  data,
  editable = true,
  save,
  add,
  addLabel,
  delete: _delete,
  filePath,
  qualificationResults,
}) => {
  const [expanded, setExpanded] = useState({})

  const _setExpanded = (id, status) => {
    setExpanded({ ...expanded, [id]: status })
  }

  const addAction = async () => {
    const actionId = nanoid()
    setExpanded({ [actionId]: true })
    await save({
      subcollectionId: 'actions',
      itemId: actionId,
      value: {
        title: 'Ny Åtgärd',
        timestamp: FieldValue.serverTimestamp(),
      },
    })
  }

  const deleteAction = actionId => e => {
    e.preventDefault()
    e.stopPropagation()
    save({
      subcollectionId: 'actions',
      itemId: actionId,
      value: undefined,
    })
  }

  const DeleteButton = ({ id }) => {
    const disabled = Object.values(data.risks.items).find(
      risk => getValue(risk.action) === id
    )
    return (
      <NakedButton
        icon="trash"
        title={
          disabled
            ? 'Åtgärden refereras av en eller flera risker och kan inte tas bort'
            : 'Ta bort åtgärd'
        }
        disabled={disabled}
        onClick={deleteAction(id)}
      />
    )
  }

  const viewModel = data
    ? Object.fromEntries(
        Object.entries(data.actions.items)
          .sort(([, r1], [, r2]) => sortByDate('timestamp.value')(r1, r2))
          .map(([id, action]) => {
            const keys = ['title', 'description', 'timescale', 'plan']

            return [
              id,
              {
                label: action.title.displayValue,
                collapsible: true,
                keys: prefixStrings(`actions.items.${id}.`, keys),
              },
            ]
          })
      )
    : {}

  return (
    <BaseSection title={title}>
      <Description>{description}</Description>
      <AccordionContext.Provider
        value={{ setExpanded: _setExpanded, expanded }}
      >
        {Object.entries(viewModel).map(([sectionId, meta], idx) => (
          <StaticGroupSection
            key={sectionId}
            id={sectionId}
            title={meta.label}
            subtitle={meta.subtitle}
            status={createElement(DeleteButton, { id: sectionId })}
            highlight={meta.highlight}
            collapsible={meta.collapsible}
            attributes={meta.keys.map(key => ({
              id: key,
              ...get(data, key),
              editable: get(data, key)?.editable && editable,
              missing: qualificationResults?.missing[key],
              inadequate: qualificationResults?.inadequate[key],
            }))}
            attachments={data.attachments?.items}
            save={save}
            filePath={filePath}
          />
        ))}
      </AccordionContext.Provider>
      <ButtonRow>
        <Button onClick={() => addAction()}>Ny Åtgärd</Button>
      </ButtonRow>
    </BaseSection>
  )
}

export default ActionSection

import { createContext, useEffect, useState, useContext } from 'react'
import { getFirebase, convertDates, Timestamp } from 'utils/firebase'
import { AuthContext } from './Auth'
import { CodeError } from 'utils/error'

export const UserContext = createContext({
  loading: true,
})

export const UserProvider = ({ children }) => {
  const { user } = useContext(AuthContext)
  const [state, setState] = useState({
    loading: true,
  })

  const { db, set } = getFirebase()
  const uid = user?.uid
  const isAdmin = user?.claims?.admin
  useEffect(() => {
    const unsubscribe = uid
      ? db
          .collection('accounts')
          .doc(uid)
          .onSnapshot(
            doc => {
              const data = doc.data()
              if (data) {
                const account = convertDates(data)
                const activeTeam = account.teams[account.activeTeam]
                  ? account.activeTeam
                  : Object.keys(account.teams).sort()[0]

                setState({
                  loading: false,
                  uid,
                  ...account,
                  team: activeTeam,
                })
              } else {
                setState({
                  loading: false,
                  error: isAdmin
                    ? undefined
                    : new CodeError(
                        'unavailable',
                        `account/${uid} not found, probably a connection error`
                      ),
                })
              }
            },
            err => {
              setState({ loading: false, error: err })
            }
          )
      : null

    return () => {
      unsubscribe?.()
      setState({ loading: true })
    }
  }, [db, uid, isAdmin])

  const setActiveTeam = team => {
    if (uid) {
      return set(`accounts/${uid}`, {
        activeTeam: team,
      })
    }
  }

  const setPreferences = newPrefs => {
    if (uid) {
      return set(`accounts/${uid}`, {
        preferences: newPrefs,
      })
    }
  }

  const setRecent = async (klass, id, label, type) => {
    if (uid && state.team) {
      return set(`accounts/${uid}`, {
        recent: {
          [state.team]: {
            [klass]: {
              [id]: {
                timestamp: Timestamp.now(),
                label,
                ...(type ? { type } : {}),
              },
            },
          },
        },
      })
    }
  }

  if (state.error) {
    throw state.error
  }

  return (
    <UserContext.Provider
      value={{
        ...state,
        setActiveTeam,
        setPreferences,
        setRecent,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

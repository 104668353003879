import { useReducer, useContext } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { theme, ifProp, ifNotProp } from 'styled-tools'
import Collapsible from './Collapsible'
import Icon from './Icon'
import AccordionContext from './AccordionContext'

const Section = styled.section`
  background: ${theme('colors.white')};
  border-radius: 12px;
  margin: 2rem 0;
  box-shadow: 0px 0px 8px ${theme('colors.shadow')};
`

const TitleGroup = styled.hgroup`
  position: relative;
  padding: 0.1rem 1rem 0.5rem;
  text-align: left;
  background: ${theme('colors.primary')};
  color: ${theme('colors.white')};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  ${ifProp('collapsible', 'cursor: pointer;')}
`

const Title = styled.h2`
  margin: 1rem 1rem 0.5rem;
  padding-top: 0.4rem;
`

const Subtitle = styled.h3`
  opacity: 0.6;
  margin: -0.5rem 1rem 0.5rem;
  font-size: 1rem;
  color: ${theme('colors.darkPrimary')};
`

const Status = styled.span`
  font-size: 1.2rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1em;
  font-weight: 400;
`

const Toggle = styled(Icon).attrs(() => ({ type: 'triangleDown' }))`
  position: absolute;
  top: 50%;
  transform: translateY(-50%) ${ifNotProp('$expanded', 'rotate(-90deg)')};
  left: 5px;
  transition: 200ms transform;
`

const HeaderLink = styled(Link)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  svg {
    width: 2rem;
    height: 2rem;
    color: white;
  }
`

const Card = ({
  id,
  title,
  subtitle,
  status,
  link,
  collapsible,
  expanded: initialExpanded,
  description,
  className,
  children,
}) => {
  const { setExpanded, expanded: expandedById } = useContext(AccordionContext)
  const [localExpanded, localToggle] = useReducer(e => !e, initialExpanded)

  const toggle = () => {
    return setExpanded ? setExpanded(id, !expandedById[id]) : localToggle()
  }

  const expanded = !!(localExpanded || expandedById?.[id])
  return (
    <Section className={className}>
      {(title || subtitle || collapsible) && (
        <TitleGroup
          onClick={toggle}
          collapsible={collapsible}
          expanded={expanded}
        >
          {status && <Status>{status}</Status>}
          {collapsible && <Toggle $expanded={expanded} />}
          {title && <Title>{title}</Title>}
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
          {link && (
            <HeaderLink to={link}>
              <Icon type="more" />
            </HeaderLink>
          )}
        </TitleGroup>
      )}
      {collapsible ? (
        <Collapsible expanded={expanded}>{children}</Collapsible>
      ) : (
        children
      )}
    </Section>
  )
}

export default styled(Card)``

import styled from 'styled-components'
import { theme } from 'styled-tools'
import { Helmet } from 'react-helmet-async'

const Main = styled.main`
  position: relative;
  padding: 1rem;
  flex-grow: 1;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 2px 2px 8px inset ${theme('colors.shadow')};
`

const Title = styled.h1`
  text-align: center;
  margin: 3rem 0 2rem;
`

const Page = ({ title, children, showTitle = true }) => (
  <Main>
    <Helmet>
      <title>{title ?? 'Loading...'} | Sciigo</title>
    </Helmet>
    {title && showTitle && <Title>{title}</Title>}
    {children}
  </Main>
)

export default Page

import { pick } from 'lodash'
import { getValue, mergeAttributes } from 'utils/data'
import { prefixStrings } from 'utils/string'

// Shorthands

const qualifyAttr = (attr, { requireTruthy } = {}) =>
  requireTruthy ? !getValue(attr) : getValue(attr) === undefined

export const requireOne = (attrs, options) =>
  Object.values(attrs).some(v => qualifyAttr(v, options))
    ? undefined
    : { fulfilled: false, missing: Object.keys(attrs) }

export const requireAll = (attrs, options) => {
  const missing = Object.entries(attrs)
    .filter(([k, v]) => qualifyAttr(v, options))
    .map(([k]) => k)
  return missing.length ? { fulfilled: false, missing } : undefined
}

// data only contains the subcollection in question, I.e. call qualifyAll({subcId}, fn)
// Last arg is the qualify function to apply to all subcollection items
// Qualifies if subcollection is empty
export const qualifyAll = (data, qualify) => {
  const [subcollectionId, subcollection] = Object.entries(data)?.[0] ?? []

  const entries = Object.entries(subcollection.items ?? {})
  if (entries.length === 0) {
    return
  }
  const itemResults = entries
    .map(([id, item]) => {
      const itemResult = qualify(item)
      return itemResult
        ? {
            missing: prefixStrings(
              `${subcollectionId}.items.${id}.`,
              itemResult.missing ?? []
            ),
            inadequate: prefixStrings(
              `${subcollectionId}.items.${id}.`,
              itemResult.inadequate ?? []
            ),
          }
        : undefined
    })
    .filter(e => e !== undefined)

  const result = mergeAttributes(itemResults)

  return result.missing?.length || result.incomplete?.length
    ? { fulfilled: false, ...result }
    : undefined
}

// All given attributes are present in all subcollection items
export const requireAllInAll = (data, attrNames) =>
  qualifyAll(data, item => requireAll(pick(item, attrNames)))

export const fulfilledOne = requirements =>
  requirements.some(({ fulfilled }) => fulfilled)

export const fulfilledAll = requirements =>
  requirements.every(({ fulfilled }) => fulfilled)

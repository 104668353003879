import { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import FormField from 'components/form/FormField'
import { getFirebase } from 'utils/firebase'
import Button from 'components/Button'
import { Errors } from 'components/form'
import LoginWrapper, { ButtonRow } from './LoginWrapper'

const LoginLink = styled(Link)`
  display: block;
  margin-bottom: 2rem;
`

const sendMagicLink = async email => {
  const { functions } = getFirebase()
  await functions.httpsCallable('sendMagicLink')({ email })
  window.localStorage.setItem('sciigoMagicLinkEmail', email)
}

const resetPassword = async email => {
  const { functions } = getFirebase()
  await functions.httpsCallable('sendResetPassword')({ email })
}

export const Forgot = ({ history, location }) => {
  const [message, setMessage] = useState('')
  const emailField = useRef()
  useEffect(() => emailField.current?.focus())

  return (
    <LoginWrapper title="Glömt lösenord">
      <Formik
        initialValues={{
          email: '',
          action: '',
        }}
        onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
          try {
            if (!values.email) {
              setErrors({ missing: { message: 'Email saknas' } })
              return
            }
            const { action } = values
            if (action === 'magicLink') {
              await sendMagicLink(values.email)
              setMessage(
                `En inloggningslänk har skickats till ${values.email}.`
              )
            } else {
              await resetPassword(values.email)
              setMessage(
                `Instruktioner för att återställa lösenordet har skickats till ${values.email}.`
              )
            }
            setSubmitting(false)
            setErrors()
          } catch (e) {
            console.error(e.code, e)
            if (
              [
                'auth/no-such-account',
                'auth/user-not-found',
                'not-found',
              ].includes(e.code)
            ) {
              setErrors({
                err: { message: `Ingen användare med epost '${values.email}'` },
              })
            } else {
              setErrors({ err: { message: `Okänt fel: "${e.message}"` } })
            }
          }
        }}
      >
        {({
          isSubmitting,
          errors,
          submitForm,
          resetForm,
          values,
          setValues,
          setErrors,
        }) => (
          <Form noValidate>
            <LoginLink to="/login">Tillbaka till inloggning</LoginLink>
            <Errors errors={errors} />
            <FormField
              name="email"
              type="email"
              label="Email"
              tabIndex="1"
              inputRef={emailField}
            />
            {message ? (
              <>
                <p>{message}</p>
                <p>
                  <Link to="/login">Logga in</Link>
                </p>
              </>
            ) : (
              <ButtonRow>
                <Button
                  disabled={isSubmitting || Object.keys(errors).length}
                  onClick={e => {
                    setValues({ ...values, action: 'magicLink' })
                    submitForm()
                  }}
                  tabIndex="3"
                >
                  Skicka inloggingslänk
                </Button>

                <Button
                  disabled={isSubmitting || Object.keys(errors).length}
                  type="submit"
                  name="action"
                  value="resetPassword"
                  tabIndex="2"
                >
                  Återställ lösenord
                </Button>
              </ButtonRow>
            )}
          </Form>
        )}
      </Formik>
    </LoginWrapper>
  )
}

export default Forgot

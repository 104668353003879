import transform from './transform'
import { toOptions } from 'utils/data'
import {
  capitalizeSentence,
  enumerateString,
  truncateString,
} from 'utils/string'
import { isRequired, isOrgNr, isNumberBetween } from 'utils/validation'
import sniCodes from 'data/sni2007.json'
import countries from 'data/countries.json'
import geographies from 'data/regions'

const sniOptions = Object.entries(sniCodes)
  .filter(([code, text]) => code.length === 4)
  .map(([code, text]) => ({
    value: code,
    label: `${code}: ${text}`,
  }))

const countryOptions = toOptions(countries)

const geographyOptions = toOptions(geographies)

const meta = {
  documents: 'primitive',

  name: {
    label: 'Namn',
    format: name => capitalizeSentence(name.toLowerCase()),
    validate: isRequired,
  },
  orgNr: {
    label: 'Organisationsnummer',
    validate: isOrgNr,
    placeholder: 'XXXXXX-XXXX',
  },

  activityDescription: {
    label: 'Verksamhetsbeskrivning',
  },

  mainActivity: {
    label: 'Huvudsaklig verksamhet',
    type: 'select',
    options: sniOptions,
    format: value => sniCodes[value] ?? '',
  },

  otherActivities: {
    label: 'Sekundär verksamhet',
    type: 'select',
    multiple: true,
    options: sniOptions,
    format: value =>
      value ? enumerateString(value.map(v => sniCodes[v])) : '',
  },

  geographies: {
    label: 'Affärer med utlandet',
    description: `Ange samtliga geografiska områden där organisationen har egen produktion, 
utlagd produktion, inköp, projektbaserade aktiviteter, investeringar eller försäljning`,
    type: 'select',
    multiple: true,
    options: geographyOptions,
  },

  taxRates: {
    label: 'Skattesatser',
    type: 'fieldArray',
    fields: {
      key: {
        label: 'Land',
        type: 'select',
        options: countryOptions,
        validate: isRequired,
      },
      value: {
        label: 'Skattesats',
        type: 'percentage',
        unit: '%',
        validate: isNumberBetween(0, 100),
      },
    },
  },

  humanRightsPolicy: {
    label: 'Policy för mänskliga rättigheter',
    type: 'boolean',
    labels: ['Finns', 'Finns inte'],
  },
  dueDiligenceProcess: {
    label: 'Due Diligence-process för inverkan på mänskliga rättigheter',
    type: 'boolean',
    labels: ['Finns', 'Finns inte'],
  },
  processToTakeAction: {
    label: 'Process för att vidta åtgärder vid identifierad inverkan',
    type: 'select',
    options: [
      { value: 'none', label: 'Det finns ingen process' },
      {
        value: 'informal',
        label: 'Det finns en informell, bristfälligt dokumenterad process',
      },
      {
        value: 'formal',
        label: 'Det finns en formaliserad och väldokumenterad process',
      },
    ],
  },
  trackingProcess: {
    label: 'Uppföljning av arbete med mänskliga rättigheter',
    type: 'select',
    options: toOptions({
      none: 'Vi följer inte upp arbetet',
      informal: 'Uppföljningen sker ad-hoc',
      formal:
        'Uppföljning sker enligt en formaliserad och väldokumenterad process',
    }),
  },
  publicCommunication: {
    label: 'Publik kommunikation om arbete med mänskliga rättigheter',
    type: 'section',
    fields: {
      communicationExists: {
        label: 'Kommunkation finns',
        type: 'boolean',
        labels: ['Finns', 'Finns inte'],
      },
      description: {
        label: 'Beskriv kommunikation',
        type: 'textArea',
        visible: env => value => value?.communicationExists,
      },
    },
    format: ({ communicationExists, description }) =>
      communicationExists
        ? `Finns ${description ? `(${truncateString(description)})` : ''}`
        : 'Finns inte',
  },

  sustainabilityProcessDescription: {
    label: 'Beskrivning av hållbarhetsarbete',
    type: 'file',
    sourced: false,
  },

  attachments: {
    label: 'Dokument',
    type: 'list',
    itemType: 'section',
    formatItem: ({ name }) => name,
    fields: {
      name: {
        label: 'Namn',
      },
      description: {
        label: 'Beskrivning',
        type: 'textArea',
      },
    },
    sourced: false,
  },
}

export default transform(meta)

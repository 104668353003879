import styled from 'styled-components'
import { useTeam, useCases } from 'providers/Session'
import Loader from 'components/Loader'
import List, { Source } from 'components/List'
import Page from 'components/Page'
import Card from 'components/Card'
import Debug from 'components/Debug'
import { isCaseOpen, getValue, sortByDate } from 'utils/data'

const Wrapper = styled(Card)`
  padding-bottom: 1.2rem;
  position: relative;
`

const StatusWrapper = styled.div``

const Status = ({ status }) => <StatusWrapper>{status}</StatusWrapper>

const CaseList = props => {
  const { loading: loadingTeam, ...teamData } = useTeam()
  const { data: _cases, loading: loadingCases } = useCases(teamData)
  const loading = loadingTeam || loadingCases

  const items = _cases
    ? Object.entries(_cases ?? {})
        .map(([id, _case]) => {
          return {
            id,
            title: _case.title.displayValue,
            status: _case.status.displayValue,
            timestamp: getValue(_case.timestamp),
            isOpen: isCaseOpen(_case),
          }
        })
        .sort(sortByDate('timestamp', false))
    : null

  return (
    <Page title="Ärenden">
      <Wrapper>
        {loading ? (
          <Loader />
        ) : !items ? (
          <div></div>
        ) : (
          <List
            data={items}
            filter={filterStr =>
              ({ title, subtitle }) =>
                title.toLowerCase().includes(filterStr.toLowerCase()) ||
                subtitle.toLowerCase().includes(filterStr.toLowerCase())}
            sort={({ title: t1 }, { title: t2 }) => t1.localeCompare(t2, 'sv')}
          >
            <Source />
            <Status />
          </List>
        )}
      </Wrapper>
      <Debug value={_cases} />
    </Page>
  )
}

export default CaseList

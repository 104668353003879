import styled from 'styled-components'
import Button from 'components/Button'
import FormField from 'components/form/FormField'

import Popup from './Popup'

const ButtonRowWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  > p {
    width: 100%;
    padding: 0.9rem 0;
    text-align: center;
    font-weight: 500;
  }
  > button {
    position: relative;
  }
`

export const ButtonRow = ({
  children,
  title,
  disabled,
  loading,
  done,
  successMessage,
}) => (
  <ButtonRowWrapper>
    {done && successMessage ? (
      <p>{successMessage}</p>
    ) : children ? (
      children
    ) : (
      <Button disabled={disabled} loading={loading} type="submit">
        {title ?? 'Spara'}
      </Button>
    )}
  </ButtonRowWrapper>
)

const FormPopup = styled(Popup)`
  h2 {
    text-transform: none;
    font-size: inherit;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  ${FormField} {
    margin: 0.3rem 0;
    label {
      padding-left: 0;
      span {
        font-size: 1rem;
        font-weight: 300;
      }
    }
  }
`

export default FormPopup

import { useMemo } from 'react'
import { pickBy } from 'lodash'
import Page from 'components/Page'
import Loader from 'components/Loader'
import ActionWrapper from 'components/Section/ActionWrapper'
import { useTeam, useAssets, useDocuments } from 'providers/Session'
import { qualify } from 'qualifier'

import { SummaryReport } from 'visualization'

import {
  exportCaseReportPdf,
  exportCaseReportExcel,
} from 'visualization/export'
import ExportButton from 'components/ExportButton'

// TODO: This view literally downloads everything and runs it through the qualifier,
// browser-side. Might be a good idea to move this to the backend.
// One idea is to store qualification results on the objects being qualified, as well as
// a team cache for the summary. Would mean a lot of triggers back and forth, but you'd
// have complete freedom in requirement settings (including cascading requirements) that
// you could enforce in every view.

const Overview = () => {
  const teamData = useTeam()
  const { data: assets } = useAssets(teamData)
  const { data: documents } = useDocuments([], teamData)

  const plans = pickBy(documents, d => d.type === 'plan')
  const riskAssessments = pickBy(documents, d => d.type === 'riskAssessment')

  const assetStr = JSON.stringify(assets)
  const plansStr = JSON.stringify(plans)
  const raStr = JSON.stringify(riskAssessments)
  const result = useMemo(() => {
    if (assets) {
      const requirementMatrices = ['demobank']
      const result = qualify(
        {
          assets,
          organizations: teamData.organizations,
          plans,
          riskAssessments,
        },
        requirementMatrices
      )
      return result
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetStr, plansStr, raStr])

  const exportActions = {
    pdf: {
      label: 'Ladda ner PDF',
      icon: 'pdf',
      download: data => exportCaseReportPdf('Sciigo case report', data),
    },
    excel: {
      label: 'Ladda ner excel',
      icon: 'excel',
      download: data => exportCaseReportExcel('Sciigo excel report', data),
    },
  }

  const exportData = {
    organizations: teamData.organizations,
    assets,
    documents,
    qualificationResults: result,
  }

  return (
    <Page title="Översikt">
      {!result ? (
        <Loader />
      ) : (
        <>
          <ActionWrapper compact={true}>
            <ExportButton actions={exportActions} data={exportData} />
          </ActionWrapper>
          <SummaryReport
            organizations={teamData.organizations}
            assets={assets}
            documents={documents}
            qualificationResults={result}
          />
        </>
      )}
    </Page>
  )
}

export default Overview

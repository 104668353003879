import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30rem;
  max-height: 100vh;
  img {
    display: block;
    height: 10rem;
    margin-right: 2rem;
  }
`
const Error = styled.div`
  h1 {
    margin-bottom: 0.3em;
  }
  p {
    font-size: 1.2em;
    font-weight: 500;
  }
  max-width: 40rem;
`

const errors = {
  'not-found': {
    title: 'Resursen hittades inte',
    message: 'Kontrollera sökväg och försök igen',
  },
}

const ErrorMessage = ({ className, error }) => {
  const { title, message } = errors[error.code] ?? {
    title: 'Nåt gick fel',
    message: 'Försök igen senare',
  }

  return (
    <Wrapper className={className}>
      <img src="/error.svg" alt="error" />
      <Error>
        <h1>{title}</h1>
        <p>{message}</p>
      </Error>
    </Wrapper>
  )
}

export default ErrorMessage

import { useState } from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { useTeam } from 'providers/Session'
import Loader from 'components/Loader'
import List from 'components/List'
import Debug from 'components/Debug'
import EnergyClass from 'components/EnergyClass'
import Page from 'components/Page'
import Card from 'components/Card'
import summarizeAddresses from 'utils/summarizeAddresses'
import Button from 'components/Button'
import { Source } from 'components/List'
import useBatchFunction from 'providers/BatchFunction'

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: stretch;
  padding: 1rem;
  button {
    width: 15rem;
    flex-grow: 0;
    margin-left: 1rem;
  }
`

const Error = styled.div`
  padding: 0.5rem 1.5rem 0 1.5rem;
  color: ${theme('colors.error')};
`

const Wrapper = styled(Card)`
  padding-bottom: 1.2rem;
`

const StyledEnergyClass = styled(EnergyClass)`
  width: 3rem;
  height: 3rem;
  font-size: 1.8rem;
  line-height: 3rem;
`

const SearchInput = styled.input``

const formatAddress = adr => {
  if (!adr) {
    return ''
  }
  const address = summarizeAddresses(adr.address)
  const { zipCode, city } = adr
  return `${address}, ${zipCode ?? ''} ${city ?? ''}`
}

const AssetList = props => {
  const { id: teamId, assets, loading } = useTeam()
  const { run, ...fnData } = useBatchFunction()
  const [filterStr, setFilterStr] = useState('')

  const addAsset = async assetId => {
    await run('addAssetToTeam', {
      teamId,
      assetType: 'property',
      assetId,
    })
  }

  const items = assets
    ? Object.entries(assets ?? {})
        .map(([id, property]) => ({
          id,
          title: property.propertyId ?? '',
          subtitle: property.address ? formatAddress(property.address) : '',
          value: property.energyClass,
          url: `/asset/${id}`,
        }))
        .filter(
          ({ title, subtitle }) =>
            title.toLowerCase().includes(filterStr.toLowerCase()) ||
            subtitle.toLowerCase().includes(filterStr.toLowerCase())
        )
        .sort(({ title: t1 }, { title: t2 }) => t1.localeCompare(t2, 'sv'))
    : []

  const isValidAssetId = str => {
    if (!str) {
      return false
    }
    if (items.find(item => item.title === str.trim())) {
      return false
    }
    const tokens = str.trim().split(' ')
    return tokens.length >= 3 && /\d+(:\d+)?/.test(tokens[tokens.length - 1])
  }

  const canAdd = isValidAssetId(filterStr) && !fnData.loading

  return (
    <Page title="Fastigheter">
      <Wrapper>
        <HeadingWrapper>
          <SearchInput
            value={filterStr}
            placeholder={'Filtrera'}
            onChange={e => setFilterStr(e.target.value)}
          />
          <Button
            onClick={e => addAsset(filterStr)}
            disabled={!canAdd}
            loading={fnData.loading}
          >
            Lägg till ny
          </Button>
        </HeadingWrapper>
        {fnData.error && <Error>Nåt gick fel</Error>}
        {loading ? (
          <Loader />
        ) : (
          <List data={items}>
            <Source />
            <StyledEnergyClass />
          </List>
        )}
      </Wrapper>
      <Debug value={assets} />
    </Page>
  )
}

export default AssetList

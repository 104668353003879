import { useEffect, useState } from 'react'
import { nanoid, sortByDate } from 'utils/data'
import { getFirebase, FieldValue, cleanData } from 'utils/firebase'
import { useUser, useTeam } from 'providers/Session'
import { addMeta } from 'data/meta'

export const useMailbox = () => {
  const { uid, team } = useUser()
  const { reporters, creditors, loading: loadingTeam } = useTeam()

  const [state, setState] = useState({ loadingMessages: true })

  useEffect(() => {
    const { db } = getFirebase()
    const unsubscribe = team
      ? db.collection(`teams/${team}/messages`).onSnapshot(snapshot => {
          const messages = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }))

          setState({
            messages,
            loadingMessages: false,
          })
        })
      : undefined

    return () => {
      unsubscribe?.()
      setState({ loadingMessages: true })
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team])

  const loading = state.loadingMessages || loadingTeam

  const { inbox, outbox } = getMailboxes({
    messages: state.messages,
    loading,
    team,
    reporters,
    creditors,
    uid,
  })

  const setRead = async (id, read) => {
    const { db } = getFirebase()
    return db.doc(`teams/${team}/messages/${id}`).set({ read }, { merge: true })
  }

  return {
    loading,
    inbox,
    outbox,
    setRead,
  }
}

const getMailboxes = ({
  messages,
  loading,
  team,
  reporters,
  creditors,
  uid,
}) => {
  if (messages && !loading) {
    const inbox = messages
      .filter(
        ({ from }) =>
          from &&
          from !== team &&
          (!reporters?.[from]?.creditorContact ||
            reporters?.[from].creditorContact === uid)
      )
      .map(data => addMeta(data, 'message', { reporters, creditors }))
      .sort(sortByDate('timestamp', true))
    const outbox = messages
      .filter(
        ({ to }) =>
          to &&
          to !== team &&
          (!reporters?.[to]?.creditorContact ||
            reporters?.[to].creditorContact === uid)
      )
      .map(data => addMeta(data, 'message', { reporters, creditors }))
      .sort(sortByDate('timestamp', true))

    return {
      inbox,
      outbox,
    }
  } else {
    return {}
  }
}

export const useMessages = () => {
  const { uid, email, team } = useUser()
  const { name: orgName, orgNr } = useTeam()

  const sendMessage = async (recipient, subject, message, data) => {
    const { db } = getFirebase()
    const msgId = nanoid()

    await db.doc(`teams/${team}/messages/${msgId}`).set(
      cleanData({
        to: recipient,
        sender: {
          uid,
          email,
          orgName,
          orgNr,
        },
        subject,
        message,
        data,
        read: true, // Outbox messages are always read
        timestamp: FieldValue.serverTimestamp(),
      })
    )
  }

  const deleteMessage = async msgId => {
    const { db } = getFirebase()
    await db.doc(`teams/${team}/messages/${msgId}`).delete()
  }

  return {
    sendMessage,
    deleteMessage,
  }
}

import { toOptions } from 'utils/data'
import transform from './transform'

const meta = {
  pending: 'primitive',
  name: {
    label: 'Namn',
    editable: false,
    sourced: false,
  },
  orgNr: {
    label: 'Organisationsnummer',
    editable: false,
    sourced: false,
  },
  contact: {
    label: 'Ansvarig rådgivare',
    type: 'select',
    options: ({ team }) => toOptions({ '': '—', ...team.users }),
    sourced: false,
  },
}

export default transform(meta)

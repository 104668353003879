import React from 'react'

const isValidElement = onlyComposite => element =>
  React.isValidElement(element) &&
  (typeof element.type !== 'string' || !onlyComposite)

export const addPropsToChildren = (children, props, onlyComposite = true) =>
  React.Children.map(children, child =>
    isValidElement(onlyComposite)(child)
      ? React.cloneElement(child, { ...props, ...child.props })
      : child
  )

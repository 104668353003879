import transform from './transform'

const meta = {
  pending: 'primitive',
  requirements: 'primitive',
  name: {
    label: 'Namn',
    editable: false,
    sourced: false,
  },
  orgNr: {
    label: 'Organisationsnummer',
    editable: false,
    sourced: false,
  },
}

export default transform(meta)

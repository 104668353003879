import transform from '../transform'
import { isArray, mapValues } from 'lodash'
import { toOptions, getValue } from 'utils/data'
import { capitalizeSentence, enumerateString } from 'utils/string'
import { certificationLabel } from 'data/certificationTypes'
import { isRequired, isOrgNr, matches, isNumber } from 'utils/validation'
import summarizeAddresses from 'utils/summarizeAddresses'

// Allowed params:
// label: string
// description: string
// type: string
// unit: string
// sourced: boolean
// labels: array (for type boolean)
// options: array|fn (for type select)
// link: string|fn
// format: fn (for display value)
// fields: object (for type section)
// formatItem: fn (for type list)
// validate: (value: any, env: TeamData) => string (erormessage) | undefined
// transform: {read: fn, write: fn} (for form editing)

// types:
// string (default)
// number.x
// percentage.x
// date
// section
// fieldArray
// list (items are attributes, possibly with fields)
// subcollection (items are object with attributes)

const buildingTypes = {
  residential: 'Bostad',
  industrial: 'Industri',
  commercial: 'Verksamhet/Lokaler',
  public: 'Samhällsfunktion',
  agricultural: 'Lantbruk/skogsbruk',
  electrical: 'Elproduktion',
  other: 'Övrig',
}

const heatingTypes = {
  directElectricity: 'El (direktverkande)',
  airborneElectricity: 'El (vattenburen)',
  waterborneElectricity: 'El (vattenburen)',
  oil: 'Fossil olja, Eldningsolja',
  districtHeating: 'Fjärrvärme',
  biofuels: 'Biobränsle',
  fossilGas: 'Fossil gas (naturgas)',
  geothermal: 'Markvärmepump',
  wood: 'Ved',
  heatPumpAirWater: 'Värmepump luft/vatten',
  heatPumpAirAir: 'Värmepump luft/luft',
  heatPumpExhaustAir: 'Värmepump frånluft',
  other: 'Annat',
}

const coolingTypes = {
  heatPumpAirWater: 'Värmepump luft/vatten',
  heatPumpAirAir: 'Värmepump luft/luft',
  districtCooling: 'Fjärrkyla',
  roomAirConditioners: 'Luftkonditionering per rum',
  sunScreens: 'Solsegel/markis (passiv)',
}

const meta = {
  type: 'primitive',
  documents: 'primitive',

  propertyId: {
    label: 'Fastighetsbeteckning',
    validate: isRequired,
    description: `Fastighetsbeteckning enligt fastighetsregistret. Format: [Kommun Trakt Enhet:Block]. 

[Läs mer](https://www.lantmateriet.se/sv/Kartor-och-geografisk-information/Kartor/min-karta/hitta-fastighetsbeteckning-i-min-karta/)`,
  },

  organization: {
    label: 'Organisation',
    sourced: false,
    type: 'select',
    options: ({ team }) =>
      toOptions(mapValues(team?.organizations, org => getValue(org.name))),
    link: (id, { caseId }) =>
      caseId ? `/report/${caseId}/organization/${id}` : `/organization/${id}`,
  },

  owner: {
    label: 'Ägare',
    description: `Fastighetens ägare`,
    format: ({ orgNr, name }) =>
      name
        ? `${capitalizeSentence(name.toLowerCase())} ${
            orgNr ? `\n(${orgNr})` : ''
          }`
        : orgNr,
    type: 'section',
    fields: {
      name: {
        label: 'Namn',
        validate: isRequired,
        placeholder: 'Namn',
      },
      orgNr: {
        label: 'Organisationsnummer',
        validate: isOrgNr,
        placeholder: 'XXXXXX-XXXX',
      },
    },
  },

  address: {
    label: 'Adress',
    format: ({ address, zipCode, city }) =>
      `${address ? `${summarizeAddresses(address)}\n` : ''}${[zipCode, city]
        .filter(i => i)
        .join(' ')}`,
    type: 'section',
    transform: {
      read: input => {
        if (isArray(input)) {
          return summarizeAddresses(input)
        } else {
          return input
        }
      },
      write: input => input,
    },
    fields: {
      address: {
        label: 'Adress',
        validate: isRequired,
      },
      zipCode: {
        label: 'Postnr',
        validate: matches(/^\d{5}$/, 'Måste bestå av fem siffror'),
      },
      city: {
        label: 'Ort',
        validate: isRequired,
      },
    },
  },

  taxedValue: {
    label: 'Fastighetens Taxeringsvärde',
    type: 'section',
    format: ({ totalValue, landValue, buildingValue }) =>
      totalValue
        ? `${(totalValue / 1000000).toFixed(1)} MSEK (mark: ${
            landValue ? (landValue / 1000000).toFixed(1) : '—'
          }, byggnader: ${
            buildingValue ? (buildingValue / 1000000).toFixed(1) : '—'
          })`
        : '—',
    fields: {
      totalValue: {
        label: 'Totalt Taxeringsvärde',
        unit: 'kSEK',
        type: 'number.0',
      },
      landValue: {
        label: 'Värde mark',
        unit: 'kSEK',
        type: 'number.0',
      },
      buildingValue: {
        label: 'Värde byggnader',
        unit: 'kSEK',
        type: 'number.0',
      },
    },
  },

  isJointTaxed: {
    label: 'Ingår i sammanföring',
    description: `Fastigheten ingår i en sammanföring, dvs flera fastigheter taxeras tillsammans på en taxeringsenhet.
Gör det svårt att ange ett taxeringsvärde för fastigheten.`,
    type: 'boolean',
  },

  marketValue: {
    label: 'Marknadsvärde',
    type: 'number.0',
    unit: 'MSEK',
  },
  mortgaging: {
    label: 'Pantsättning',
    type: 'number.0',
    unit: 'MSEK',
  },

  gwp: {
    label: 'GWP-kalkyl',
    type: 'boolean',
    labels: ['Finns', 'Finns ej'],
  },

  climateRiskAnalysis: {
    label: 'Klimatriskanalys',
    type: 'boolean',
    labels: ['Finns', 'Finns ej'],
  },

  buildings: {
    label: 'Byggnader',
    type: 'subcollection',
    item: {
      houseNr: {
        label: 'Husnummer',
        type: 'number.0',
      },
      buildingId: {
        label: 'Byggnads-id',
        type: 'section',
        format: ({ prefix, id }) => `${prefix}-${id}`,
        fields: {
          prefix: {
            label: 'Prefix',
          },
          id: {
            label: 'Id',
          },
        },
      },

      isMainBuilding: {
        label: 'Huvudbyggnad',
        description: `Byggnaden utgör huvudbyggnad på fastigheten`,
        type: 'boolean',
        labels: ['Huvudbyggnad', 'Ej huvudbyggnad'],
      },

      address: {
        label: 'Adress',
        format: ({ address, zipCode, city }) =>
          `${address ? `${summarizeAddresses(address)}\n` : ''}${[zipCode, city]
            .filter(i => i)
            .join(' ')}`,
        type: 'section',
        transform: {
          read: input => {
            if (isArray(input)) {
              return summarizeAddresses(input)
            } else {
              return input
            }
          },
          write: input => input,
        },
        fields: {
          address: {
            label: 'Adress',
            validate: isRequired,
          },
          zipCode: {
            label: 'Postnr',
            validate: matches(/^\d{5}$/, 'Måste bestå av fem siffror'),
          },
          city: {
            label: 'Ort',
            validate: isRequired,
          },
        },
      },

      buildingType: {
        label: 'Byggnadstyp',
        description: `Byggnadstyp`,
        type: 'select',
        options: toOptions(buildingTypes),
      },

      taxedValue: {
        label: 'Byggnadens Taxeringsvärde',
        description: `Byggnadens taxeringsvärde`,
        type: 'number.0',
        unit: 'kSEK',
      },
      floorArea: {
        label: 'Yta',
        unit: 'm^{2}',
        type: 'number.0',
        description: `Byggnadens totala yta (t.ex. boyta/lokalyta). 

Värdet finns i fastighetsregistrering eller taxering.`,
      },
      yearOfConstruction: {
        label: 'Nybyggnadsår',
        type: 'year',
        description: `År då byggnaden färdigställdes.

Värdet finns i fastighetsregistrering eller taxering.`,
      },
      yearOfModification: {
        label: 'Tillbyggnadsår',
        type: 'year',
        description: `Året då byggnaden renoverades (om relevant), enligt fastighetsregistret.`,
      },

      hasEpc: {
        label: 'Energideklaration',
        type: 'boolean',
        labels: ['Finns', 'Finns ej'],
      },

      energyClass: {
        label: 'Energiklass',
        type: 'select',
        options: 'ABCDEFG'.split('').map(l => ({ value: l, label: l })),
        description: `Energiklass A - G är en klassificering baserat på fastighetens energianvändning, enligt Boverkets Byggregler (BBR)

Värdet finns på fastighetens energideklaration.`,
      },

      tempArea: {
        label: 'A_{temp}',
        description: `Yta som används som underlag till energideklaration.

Värdet finns på fastighetens energideklaration.
[Läs mer](https://www.boverket.se/sv/om-boverket/publicerat-av-boverket/fragor--svar/bbr-boverkets-byggregler/avsnitt-9-energihushallning/atemp/vad-ar-atemp-for-nagot/)`,
        unit: 'm^{2}',
        type: 'number.0',
      },

      primaryEnergyDemand: {
        label: 'Primärenergital',
        type: 'number',
        unit: 'kWh/m^{2}',
        description: `Primärenergitalet är Boverkets mätvärde för att ange en byggnads energiprestanda
sedan 1/1 2019.

Värdet finns på fastighetens energideklaration.`,
      },
      specificEnergyDemand: {
        label: 'Specifik Energianvändning',
        type: 'number',
        unit: 'kWh/m^{2}',
        description: `Specifik Energianvändning var Boverkets mätvärde för att ange en byggnads energiprestanda
före 1/1 2019.

Värdet finns på fastighetens energideklaration.`,
      },
      epcExpirationDate: {
        label: 'Energideklaration giltig till',
        type: 'date',
        description: `Utgångsdatum för energideklaration, 10 år efter energideklarationens utfärdande.

Värdet finns på fastighetens energideklaration.`,
      },
      epcBuildingClassification: {
        label: 'Byggnadskategori (EPC)',
        type: 'select',
        options: toOptions({
          singleDoubleResident: 'En- och tvåbostadshus',
          apartmentBlock: 'Flerbostadshus',
          special: 'Lokal- och specialbyggnader',
          commercial: 'Lokalbyggnader',
          unknown: '(Okänd)',
        }),
      },

      mainHeatingType: {
        label: 'Huvudsaklig uppvärmning',
        description: `Mekanism för uppvärmning av fastigheten. Inkludera alla alternativ som bidrar signifikant till
uppvärmningen.

Värdet finns i regel angivet i fastighetens energideklaration.`,
        type: 'select',
        multiple: true,
        options: toOptions(heatingTypes),
      },

      yearlyEnergyConsumption: {
        label: 'Årlig energianvändning',
        description: 'Normalårsjusterad',
        type: 'number.0',
        unit: 'kWh',
      },

      energyConsumptionByType: {
        label: 'Årlig energianvändning per energislag',
        type: 'section',
        fields: {
          electricity: {
            label: 'El',
            type: 'number',
            unit: 'kWh',
          },
          remoteHeating: {
            label: 'Fjärrvärme',
            type: 'number',
            unit: 'kWh',
          },
          remoteCooling: {
            label: 'Fjärrkyla',
            type: 'number',
            unit: 'kWh',
          },
          biofuels: {
            label: 'Biobränsle',
            type: 'number',
            unit: 'kWh',
          },
          fossilOil: {
            label: 'Fossil olja',
            type: 'number',
            unit: 'kWh',
          },
          fossilGas: {
            label: 'Fossil gas',
            type: 'number',
            unit: 'kWh',
          },
        },
      },
      energyMix: {
        type: 'section',
        label: 'Elmix',
        fields: {
          renewable: {
            label: 'Andel förnybart',
            type: 'percentage',
            unit: '%',
          },
          nuclear: {
            label: 'Andel kärnkraft',
            type: 'percentage',
            unit: '%',
          },
          fossil: {
            label: 'Andel fossilt',
            type: 'percentage',
            unit: '%',
          },
          other: {
            label: 'Andel övrigt',
            type: 'percentage',
            unit: '%',
          },
        },
      },

      airPermeabilityTested: {
        label: 'Täthetsprov',
        type: 'boolean',
        labels: ['Genomfört', 'Ej genomfört'],
        description: `Har lufttäthetsprovning/provtryckning gjorts för att kontrollera luftläckage/genomtränglighet?

Om täthetsprov är genomfört bör protokollet inkluderas som dokument.`,
      },
      thermography: {
        label: 'Termografiundersökning',
        description: `Har undersökning med värmekamera genomförts för att fastställa värmegenomgångskoefficient?

Om ja, ange även värmegenomgångskoefficient.`,
        type: 'boolean',
        labels: ['Genomfört', 'Ej genomfört'],
      },

      uMean: {
        label: 'Genomsnittlig värmegenomgångskoefficient (U_{m})',
        description: `Ett mått på hur väl fastigheten är isolerad. Kan uppskattas i nya fastigheter eller med 
värmekamera (termografi).`,
        type: 'number.2',
        unit: 'W/m^{2}K',
        validate: isNumber,
      },

      cooling: {
        label: 'Kylning av boutrymmen/lokaler',
        description: `Byggnadens mekanismer för att kyla inomhusmiljön`,
        type: 'section',
        format: ({ hasCooling, type }) => {
          return hasCooling
            ? type.length
              ? enumerateString(
                  type.map(t => coolingTypes[t] ?? `Okänd [${t}]`)
                )
              : 'Kyla finns'
            : 'Ingen kylning'
        },
        fields: {
          hasCooling: {
            label: 'Finns kylning?',
            type: 'boolean',
            labels: ['Ingen kylning', 'kylning finns'],
          },
          type: {
            label: 'Typ av kylning',
            type: 'select',
            multiple: true,
            options: toOptions(coolingTypes),
          },
        },
      },

      thermographyAlternative: {
        label: 'Alternativ kvalitetskontroll för köldläckage',
        description: `Har någon annan metod än termografi använts för att uppskatta fastighetens köldläckage?`,
        type: 'boolean',
        labels: ['Finns', 'Finns ej'],
      },
      combinedHvacOutput: {
        label: 'Kombinerad energiklassning för vvk-system',
        type: 'number',
        unit: 'kW',
        description:
          'Sammanlagd energiklassning för värme- ventilations- och kylsystem i hela fastigheten',
      },

      automatedHvacControlSystem: {
        label: 'Automatiserat kontroll-/övervakningssystem för vvk',
        description: `Finns ett automatiskt system för att monitorera och optimera prestanda för vvk-system?`,
        type: 'boolean',
        labels: ['Finns', 'Finns ej'],
      },

      hvacMonitoringProcess: {
        label: 'Process för kontroll och övervakning av vvk-system',
        description: `Finns en manuell process för att monitorera och optimera prestanda för vvk-system?`,
        type: 'boolean',
        labels: ['Finns', 'Finns ej'],
      },

      energyPerformanceContract: {
        label: 'Energy Performance Contract',
        description: `Omfattas byggnaden av ett Energy Performance Contract/EPC-projekt?

EPC-projekt är större projekt för att förbättra energiprestandan i en eller flera byggnaden, där
en entreprenör tar en helhetsansvar för prestandaförbättringen.`,
        type: 'boolean',
        labels: ['Ja', 'Nej'],
      },
    },
  }, // buildings

  certifications: {
    label: 'Certifieringar',
    type: 'list',
    formatItem: ({ type, level, dateIssued }) =>
      certificationLabel({
        type,
        level,
        dateIssued,
      }),
  },

  attachments: {
    label: 'Dokument',
    type: 'list',
    itemType: 'section',
    formatItem: ({ name }) => name,
    fields: {
      name: {
        label: 'Namn',
      },
      description: {
        label: 'Beskrivning',
        type: 'textArea',
      },
    },
    sourced: false,
  },

  plans: {
    label: 'Planer',
    type: 'list',
    formatItem: (_, { itemId, team, object }) =>
      getValue(team.plans?.[itemId]?.title) ??
      team.plans?.[itemId]?.title ??
      `Plan för ${getValue(object.propertyId)}`,
    link: (_, { itemId, caseId }) =>
      caseId ? `/report/${caseId}/plan/${itemId}` : `/plan/${itemId}`,
    sourced: false,
  },

  riskAssessments: {
    label: 'Riskanalys',
    type: 'list',
    formatItem: (_, { itemId, team, object }) =>
      getValue(team.riskAssessments?.[itemId]?.title) ??
      team.riskAssessments?.[itemId]?.title ??
      `Riskanalys för ${getValue(object.propertyId)}`,
    link: (_, { itemId, caseId }) =>
      caseId
        ? `/report/${caseId}/riskAssessment/${itemId}`
        : `/riskAssessment/${itemId}`,
    sourced: false,
  },
}

export default transform(meta)

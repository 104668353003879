import { useMemo, createElement } from 'react'
import { useTeam, useDocument } from 'providers/Session'
import { useRecent } from 'providers/Recent'
import { qualifyObject } from 'qualifier'
import { getDocumentLabel } from 'utils/data'
import Loader from 'components/Loader'
import Page from 'components/Page'
import Debug from 'components/Debug'

import { PlanDetails } from './Plan'
import RiskAssessmentDetails from './RiskAssessment'

const documentDetails = {
  plan: PlanDetails,
  riskAssessment: RiskAssessmentDetails,
}

const DocumentDetails = ({ match }) => {
  const { documentId, caseId } = match.params
  const { loading: loadingTeam, ...teamData } = useTeam()

  const {
    data: documentData,
    loading: loadingDocument,
    reporter,
    save,
  } = useDocument({ documentId, caseId }, teamData)

  const loading = loadingTeam || loadingDocument
  useRecent(
    'document',
    documentId,
    getDocumentLabel(documentData),
    documentData?.type
  )

  const { missing, inadequate } = useMemo(
    () => {
      return qualifyObject(
        documentId,
        'document',
        documentData,
        teamData /* { properties } */
      )
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(documentData) /* Object.keys(properties) */, teamData]
  )

  return (
    <Page title={getDocumentLabel(documentData)}>
      {loading ? (
        <Loader />
      ) : documentData ? (
        createElement(documentDetails[documentData.type], {
          data: documentData,
          qualificationResults: { missing, inadequate },
          reporter,
          editable: !caseId,
          filePath: reporter,
          save,
        })
      ) : (
        <div></div>
      )}
      <Debug value={documentData} />
    </Page>
  )
}

export default DocumentDetails

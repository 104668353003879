import { Fragment } from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { InlineMarkdown } from 'components/Markdown'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  column-gap: 0;
  justify-content: start;
`

const Label = styled(InlineMarkdown)`
  font-weight: 500;
  margin: 0;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-right: 0.5rem;
  padding-left: ${({ indent }) => `${0.5 + (indent ?? 0)}rem`};
  border: 1px solid ${theme('colors.border')};
  border-bottom-width: 0;
  border-right-width: 0;
  &:nth-last-of-type(2) {
    border-bottom-width: 1px;
  }
  > * {
    border: none;
    font-weight: 500;
  }
`

const Value = styled(InlineMarkdown)`
  margin: 0 0;
  padding: 0.3rem 0.5rem;
  text-align: right;
  border: 1px solid ${theme('colors.border')};
  border-bottom-width: 0;
  &:last-of-type {
    border-bottom-width: 1px;
  }
  &:last-of-type {
    border-bottom-width: 1px;
  }
  > * {
    border: none;
  }
`

const KpiTable = ({ items = [] }) => (
  <Wrapper>
    {items.map(({ label, value, indent }, idx) => (
      <Fragment key={idx}>
        <Label indent={indent}>{label}</Label>
        <Value>{value.toString()}</Value>
      </Fragment>
    ))}
  </Wrapper>
)

export default KpiTable

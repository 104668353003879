import { Link } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import { subscript, superscript } from './plugins'

const Wrapper = styled.div`
  h2 {
    line-height: 1.3;
    font-size: 1.5em;
    margin: 0.5rem 0;
  }
  h3 {
    line-height: 1.3;
    font-size: 1em;
    margin: 0.3rem 0;
  }
  p {
    font-size: 1em;
    line-height: normal;
  }
  ul {
    font-size: 1em;
    list-style: disc;
    padding-left: 1em;
  }
`
const Markdown = props => (
  <Wrapper className={props.className}>
    <ReactMarkdown
      disallowedElements={['img', 'h1']}
      rehypePlugins={[subscript, superscript]}
      components={{
        sub: 'sub',
        a: ({ children, href }) => {
          if (['//', 'http://', 'https://'].some(pre => href.startsWith(pre))) {
            return (
              <a href={href} target="_blank" rel="noopener noreferrer">
                {children}
              </a>
            )
          } else {
            return <Link to={href}>{children}</Link>
          }
        },
      }}
      {...props}
      children={
        Array.isArray(props.children) ? props.children.join('') : props.children
      }
    />
  </Wrapper>
)

export const InlineMarkdown = styled(Markdown)`
  display: inline;
  div,
  p {
    display: inline;
    margin: 0;
    padding: 0;
  }
`

export default Markdown

import { useMemo } from 'react'
import { pickBy } from 'lodash'
import styled from 'styled-components'
import { useTeam, useAssets, useDocuments } from 'providers/Session'
import { qualify } from 'qualifier'

import Card from 'components/Card'
import Loader from 'components/Loader'
import QualificationPie from 'visualization/QualificationPie'

const Wrapper = styled.div`
  padding: 0.5rem 1rem;
  a {
    text-align: right;
  }
`

const Completeness = () => {
  const teamData = useTeam()
  const { data: assets } = useAssets(teamData)
  const { data: documents } = useDocuments([], teamData)

  const plans = pickBy(documents, d => d.type === 'plan')
  const riskAssessments = pickBy(documents, d => d.type === 'riskAssessment')

  const assetStr = JSON.stringify(assets)
  const plansStr = JSON.stringify(plans)
  const raStr = JSON.stringify(riskAssessments)
  const result = useMemo(() => {
    if (assets) {
      const requirementMatrices = ['demobank']
      const result = qualify(
        {
          assets,
          organizations: teamData.organizations,
          plans,
          riskAssessments,
        },
        requirementMatrices
      )
      return result
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetStr, plansStr, raStr])

  return (
    <Card title="Datatäckning" link="/completeness">
      {!result ? (
        <Loader />
      ) : (
        <Wrapper>
          <QualificationPie
            title={false}
            values={[result.progress, 0, 1 - result.progress]}
          />
        </Wrapper>
      )}
    </Card>
  )
}

export default Completeness

import { AttachmentsSection } from 'components/Section'
import RiskSection from './RiskSection'
import ActionSection from './ActionSection'
import SummarySection from './SummarySection'
import LinksSection from '../LinksSection'

const RiskAssessmentDetails = ({
  data,
  qualificationResults,
  editable,
  filePath,
  save,
}) => {
  return (
    <>
      <LinksSection
        title="Omfattade fastigheter"
        data={data.links}
        emptyLabel="Inga fastigheter"
      />
      <SummarySection
        title="Sammanfattning"
        data={data}
        editable={editable}
        save={save}
        filePath={filePath}
        qualificationResults={qualificationResults}
        attachments={data.attachments?.items}
      />
      <RiskSection
        title="Risker"
        description="Lista samtliga klimatrisker som fastigheten påverkas av"
        data={data}
        editable={editable}
        save={save}
        filePath={filePath}
        qualificationResults={qualificationResults}
        attachments={data.attachments?.items}
      />
      <ActionSection
        title="Åtgärder"
        description="Lista åtgärder för att minska riskernas sannolikhet och påverkan"
        data={data}
        editable={editable}
        qualificationResults={qualificationResults}
        save={save}
        filePath={filePath}
        attachments={data.attachments?.items}
      />
      <AttachmentsSection
        key="attachments"
        title="Dokument"
        attachments={data.attachments}
        parent={data}
        save={save}
        editable={editable}
        filePath={filePath}
      />
    </>
  )
}

export default RiskAssessmentDetails

import { useState, useEffect } from 'react'
import { convertDates } from 'utils/firebase'
import { addTeamMeta } from 'data/meta'
import { get } from 'utils/storage'

const useSnapshot = (reporterId, snapshotId, caseId) => {
  const [state, setState] = useState({ loading: true })

  useEffect(() => {
    if (reporterId && snapshotId) {
      get(`${reporterId}/cases/${snapshotId}`)
        .then(data => {
          console.log('Got snapshot')
          setState({
            loading: false,
            data: addTeamMeta(convertDates(data), [], { caseId }),
          })
        })
        .catch(err => {
          console.error(err)
          setState({ loading: false, error: 'Nåt gick fel' })
        })
    } else if (reporterId && !snapshotId) {
      console.log('This report has no snapshot')
      setState({ loading: false })
    }

    return () => {
      setState({ loading: true })
    }
  }, [snapshotId, reporterId, caseId])

  return state
}

export default useSnapshot

import { useMemo } from 'react'
import { pickBy } from 'lodash'
import Switch from 'components/Switch'
import Loader from 'components/Loader'
import Debug from 'components/Debug'
import ActionWrapper from 'components/Section/ActionWrapper'
import SendButton from './SendButton'
import { isCaseOpen, getValue } from 'utils/data'
import { useAssets, useDocuments } from 'providers/Session'
import { qualify } from 'qualifier'

import GeneralStatus from './GeneralStatus'
import DataStatus from './DataStatus'

const ReporterView = ({ teamData, case: _case }) => {
  const { data: assets } = useAssets(teamData)
  const { data: documents } = useDocuments([], teamData)

  const plans = pickBy(documents, d => d.type === 'plan')
  const riskAssessments = pickBy(documents, d => d.type === 'riskAssessment')

  const assetStr = JSON.stringify(assets)
  const plansStr = JSON.stringify(plans)
  const raStr = JSON.stringify(riskAssessments)
  const result = useMemo(() => {
    if (assets) {
      const { creditors } = teamData
      const requirementMatrices =
        creditors[getValue(_case.creditor)].requirements

      const result = qualify(
        {
          assets,
          organizations: teamData.organizations,
          plans,
          riskAssessments,
        },
        requirementMatrices
      )
      return result
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetStr, plansStr, raStr])
  return !result ? (
    <Loader />
  ) : (
    <>
      <Switch
        labels={['Data', 'Diskussion']}
        urls={[`/case/${_case.id}`, `/case/${_case.id}/discussion`]}
        activeIndex={0}
      />
      <ActionWrapper compact={true}>
        <SendButton
          disabled={!isCaseOpen(_case)}
          case={_case}
          teamId={teamData.id}
        />
      </ActionWrapper>
      <GeneralStatus case={_case} qualificationResults={result} />
      <DataStatus
        teamData={teamData}
        case={_case}
        qualificationResults={result}
      />
      <Debug value={result} />
    </>
  )
}

export default ReporterView

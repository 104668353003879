import { createElement } from 'react'
import styled from 'styled-components'
import Trash from './Trash'
import Pencil from './Pencil'
import Plus from './Plus'
import Question from './Question'
import Close from './Close'
import Check from './Check'
import Filter from './Filter'
import ArrowUp from './ArrowUp'
import ArrowDown from './ArrowDown'
import TriangleDown from './TriangleDown'
import Sheet from './Sheet'
import Login from './Login'
import Logout from './Logout'
import Form from './Form'
import Graph from './Graph'
import Search from './Search'
import Data from './Data'
import Warning from './Warning'
import Download from './Download'
import Attachment from './Attachment'
import X from './X'
import Send from './Send'
import Hand from './Hand'
import Document from './Document'
import Pdf from './Pdf'
import Organization from './Organization'
import Building from './Building'
import Pie from './Pie'
import Reply from './Reply'
import Verified from './Verified'
import User from './User'
import Excel from './Excel'
import More from './More'

const icons = {
  trash: Trash,
  pencil: Pencil,
  plus: Plus,
  question: Question,
  close: Close,
  check: Check,
  filter: Filter,
  arrowUp: ArrowUp,
  arrowDown: ArrowDown,
  triangleDown: TriangleDown,
  sheet: Sheet,
  login: Login,
  logout: Logout,
  form: Form,
  graph: Graph,
  search: Search,
  data: Data,
  warning: Warning,
  download: Download,
  attachment: Attachment,
  x: X,
  send: Send,
  hand: Hand,
  document: Document,
  pdf: Pdf,
  organization: Organization,
  building: Building,
  pie: Pie,
  reply: Reply,
  verified: Verified,
  user: User,
  excel: Excel,
  more: More,
}

const filter = props =>
  Object.fromEntries(
    Object.entries(props).filter(([key]) => !['isValid'].includes(key))
  )

const Icon = ({ type, ...props }) =>
  createElement(icons[type] ?? icons.question, filter(props))

export default styled(Icon)`
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  margin: auto;
`

import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { theme, ifProp, switchProp } from 'styled-tools'
import { useUser } from 'providers/Session'
import { useEvents } from 'providers/Events'
import { NakedButton } from 'components/Button'

const TeamWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  min-width: 20rem;
  > div {
    // cursor: pointer;
    user-select: none;
  }
`

const TeamItem = styled.div`
  padding: 0.2rem 0.5rem;
  flex-grow: 1;
  text-align: center;
  cursor: ${ifProp('disabled', 'auto', 'pointer')};
`

const TeamMenuButton = styled(NakedButton)`
  color: ${theme('colors.medium')};
  transform: ${switchProp('rotate', {
    true: 'rotate(-180deg)',
    false: 'none',
  })};
  margin-left: 0.5rem;
  width: 1rem;
`

const TeamList = ({ teams, onClick, className }) => (
  <div className={className}>
    {Object.entries(teams).map(([id, { name }]) => (
      <TeamItem key={id} onClick={e => onClick(id)}>
        {name}
      </TeamItem>
    ))}
  </div>
)

const StyledTeamList = styled(TeamList)`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1;
  background-color: white;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
  ${TeamItem}:hover {
    color: ${theme('colors.dark')};
  }
`

const TeamMenu = () => {
  const { team, teams, setActiveTeam } = useUser()
  const { subscribe, unsubscribe, fire } = useEvents()
  const history = useHistory()
  const [menuOpen, setMenuOpen] = useState(false)
  const currentTeam = teams ? teams[team].name : ''

  const subscription = {}
  useEffect(() => {
    subscription.id = subscribe('click', () => {
      setMenuOpen(false)
    })
    return () => {
      unsubscribe(subscription.id)
      subscription.id = undefined
    }
  })

  const toggle = e => {
    if (menuOpen) {
      setMenuOpen(false)
    } else {
      setMenuOpen(true)
      fire('click', subscription.id)
      e.stopPropagation()
    }
  }

  const setTeam = newActiveTeam => {
    if (newActiveTeam !== team) {
      setActiveTeam(newActiveTeam)
      history.replace('/')
    }
    setMenuOpen(false)
  }
  const hasMultipleTeams = Object.keys(teams ?? {}).length > 1

  return (
    <TeamWrapper>
      <TeamItem
        onClick={hasMultipleTeams ? toggle : null}
        disabled={!hasMultipleTeams}
      >
        {currentTeam}
      </TeamItem>
      {hasMultipleTeams && (
        <TeamMenuButton
          kind="light"
          icon="triangleDown"
          rotate={menuOpen.toString()}
          onClick={toggle}
        />
      )}
      {menuOpen && <StyledTeamList teams={teams} onClick={setTeam} />}
    </TeamWrapper>
  )
}

export default TeamMenu

import { useTeam, useCase } from 'providers/Session'
import { useRecent } from 'providers/Recent'
import {
  getValue,
  getLatestSnapshotId,
  isCaseOpen,
  getAssetLabel,
} from 'utils/data'
import { CodeError } from 'utils/error'

import Page from 'components/Page'
import Loader from 'components/Loader'
import Switch from 'components/Switch'
import Debug from 'components/Debug'

import {
  StaticSections,
  StaticSection,
  AttachmentsSection,
} from 'components/Section'
import { useSnapshot } from 'providers/Session'

const viewModel = {
  summary: {
    label: 'Sammanfattande kommentarer',
    keys: ['caseComment', 'reporterComment', 'futurePlansComment'],
  },
}

const CaseReview = ({ match }) => {
  const { id: caseId } = match.params
  const { loading: loadingTeam, ...teamData } = useTeam()
  const { data: _case, loading: loadingCase, save } = useCase(caseId, teamData)

  const snapshotId = getLatestSnapshotId(_case)

  const { data: snapshotData, loading: loadingSnapshot } = useSnapshot(
    getValue(_case?.reporter),
    snapshotId
  )
  const loading = loadingTeam || loadingCase || loadingSnapshot

  if (teamData?.teamData && teamData.teamData.type !== 'creditor') {
    throw new CodeError('not-found', 'Tried to access case review as reporter')
  }

  useRecent('case', caseId, _case?.title.displayValue)

  const snapshot = snapshotData
    ? {
        organization: Object.values(snapshotData.organizations)[0],
        ...snapshotData,
      }
    : {}

  const assetSpecificComments = Object.entries(snapshot.assets ?? {}).map(
    ([id, asset]) => {
      const label = getAssetLabel(asset)
      return {
        id,
        label,
        description: `Specifika kommentarer angående ${label}`,
        type: 'textArea',
        editable: true,
        sourced: true,
        ...(_case.assetSpecificComments.items?.[id] ?? {}),
      }
    }
  )

  return (
    <Page title={_case?.title.displayValue}>
      {loading ? (
        <Loader />
      ) : snapshot ? (
        <>
          <Switch
            labels={['Data', 'Kommentarer', 'Diskussion']}
            urls={[
              `/case/${caseId}`,
              `/case/${caseId}/review`,
              `/case/${caseId}/discussion`,
            ]}
            activeIndex={1}
          />
          <StaticSections
            viewModel={viewModel}
            data={_case}
            editable={isCaseOpen(_case)}
            save={save}
          />
          <StaticSection
            title="Fastighetsspecifika kommentarer"
            attributes={assetSpecificComments}
            attachments={_case.attachments?.items}
            editable={isCaseOpen(_case)}
            save={({ id, value, attachment }) =>
              save({
                attrName: 'assetSpecificComments',
                itemId: id,
                value,
                attachment,
              })
            }
          />

          <AttachmentsSection
            key="attachments"
            title="Dokument"
            attachments={_case.attachments}
            parent={_case}
            save={save}
            editable={isCaseOpen(_case)}
          />
        </>
      ) : (
        <div></div>
      )}
      <Debug value={_case} />
    </Page>
  )
}

export default CaseReview

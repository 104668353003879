import { useEffect } from 'react'
import { useUser } from 'providers/Session'

export const useRecent = (klass, id, label, type) => {
  const { uid, team, setRecent } = useUser()
  useEffect(() => {
    if (uid && team && klass && id && label) {
      setRecent(klass, id, label, type)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, team, klass, id, label])
}

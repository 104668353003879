import { useEffect, useState } from 'react'
import { mapValues, omit } from 'lodash'
import {
  getFirebase,
  cleanData,
  FieldValue,
  convertDates,
} from 'utils/firebase'
import { addMeta } from 'data/meta'
import { toPlural } from 'utils/data'
import { CodeError } from 'utils/error'

export const useTeamObjects = (query, teamData, type) => {
  const [state, setState] = useState({
    loading: true,
  })

  const { db } = getFirebase()
  useEffect(() => {
    if (query) {
      const unsubscribe = teamData?.id
        ? db
            .collection(`teams/${teamData.id}/${toPlural(type)}`)
            .where(...query)
            .onSnapshot(snapshot => {
              const data = Object.fromEntries(
                snapshot.docs.map(doc => {
                  const data = convertDates(doc.data())
                  return [
                    doc.id,
                    addMeta(data, type, {
                      team: teamData,
                      object: data,
                      id: doc.id,
                    }),
                  ]
                })
              )

              setState({
                loading: false,
                data,
              })
            })
        : undefined
      return () => {
        unsubscribe?.()
        setState({ loading: true })
      }
    } else {
      setState({ loading: false })
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(query?.[2]), teamData.id])

  return state
}

export const useTeamObject = (id, teamData, type, { supressErrors } = {}) => {
  const [state, setState] = useState({
    loading: true,
  })

  const { db } = getFirebase()

  useEffect(() => {
    const unsubscribe =
      id && teamData.id
        ? db
            .doc(`teams/${teamData.id}/${toPlural(type)}/${id}`)
            .onSnapshot(doc => {
              if (!doc.exists) {
                setState({
                  error: new CodeError(
                    'not-found',
                    `Error in useTeamObject: ${type}/${id} does not exist`
                  ),
                  loading: false,
                })
              } else {
                const objectData = convertDates(doc.data())
                setState({
                  id,
                  objectData,
                  loading: false,
                  data: addMeta(objectData, type, {
                    team: teamData,
                    object: objectData,
                    id,
                  }),
                })
              }
            })
        : undefined

    return () => {
      setState({ loading: true })
      unsubscribe?.()
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, teamData.id])

  // Use this when team data is updated but object is static
  useEffect(() => {
    if (teamData.id && state.objectData) {
      setState({
        loading: false,
        data: addMeta(state.objectData, type, {
          team: teamData,
          object: state.objectData,
          id,
        }),
      })
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(teamData)])

  const set = data =>
    db
      .doc(`teams/${teamData.id}/${toPlural(type)}/${id}`)
      .set(data, { merge: true })

  // args = {
  //   attrName,
  //   subcollectionId,
  //   itemId,
  //   value,
  //   attachment,
  //   comment,
  // }
  const save = args => {
    if (args.attrName?.includes('.')) {
      const [subcollectionId /*items*/, , itemId, attrName] =
        args.attrName.split('.')
      return saveSubcollectionItemAttr({
        ...args,
        subcollectionId,
        itemId,
        attrName,
      })
    } else {
      return args.subcollectionId
        ? args.attrName
          ? saveSubcollectionItemAttr(args)
          : args.value
          ? addSubcollectionItem(args)
          : deleteSubcollectionItem(args)
        : args.itemId
        ? saveListItem(args)
        : saveAttr(args)
    }
  }

  const saveAttr = ({ attrName, value, attachment, comment }) =>
    set({
      [attrName]: cleanData(
        {
          value,
          attachment: attachment || undefined,
          comment: comment || undefined,
        },
        true
      ),
    })

  const saveListItem = ({ attrName, itemId, value, attachment, comment }) =>
    set({
      [attrName]: {
        items: {
          [itemId]: value
            ? cleanData(
                {
                  value,
                  attachment: attachment || undefined,
                  comment: comment || undefined,
                },
                true
              )
            : FieldValue.delete(),
        },
      },
    })

  const deleteSubcollectionItem = ({ subcollectionId, itemId }) => {
    console.log(`Deleting subcollectionitem ${subcollectionId}.${itemId}`)
    set({
      [subcollectionId]: {
        items: {
          [itemId]: FieldValue.delete(),
        },
      },
    })
  }

  const addSubcollectionItem = ({ subcollectionId, itemId, value }) =>
    set({
      [subcollectionId]: {
        items: {
          [itemId]: mapValues(value, attrValue =>
            cleanData(
              {
                value: attrValue,
              },
              true
            )
          ),
        },
      },
    })

  const saveSubcollectionItemAttr = ({
    subcollectionId,
    itemId,
    attrName,
    value,
    attachment,
    comment,
  }) =>
    set({
      [subcollectionId]: {
        items: {
          [itemId]: {
            [attrName]: cleanData(
              {
                value,
                attachment: attachment || undefined,
                comment: comment || undefined,
              },
              true
            ),
          },
        },
      },
    })

  if (state.error && !supressErrors) {
    throw state.error
  }

  return {
    save,
    saveListItem,
    // Hack to make sure it never returns any data that doesn't correspond to the provided id
    // Can probably be done better by someone who understands hooks
    ...(id === state.id
      ? state
      : { ...omit(state, ['data', 'objectData']), loading: true }),
  }
}

import React, { useRef, useEffect, useCallback } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  transition: 200ms height;
  overflow: hidden;
  height: 0;
`

const Collapsible = ({ className, children, expanded = true }) => {
  const containerRef = useRef(null)

  useEffect(() => {
    containerRef.current.style.height = `${containerRef.current.scrollHeight}px`
    if (!expanded) {
      const h = containerRef.current.offsetHeight // eslint-disable-line no-unused-vars
      containerRef.current.style.height = 0
      containerRef.current.style.overflow = 'hidden'
    }
  }, [expanded])

  const transitionEnd = useCallback(() => {
    containerRef.current.style.height = expanded ? 'auto' : '0'
    if (expanded) {
      containerRef.current.style.overflow = 'visible'
    }
  }, [expanded])

  return (
    <Container
      className={className}
      ref={containerRef}
      expanded={expanded}
      onTransitionEnd={transitionEnd}
    >
      {children}
    </Container>
  )
}

export default Collapsible

import styled from 'styled-components'
import { getValue } from 'utils/data'
import { useTeam } from 'providers/Session'
import Loader from 'components/Loader'
import List from 'components/List'
import Page from 'components/Page'
import Card from 'components/Card'
import Debug from 'components/Debug'
import { Source } from 'components/List'

const Wrapper = styled(Card)`
  padding: 1.2rem 0;
  overflow: hidden;
`

const PlanList = props => {
  const { plans, properties, loading } = useTeam()

  const items = Object.entries(plans ?? {}).map(([id, plan]) => {
    return {
      id,
      title: plan.title,
      subtitle: getValue(properties[plan.property].propertyId),
      url: `/plan/${id}`,
    }
  })

  return (
    <Page title="Planer">
      <Wrapper>
        {loading ? (
          <Loader />
        ) : !items ? (
          <div></div>
        ) : (
          <List
            data={items}
            filter={filterStr =>
              ({ title, subtitle }) =>
                title.toLowerCase().includes(filterStr.toLowerCase()) ||
                subtitle.toLowerCase().includes(filterStr.toLowerCase())}
            sort={({ title: t1 }, { title: t2 }) => t1.localeCompare(t2, 'sv')}
          >
            <Source />
          </List>
        )}
      </Wrapper>
      <Debug value={plans} />
    </Page>
  )
}

export default PlanList

import styled from 'styled-components'
import { useTeam } from 'providers/Session'
import Loader from 'components/Loader'
import List, { Source } from 'components/List'
import Page from 'components/Page'
import Card from 'components/Card'
import Debug from 'components/Debug'
import NewReporterButton from './NewReporterButton'
import ActionWrapper from 'components/Section/ActionWrapper'

const Wrapper = styled(Card)`
  padding-bottom: 1.2rem;
  position: relative;
`

const ReporterList = props => {
  const { reporters, loading } = useTeam()

  const items = Object.entries(reporters ?? {}).map(([id, reporter]) => {
    return {
      id,
      title: reporter.name.displayValue,
      subtitle: reporter.orgNr.displayValue,
      url: `/reporter/${id}`,
      disabled: reporter.pending,
    }
  })

  return (
    <Page title="Kunder">
      <ActionWrapper compact={true}>
        <NewReporterButton />
      </ActionWrapper>
      <Wrapper>
        {loading ? (
          <Loader />
        ) : !items ? (
          <div></div>
        ) : (
          <List
            data={items}
            filter={filterStr =>
              ({ title, subtitle }) =>
                title.toLowerCase().includes(filterStr.toLowerCase()) ||
                subtitle.toLowerCase().includes(filterStr.toLowerCase())}
            sort={({ title: t1 }, { title: t2 }) => t1.localeCompare(t2, 'sv')}
          >
            <Source />
          </List>
        )}
      </Wrapper>
      <Debug value={reporters} />
    </Page>
  )
}

export default ReporterList

import { defaultsDeep } from 'lodash'
import { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import Chart from './Chart'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { getLightened } from './colors'

const DonutChart = ({ options, ...props }) => {
  const theme = useContext(ThemeContext)
  const colors = getLightened(
    theme.colors.primary,
    props.data.datasets[0].data.length
  )
  return (
    <Chart
      type="doughnut"
      {...props}
      plugins={[ChartDataLabels]}
      options={defaultsDeep(options, {
        color: colors,
        borderColor: 'white',
        radius: ctx => 100 - ctx.datasetIndex * 5 + '%',
        cutout: '50%',
        plugins: {
          tooltip: {
            callbacks: {
              title: ([ctx]) => ctx.dataset.label,
            },
          },
        },
      })}
    />
  )
}

export default DonutChart

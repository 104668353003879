import { useState, createElement } from 'react'
import styled from 'styled-components'
import { get, pickBy } from 'lodash'
import { nanoid, sortByDate } from 'utils/data'
import { prefixStrings } from 'utils/string'
import { FieldValue } from 'utils/firebase'
import {
  BaseSection,
  StaticGroupSection as UnstyledStaticGroupSection,
} from 'components/Section'
import Button, { NakedButton } from 'components/Button'
import Markdown from 'components/Markdown'
import AccordionContext from 'components/AccordionContext'

const ButtonRow = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
`
const Description = styled(Markdown)`
  padding: 0.5rem;
`

const StaticGroupSection = styled(UnstyledStaticGroupSection)`
  > hgroup > h2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 2rem;
  }
`

const RiskSection = ({
  title,
  description,
  data,
  editable = true,
  save: _save,
  add,
  addLabel,
  delete: _delete,
  filePath,
  qualificationResults,
}) => {
  const [expanded, setExpanded] = useState({})

  const _setExpanded = (id, status) => {
    setExpanded({ ...expanded, [id]: status })
  }

  const save = async args => {
    if (args.attrName?.endsWith('.action') && args.value === 'new') {
      const actionId = nanoid()
      const [, , riskId] = args.attrName.split('.')
      const risk = data.risks.items[riskId]
      await _save({
        subcollectionId: 'actions',
        itemId: actionId,
        value: {
          timestamp: FieldValue.serverTimestamp(),
          title: risk.description.displayValue
            ? `Åtgärd mot "${risk.description.displayValue}"`
            : risk.category.displayValue
            ? `Åtgärd mot ${risk.category.displayValue}`
            : `Ny Åtgärd`,
        },
      })
      await _save({
        ...args,
        value: actionId,
      })
    } else {
      return _save(args)
    }
  }

  const addRisk = async () => {
    const riskId = nanoid()
    setExpanded({ [riskId]: true })
    await _save({
      subcollectionId: 'risks',
      itemId: riskId,
      value: {
        timestamp: FieldValue.serverTimestamp(),
      },
    })
  }

  const deleteRisk = riskId => e => {
    e.preventDefault()
    e.stopPropagation()
    save({
      subcollectionId: 'risks',
      itemId: riskId,
      value: undefined,
    })
  }

  const DeleteButton = ({ id }) => (
    <NakedButton icon="trash" title="Ta bort risk" onClick={deleteRisk(id)} />
  )

  const viewModel = data
    ? Object.fromEntries(
        Object.entries(data.risks.items)
          .sort(([, r1], [, r2]) => sortByDate('timestamp.value')(r1, r2))
          .map(([id, risk]) => {
            const keys = Object.keys(
              pickBy(
                {
                  category: true,
                  description: true,
                  impact: true,
                  action: true,
                  residualImpact: true,
                },
                v => v
              )
            )

            return [
              id,
              {
                label:
                  risk.description.displayValue ??
                  risk.category.displayValue ??
                  'Ny Risk',
                collapsible: true,
                keys: prefixStrings(`risks.items.${id}.`, keys),
              },
            ]
          })
      )
    : {}

  return (
    <BaseSection title={title}>
      <Description>{description}</Description>
      <AccordionContext.Provider
        value={{ setExpanded: _setExpanded, expanded }}
      >
        {Object.entries(viewModel).map(([sectionId, meta], idx) => (
          <StaticGroupSection
            key={sectionId}
            id={sectionId}
            title={meta.label}
            subtitle={meta.subtitle}
            status={createElement(DeleteButton, { id: sectionId })}
            highlight={meta.highlight}
            collapsible={meta.collapsible}
            attributes={meta.keys.map(key => ({
              id: key,
              ...get(data, key),
              editable: get(data, key)?.editable && editable,
              missing: qualificationResults?.missing[key],
              inadequate: qualificationResults?.inadequate[key],
            }))}
            attachments={data.attachments?.items}
            save={save}
            filePath={filePath}
          />
        ))}
      </AccordionContext.Provider>
      <ButtonRow>
        <Button onClick={() => addRisk()}>Ny Risk</Button>
      </ButtonRow>
    </BaseSection>
  )
}

export default RiskSection

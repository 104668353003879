import { get, mapValues } from 'lodash'
import { BaseSection, StaticGroupSection } from 'components/Section'
import ClimateScenarioSection from './ClimateScenarioSection'
import SuggestedRisksSection from './SuggestedRisksSection'
import { getValue } from 'utils/data'

const climateScenario = {
  temperature: {
    label: 'Temperatur',
    impact: 3,
    comment: '* 4,5-5°C ökning av medeltemperatur',
  },
  heatWave: {
    label: 'Värmebölja',
    impact: 3,
    comment: `
* Ökning av antalet högsommardygn från 20 till > 44 dygn
* Ökning av antalet tropiska dygn från 0 till 6 per år
* Ökning av tid för längsta värmeböljan från 20 till >44 dygn`,
  },
  drought: {
    label: 'Torka/vattenstress',
    impact: 1,
    comment: `
* Ingen ökning av antalet torra dygn eller längsta torrperiod
* Vattenstress är inte en riskfaktor i området`,
  },
  precipitation: {
    label: 'Nederbörd',
    impact: 1,
    comment: '* 3% ökning av nederbörd per månad',
  },
  heavyPrecipitation: {
    label: 'Skyfall',
    impact: 3,
    comment: `
* 20% fler dygn med kraftig nederbörd
* 2-4 dygn med extrem nederbörd (från 2 idag)`,
  },
  flood: {
    label: 'Översvämning',
    impact: 1,
    comment: '* Ingen översvämningsrisk',
  },
}

const suggestedRisks = {
  one: {
    risk: {
      category: { category: 'temperature', driver: 'heatWave' },
      description: `Förhöjd inomhustemperatur under värmebölja påverkar attraktivitet samt hälsa för känsliga boende`,
      motivation: `* Ökning av värmebölja i klimatscenario
}
* Ingen aktiv kylning installerad
* Väl isolerad byggnad`,
    },
    action: { title: 'Installera aktiv kylning' },
  },
  two: {
    risk: {
      category: { category: 'water', driver: 'heavyPrecipitation' },
      description: `Risk för översvämning av källarplan vid skyfall`,
      motivation: `* Ökning av skyfall i klimatscenario
* Trappbassäng finns
* Dålig avrinning i närområdet
* Källarplan finns`,
    },
    action: { title: 'Förbättra avrinning kring trappbassäng' },
  },
  three: {
    risk: {
      category: { category: 'water', driver: 'heavyPrecipitation' },
      description: `Risk för fuktskador på fasad vid skyfall`,
      motivation: `* Ökning av skyfall i klimatscenario`,
    },
    action: { title: 'Förbättra tätning vid utsatta delar av fasaden' },
  },
  four: {
    risk: {
      category: { category: 'water', driver: 'heavyPrecipitation' },
      description: `Risk för fuktskador på tak vid skyfall`,
      motivation: `* Ökning av skyfall i klimatscenario
* Platt tak, ej sedumtak`,
    },
    action: { title: 'Öka tätning och förbättra avrinning på tak' },
  },
  five: {
    risk: {
      category: { category: 'temperature', driver: 'changingTemperature' },
      description: `Snabbare utslitning av takpapp pga högre temperatur`,
      motivation: `* Ökning av temperatur i klimatscenario
* Byggnader med papptak`,
    },
    action: { title: 'Öka underhållsfond och tätare monitorering av tak' },
  },
}

const SummarySection = ({
  title,
  description,
  data,
  editable = true,
  save,
  filePath,
  qualificationResults,
}) => {
  const summaryKeys = [
    'status',
    'topography',
    'environment',
    'buildingCharacteristics',
  ]

  const _suggestedRisks = mapValues(suggestedRisks, ({ risk, action }, id) => ({
    risk: {
      ...risk,
      added: Object.values(data.risks.items).some(
        risk => getValue(risk.fromStandardRisk) === id
      ),
    },
    action,
  }))

  return (
    <BaseSection title="Sammanfattning">
      <StaticGroupSection
        title="Fastighetsdata"
        collapsible={true}
        expanded={true}
        attributes={summaryKeys.map(key => ({
          id: key,
          ...get(data, key),
          editable: get(data, key).editable && editable,
          missing: qualificationResults?.missing?.[key],
          inadequate: qualificationResults?.inadequate?.[key],
        }))}
        attachments={data.attachments?.items}
        save={save}
        filePath={filePath}
      />
      <ClimateScenarioSection
        title="Klimatscenario"
        collapsible={true}
        expanded={true}
        data={climateScenario}
      />
      <SuggestedRisksSection
        title="Förslag på risker"
        collapsible={true}
        expanded={false}
        data={_suggestedRisks}
        save={save}
      />
    </BaseSection>
  )
}

export default SummarySection

import { omit } from 'lodash'
import styled, { css } from 'styled-components'
import { ifProp, theme } from 'styled-tools'
import { Link } from 'react-router-dom'
import Icon from './Icon'
import UnstyledLoader from 'components/Loader'

const getColor =
  (isBackground = false, backgroundColor = theme('colors.white')) =>
  props => {
    const color = props.theme.colors[props.kind ?? 'primary']
    return isBackground !== (!!props.$inverted ?? false)
      ? color
      : backgroundColor
  }

const Loader = styled(UnstyledLoader)`
  position: absolute;
  top: 0.6rem;
  bottom: 0.6rem;
  left: 1.2rem;
  right: 1.2rem;
  max-width: 5rem;
`

const ButtonBase = styled.button.attrs(({ type, ...props }) => ({
  type: type || 'button',
  ...props,
}))`
  position: relative;
  display: block;
  background: ${getColor(true)};
  border: 1px solid;
  border-color: ${getColor(false)};
  color: ${ifProp('$loading', getColor(true), getColor(false))};
  opacity: ${ifProp('disabled', 0.5, 1)};
  > not(${Loader}) {
    visibility: ${ifProp('$loading', 'hidden', 'visible')};
  }

  label {
    font-size: 1rem;
    display: block;
    margin-bottom: 0;
    cursor: pointer;
  }

  ${ifProp(
    'icon',
    css`
      min-width: 3.5rem;
      padding-left: ${ifProp('hasChildren', '2.7rem', '0')};
    `,
    ''
  )}

  ${Loader} {
    color: ${getColor(false)};
  }

  svg {
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 1rem;
    ${ifProp(
      'hasChildren',
      css`
        height: 1.2rem;
        width: 1.2rem;
      `,
      css`
        height: 1.5rem;
        width: 1.5rem;
      `
    )}
  }
`

const UnstyledButton = ({ children, loading, inverted, ...props }) => (
  <ButtonBase
    {...props}
    $loading={loading}
    $inverted={inverted}
    hasChildren={!!children}
  >
    {props.icon && <Icon type={props.icon} />}
    {children ?? '\u00A0'}
    {loading && <Loader />}
  </ButtonBase>
)

const Button = styled(UnstyledButton)``

export default Button

export const NakedButtonBase = styled.button.attrs(({ type, ...props }) => ({
  type: type || 'button',
  ...props,
}))`
  margin: 0;
  padding: 0;
  border: none;
  text-transform: none;
  font-size: 1rem;
  font-weight: 300;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  svg {
    position: relative;
    top: auto;
    left: auto;
    margin: 0;
    height: 1rem;
    width: 1rem;
    transform: none;
  }

  label {
    font-size: 1rem;
    display: block;
    margin-left: 0.5rem;
    margin-bottom: 0;
  }
`

const UnstyledNakedButton = ({ children, ...props }) => (
  <NakedButtonBase {...omit(props, 'loading')} hasChildren={!!children}>
    {props.icon && <Icon type={props.icon} />}
    {children}
    {props.loading && <Loader />}
  </NakedButtonBase>
)

export const NakedButton = styled(UnstyledNakedButton)``

const StyledLink = styled(Link)`
  position: relative;
  display: block;
  background: ${getColor(true)};
  border: 1px solid;
  border-color: ${getColor(false)};
  color: ${ifProp('loading', getColor(true), getColor(false))};
  opacity: ${ifProp('disabled', 0.5, 1)};
  > not(${Loader}) {
    visibility: ${ifProp('loading', 'hidden', 'visible')};
  }
  * {
    color: currentColor;
  }

  ${ifProp(
    'icon',
    css`
      min-width: 3.5rem;
      padding-left: 2.7rem;
      label {
        font-size: 1rem;
        display: block;
        margin-bottom: 0;
      }
    `,
    ''
  )}

  svg {
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 1rem;
    ${ifProp(
      'hasChildren',
      css`
        height: 1.2rem;
        width: 1.2rem;
      `,
      css`
        height: 1.5rem;
        width: 1.5rem;
      `
    )}
  }

  border-radius: 4px;
  font-weight: 500;
  padding: 0.9em 1.6em;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  &:hover {
    text-decoration: none;
  }
`

export const ButtonLink = ({ children, inverted, ...props }) => (
  <StyledLink {...props} $inverted={inverted}>
    {props.icon && <Icon type={props.icon} />}
    {children ?? '\u00A0'}
  </StyledLink>
)

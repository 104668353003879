import { pickBy, countBy, mapValues } from 'lodash'
import { propAverage } from 'utils/data'

const assetTypeCategories = {
  residential: 'Bostäder',
  residentialAndCommercial: 'Bostäder och lokaler',
  commercial: 'Lokaler',
  industrial: 'Industrifastigheter',
  other: 'Övrigt',
}

const formatNumber = n =>
  parseFloat(n)
    .toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replaceAll(',', ' ')

export const getSummaryKpis = ({ assets, documents }) => {
  const allBuildings = Object.values(assets).flatMap(asset =>
    Object.values(asset.buildings.items)
  )
  const buildingsByType = countBy(
    allBuildings,
    building => building.buildingType.value
  )

  const riskAssessments = pickBy(documents, d => d.type === 'riskAssessment')

  return {
    numberOfProperties: {
      label: 'Antal fastigheter',
      value: Object.keys(assets).length,
    },
    numberOfBuildings: {
      label: 'Antal byggnader',
      value: allBuildings.length,
    },
    ...pickBy(
      mapValues(assetTypeCategories, (label, key) => ({
        label,
        value: buildingsByType[key],
        indent: 1,
      })),
      ({ value }) => value
    ),
    yearOfConstruction: {
      label: 'Byggår (snitt)',
      value: Math.round(propAverage(allBuildings, 'yearOfConstruction')),
    },
    floorArea: {
      label: 'Yta (snitt, per byggnad)',
      value: `${formatNumber(propAverage(allBuildings, 'floorArea'))} m^{2}`,
    },
    riskAssessment: {
      label: 'Antal fastigheter med riskanalys',
      value: Object.keys(riskAssessments).length, // Sort of cheating, but ok
    },
  }
}

const getKpis = (attrNames, object, { indent = 0, prefix } = {}) =>
  attrNames.map(attrName => [
    prefix ? `${prefix}.${attrName}` : attrName,
    {
      label: object[attrName]?.label ?? attrName,
      value: object[attrName]?.displayValue ?? '—',
      indent,
    },
  ])

export const getPropertyKpis = (asset, plans, riskAssessments) => {
  const numberOfBuildings = Object.keys(asset.buildings.items).length
  const numberOfPlans = Object.keys(plans).length
  const numberOfRiskAssessments = Object.keys(riskAssessments).length
  return {
    ...Object.fromEntries(getKpis(['taxedValue'], asset)),
    numberOfBuildings: {
      label: 'Antal byggnader',
      value: numberOfBuildings,
    },
    ...(numberOfBuildings === 0
      ? noBuildings(asset)
      : numberOfBuildings === 1
      ? singleBuilding(asset)
      : multipleBuildings(asset)),
    certifications: {
      label: asset.certifications.label,
      value: Object.keys(asset.certifications.items ?? {}).length
        ? Object.values(asset.certifications.items)
            .map(({ displayValue }) => displayValue)
            .join('\n')
        : '—',
    },
    ...(numberOfPlans
      ? {
          plans: {
            label: 'Utvecklingsplan',
            value: 'Finns',
          },
        }
      : {}),
    riskAssessment: {
      label: 'Riskanalys',
      value: numberOfRiskAssessments ? 'Finns' : 'Saknas',
    },
  }
}

const buildingKpis = [
  'buildingType',
  'yearOfConstruction',
  'yearOfModification',
  'floorArea',
  'energyClass',
  'primaryEnergyDemand',
  'specificEnergyDemand',
  'mainHeatingType',
]

const noBuildings = asset => ({})
const singleBuilding = asset => {
  const building = Object.values(asset.buildings.items)[0]
  return Object.fromEntries(getKpis(buildingKpis, building))
}

const multipleBuildings = asset => {
  return Object.fromEntries(
    Object.entries(asset.buildings?.items ?? {})
      .sort(
        (e1, e2) =>
          parseInt(e1[1].houseNr.value) - parseInt(e2[1].houseNr.value)
      )
      .flatMap(([buildingId, building]) => [
        [
          `${buildingId}.title`,
          { label: `Byggnad ${building.houseNr?.displayValue}`, value: '' },
        ],
        ...getKpis(buildingKpis, building, {
          indent: 1,
          prefix: buildingId,
        }),
      ])
  )
}

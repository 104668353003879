import { createContext, useEffect, useState } from 'react'
import { getFirebase } from 'utils/firebase'
import { setUser, track } from 'utils/tracking'
import { pick } from 'lodash'

const customClaims = ['admin']

export const AuthContext = createContext({
  loading: true,
})

export const AuthProvider = ({ children }) => {
  const { auth } = getFirebase()

  const [state, setState] = useState({
    user: auth.currentUser,
    loading: true,
  })

  useEffect(() => {
    auth.onAuthStateChanged(
      async user => {
        console.log('Auth state changed')
        setState({ loading: true })
        if (user) {
          try {
            const tokenResult = await user.getIdTokenResult()
            await setUser(user.uid, user.email)
            setState({
              user: {
                displayName: user.displayName,
                email: user.email,
                uid: user.uid,
                claims: pick(tokenResult.claims, customClaims),
              },
              loading: false,
            })
          } catch (e) {
            console.log('We have a user, but we cannot get a token ', e)
            setState({
              user: null,
              loading: false,
            })
          }
        } else if (auth.isSignInWithEmailLink(window.location.href)) {
          try {
            const email = window.localStorage.getItem('sciigoMagicLinkEmail')
            if (email) {
              const { user } = await auth.signInWithEmailLink(
                email,
                window.location.href
              )
              window.localStorage.removeItem('sciigoMagicLinkEmail')
              await setUser(user.uid, user.email)
              setState({
                user: {
                  displayName: user.displayName,
                  email: user.email,
                  uid: user.uid,
                },
                loading: false,
              })
            } else {
              console.log('Email is missing')
              setState({
                user: null,
                loading: false,
                emailMissingForMagicLinkSignIn: true,
              })
            }
          } catch (e) {
            console.log('Error in auth, ', e)
            setState({
              user: null,
              loading: false,
              error: e,
            })
          }
        } else {
          setState({
            user: null,
            loading: false,
          })
        }
      },
      err => {
        console.log('Error in auth state')
      }
    )

    return () => {
      setState({
        loading: true,
      })
    }
  }, [auth])

  const logout = () =>
    auth.signOut().then(() => {
      track('logout')
      console.log('Signed out')
    })

  const refreshAuth = async () => {
    try {
      const token = await auth.currentUser.getIdToken(true)
      console.log('Successfully refreshed token ', token)
      return token
    } catch (e) {
      if (e.code === 403) {
        console.log('Auth error getting token, should probably just log out')
        logout()
      } else {
        console.log('Unknown error refreshing token ', e)
      }
    }
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        logout,
        refreshAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

import { useState, useEffect, createElement } from 'react'
import styled from 'styled-components'
import { theme, ifProp } from 'styled-tools'
import Button, { NakedButton } from 'components/Button'
import { addPropsToChildren } from 'utils/react'
import { useEvents } from 'providers/Events'

const CloseButton = styled(NakedButton)`
  position: absolute;
  top: 0.1rem;
  right: 0.1rem;
  color: ${theme('colors.dark')};
  width: 0.8rem;
  height: 0.8rem;
`

const PopupDialog = styled.div`
  position: absolute;
  z-index: 101;
  top: 0;
  right: ${ifProp('isLeft', 'auto', '0')};
  left: ${ifProp('isLeft', '0', 'auto')};
  width: ${props => props.dialogWidth ?? '30rem'};
  background-color: white;
  border: 1px solid ${theme('colors.border')};
  padding: 1rem;
  border-radius: 3px;
`

const PopupWrapper = styled.div`
  position: relative;
`

const StyledNakedButton = styled(NakedButton)`
  margin-top: 0.15rem;
`

const buttons = {
  naked: StyledNakedButton,
  normal: Button,
}

const Popup = ({
  children,
  icon,
  label,
  isOpen: initiallyOpen = false,
  onClose,
  dialogWidth,
  component: Component,
  buttonStyle = 'naked',
  disabled,
  ...props
}) => {
  const { subscribe, unsubscribe, fire } = useEvents()
  const [isOpen, setIsOpen] = useState(initiallyOpen)
  const [isLeft, setIsLeft] = useState(true)

  const subscription = {}
  useEffect(() => {
    subscription.id = subscribe('click', () => {
      close()
    })
    return () => {
      unsubscribe(subscription.id)
      subscription.id = undefined
    }
  })

  const open = e => {
    const bounds = e.target.getBoundingClientRect()
    if (bounds.left > (5 / 8) * document.documentElement.clientWidth) {
      setIsLeft(undefined)
    } else {
      setIsLeft(true)
    }
    setIsOpen(true)
    fire('click', subscription.id)
    e.stopPropagation()
    e.preventDefault()
  }

  const close = () => {
    setIsOpen(false)
    onClose?.()
  }

  const childrenWithCloseProp = addPropsToChildren(children, { close })
  return (
    <PopupWrapper {...props} onClick={e => e.stopPropagation()}>
      {isOpen && (
        <PopupDialog isLeft={isLeft} dialogWidth={dialogWidth}>
          <CloseButton icon="close" onClick={close} kind="light" />
          {childrenWithCloseProp}
        </PopupDialog>
      )}
      {Component ? (
        <Component onClick={open} disabled={disabled} />
      ) : (
        createElement(
          buttons[buttonStyle],
          { icon, type: 'button', onClick: open, disabled },
          label && <label>{label}</label>
        )
      )}
    </PopupWrapper>
  )
}

export default Popup

import { pick, pickBy, mapValues, defaultsDeep, union } from 'lodash'
import * as reqs from './requirements'
import { toPlural, objectWithKeys, propwiseAdd } from 'utils/data'
import { CodeError } from 'utils/error'

const qualifiableCollections = ['assets', 'organizations', 'documents']

export const qualify = (
  data,
  requirementMatrixIds = [],
  { includeInternal } = {}
) => {
  requirementMatrixIds.forEach(id => {
    if (!reqs[id]) {
      throw new CodeError('not-found', `Requirement matrix ${id} not found`)
    }
  })
  const results = Object.fromEntries(
    requirementMatrixIds
      .filter(reqId => includeInternal || !reqs[reqId].internal)
      .map(reqId => [reqId, qualifyMatrix(reqId, reqs[reqId], data)])
  )

  const summary = Object.values(results).reduce(
    (cur, ack) => propwiseAdd(cur, ack, ['fulfilled', 'total']),
    {}
  )

  const collections = Object.fromEntries(
    qualifiableCollections.map(collection => {
      return [
        collection,
        mapValues(
          Object.values(results)
            .map(result => result[collection])
            .reduce(
              (acc, cur) =>
                Object.fromEntries(
                  union(Object.keys(acc), Object.keys(cur)).map(id => [
                    id,
                    cur[id] && acc[id]
                      ? combineObjectResults(acc[id], cur[id])
                      : acc[id] ?? cur[id],
                  ])
                ),

              {}
            ),
          addProgress
        ),
      ]

      //[collection, defaultsDeep(...Object.values(results).map(result => result[collection]))]))
    })
  )

  return {
    ...addProgress(summary),
    ...collections,
    ...results,
  }
}

const qualifyMatrix = (id, matrix, data) => {
  const results = pickBy(
    mapValues(matrix.requirements, requirement =>
      qualifyRequirement(requirement, data)
    ),
    value => value
  )

  const requirements = mapValues(results, result => result.summary)
  const { fulfilled, total } = Object.values(requirements).reduce(
    ({ fulfilled, total }, acc) => ({
      fulfilled: fulfilled + acc.fulfilled,
      total: total + acc.total,
    }),
    { fulfilled: 0, total: 0 }
  )

  const objectResults = Object.fromEntries(
    qualifiableCollections.map(collectionName => [
      collectionName,
      summarizeObjectResults(groupObjectResults(results, collectionName), {
        id,
        ...matrix,
      }),
    ])
  )

  return {
    id,
    ...pick(matrix, ['title', 'description']),
    fulfilled,
    total,
    requirements,
    ...objectResults,
  }
}

const qualifyRequirement = (requirement, data) => {
  const { object, type, qualify, disabled } = requirement
  if (disabled) {
    return
  }
  const result = object
    ? qualifyCollection(object, type, data, qualify) // Object type specific requirement
    : qualifyItem(data, data, qualify) // Currently not used (afaics)

  return result
    ? { ...result, ...pick(requirement, ['label', 'description']) }
    : undefined
}

// Qualifies a collection of objects against a single requirement
const qualifyCollection = (objectClass, objectType, data, qualify) => {
  const collectionKey = toPlural(objectClass)
  const items = objectType
    ? pickBy(data[collectionKey], item => item.type === objectType)
    : data[collectionKey]

  if (!Object.keys(items ?? {}).length) {
    return undefined
  }

  const results = mapValues(items, (item, id) =>
    qualifyItem({ id, ...item }, data, qualify)
  )

  const fulfilled = Object.values(results).filter(
    ({ fulfilled }) => fulfilled
  ).length
  const total = Object.keys(results).length
  return {
    [collectionKey]: results,
    summary: {
      fulfilled,
      total,
    },
  }
}

const qualifyItem = (item, data, qualify) =>
  qualify(item, data) ?? { fulfilled: true }

///////////////////////////////////////////////////////////////////////////////////////////

export const qualifyObject = (id, type, data, teamData) => {
  const collectionName = toPlural(type)
  const requirementMatrices = getRequirementMatrices(teamData)
  const nullResult = { missing: {}, inadequate: {} }
  if (!requirementMatrices?.length) {
    return nullResult
  }
  if (data && teamData) {
    const result = qualify(
      { [collectionName]: { [id]: data } },
      requirementMatrices
    )
    return result[collectionName][id] ?? nullResult
  }
  return nullResult
}

const getRequirementMatrices = teamData =>
  !teamData
    ? undefined
    : teamData.creditors
    ? Object.values(teamData.creditors ?? {})[0].requirements
    : teamData.teamData?.requirements ?? ['demobank']

const addProgress = ({ fulfilled, total, ...props }) => ({
  fulfilled,
  total,
  ...(fulfilled !== undefined && total
    ? {
        progress: fulfilled / total,
        progressDisplay: `${((100 * fulfilled) / total).toFixed()} %`,
      }
    : {}),
  ...props,
})

// Qualification results data model:
// {
//   title: matrix title
//   description: matrix description
//   fulfilled: // Number of requirements fullfilled by team
//   total: // Number of requirements

//   requirements: {
//     id: {
//       fulfilled: // Number of objects that fulfill argument [id]
//       total: // Total number of objects relevant for requirement [id]
//       label: // For reference
//       description: // For reference
//     }
//   }

//   properties: {
//     propertyId: {
//       fulfilled: // Number of requirements fulfilled by [propertyId]
//       total: // Total number of requirements relevant for [propertyId]
//       missing: {
//         attr1: [reqId1, reqId2] // attr1, required by reqId1 and reqId2 is missing
//         attr2...
//       }
//       inadequate: {
//         attr1: [reqId1, reqId2] // value for attr1 does not meet requirements reqId1 and reqId2
//         attr2...
//       }
//     }
//   }

//   organizations: {
//     ...
//   }

//   plans: {
//     ...
//   }
// }

const groupObjectResults = (results, collectionName) =>
  defaultsDeep(
    ...Object.values(
      mapValues(results, (result, id) =>
        mapValues(result[collectionName], objectResult => ({
          [id]: { requirement: id, ...objectResult },
        }))
      )
    )
  )

const summarizeObjectResults = (objectResults, matrix) =>
  mapValues(objectResults, requirements =>
    Object.values(requirements).reduce(
      (prev, { requirement, fulfilled, missing, inadequate }) => {
        return {
          fulfilled: prev.fulfilled + (fulfilled ?? 0),
          total: prev.total + 1,
          missing: defaultsDeep(
            prev.missing,
            objectWithKeys(missing, {
              [requirement]: {
                ...pick(matrix.requirements[requirement], [
                  'label',
                  'description',
                ]),
                matrix: pick(matrix, 'id', 'title'),
              },
            })
          ),
          inadequate: defaultsDeep(
            prev.inadequate,
            objectWithKeys(inadequate, {
              [requirement]: {
                ...pick(matrix.requirements[requirement], [
                  'label',
                  'description',
                ]),
                matrix: pick(matrix, 'id', 'title'),
              },
            })
          ),
        }
      },
      {
        fulfilled: 0,
        total: 0,
        missing: {},
        inadequate: {},
      }
    )
  )

const combineObjectResults = (o1, o2) => ({
  fulfilled: o1.fulfilled + o2.fulfilled,
  total: o1.total + o2.total,
  ...defaultsDeep(
    pick(o1, 'missing', 'inadequate'),
    pick(o2, 'missing', 'inadequate')
  ),
})

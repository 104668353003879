import { capitalize } from 'lodash'

export const capitalizeSentence = str =>
  str ? str.split(' ').map(capitalizeIf).join(' ') : ''

const capitalizeIf = str =>
  acronyms.includes(str.toUpperCase().trim())
    ? str.toUpperCase()
    : capitalize(str)

const acronyms = ['AB', 'HB', 'EF', 'KB', 'LLC', 'INC']

export const truncateString = (str, len = 100) =>
  str ? (str.length < len ? str : `${str.slice(0, len - 3)}...`) : undefined

export const enumerateString = tokens =>
  tokens
    ? new Intl.ListFormat('sv', { style: 'long', type: 'conjunction' }).format(
        tokens
      )
    : undefined

export const prefixStrings = (prefix, strings) =>
  prefix ? strings.map(str => `${prefix}${str}`) : strings

import { mapValues } from 'lodash'
import { getTransform } from 'utils/transform'

export const getInitialValue = ({
  value,
  type,
  section,
  fieldArray,
  unit,
  multiple,
  transform,
}) => {
  const { read } = transform ?? getTransform({ type, unit })

  return type === 'section'
    ? mapValues(section, field =>
        getInitialValue({ multiple, transform, ...field })
      )
    : type === 'fieldArray'
    ? fieldArray.map(value =>
        mapValues(value, field =>
          getInitialValue({ multiple, transform, ...field })
        )
      )
    : read(value) ?? (multiple || type === 'fieldArray' ? [] : '')
}

export const getWriteValue = ({ value, type, unit, section, transform }) => {
  const { write } = transform ?? getTransform({ type, unit })

  const data =
    type === 'section'
      ? mapValues(value, (partial, key) =>
          getWriteValue({
            transform,
            ...section[key],
            value: partial,
          })
        )
      : type === 'fieldArray'
      ? value.map(val =>
          mapValues(val, (partial, key) =>
            getWriteValue({
              transform,
              ...section[key],
              value: partial,
            })
          )
        )
      : write(value)
  return data
}

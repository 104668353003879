import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'

const StyledTextArea = styled.textarea`
  overflow: hidden;
  resize: none;
  &[disabled] {
    background-color: transparent;
  }
  ::placeholder {
    color: ${theme('colors.medium')};
  }
`

const TextArea = ({ value, height, ...props }) => {
  const ref = useRef(null)
  useEffect(() => {
    ref.current.style.height = 0
    ref.current.style.height =
      Math.max(height ?? 150, ref.current.scrollHeight) + 'px'
  }, [value, height])

  return <StyledTextArea ref={ref} value={value} {...props} />
}

export default TextArea

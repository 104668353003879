import { findKey, pickBy } from 'lodash'
import { getValue } from 'utils/data'
import { addDates } from 'utils/date'
import { prefixStrings } from 'utils/string'
import { fulfilledAll, requireAll, requireAllInAll, qualifyAll } from '../utils'

export const title = 'Bankens baskrav'

export const description = `Bankens krav på samtliga låntagare

Samtliga krav måste uppfyllas`

export const calculateTotal = fulfilledAll

// If test passed, return undefined
// Otherwise, return why it did not pass
// { missing: [attr1, attr2]}
// { inadequate: [attr1, attr2]}

//

export const requirements = [
  {
    label: 'Policy för mänskliga rättigheter',
    object: 'organization',
    description: `Organisationen ska ha en policy för mänskliga rättigheter, och denna ska vara bifogad som dokument`,
    qualify: ({ humanRightsPolicy }) => {
      if (
        !getValue(humanRightsPolicy) ||
        !humanRightsPolicy?.source?.attachment
      ) {
        return { missing: ['humanRightsPolicy'] }
      }
    },
  },

  {
    label: 'Fastighetsinformation',
    object: 'asset',
    type: 'property',
    description: `Alla byggnader på alla fastigheter har följande data:
- Byggår (och tillbyggnadsår om applicerbart)
- Boyta
- Byggnadstyp`,
    qualify: ({ buildings }) => {
      return requireAllInAll({ buildings }, [
        'yearOfConstruction',
        'floorArea',
        'buildingType',
      ])
    },
  },

  // Buildings that need an EPC:
  // Above 250 sqm that are often visited by the public
  // All buildings with "nyttjanderätt", meaning all rented properties and BRs (and more)
  // All newly built buildings and all buildings that are to be sold

  {
    label: 'Energideklaration',
    object: 'asset',
    type: 'property',
    description: `Alla byggnader har en giltig och komplett energideklaration, dvs:

*För alla byggnader på alla fastigheter:*
- A_{temp}
- Energideklarationens giltighetstid
- Huvudsaklig uppvärmning

*För byggnader energideklarerade efter 1/1 2019:*
- Energiklass
- Primärenergital

*För byggnader energideklarerade mellan 1/1 2014 och 1/1 2019:*
- Energiklass
- Specifik energianvändning

*För byggnader energideklarerade före 1/1 2014:*
- Specifik energianvändning
    `,
    qualify: ({ buildings }) => {
      const qualifyBuilding = ({
        hasEpc,
        tempArea,
        epcExpirationDate,
        mainHeatingType,
        energyClass,
        primaryEnergyDemand,
        specificEnergyDemand,
      }) => {
        if (getValue(hasEpc) === false) {
          return undefined
        }
        const d1 = new Date('2019-01-01')
        const d2 = new Date('2014-01-01')

        if (!getValue(epcExpirationDate)) {
          return requireAll(
            {
              hasEpc,
              tempArea,
              epcExpirationDate,
              mainHeatingType,
              energyClass,
              primaryEnergyDemand,
              specificEnergyDemand,
            },
            { requireTruthy: true }
          )
        }

        const alwaysRequired = {
          hasEpc,
          tempArea,
          epcExpirationDate,
          mainHeatingType,
        }
        const epcDate = addDates(getValue(epcExpirationDate), -10)

        return epcDate > d1
          ? requireAll(
              { ...alwaysRequired, energyClass, primaryEnergyDemand },
              { requireTruthy: true }
            )
          : epcDate > d2
          ? requireAll(
              { ...alwaysRequired, energyClass, specificEnergyDemand },
              { requireTruthy: true }
            )
          : requireAll(
              { ...alwaysRequired, specificEnergyDemand },
              { requireTruthy: true }
            )
      }
      return qualifyAll({ buildings }, qualifyBuilding)
    },
  },
  {
    label: 'Energiklassificering av VVK-utrustning',
    object: 'asset',
    type: 'property',
    description: `Alla byggnader större än 5000 m^{2} som inte innehåller bostäder har information om:
  - Kombinerad energiklassning för vvk-system
  - Ev. Automatiserad kontroll-övervakningssystem för vvk
  - Ev. Process för kontroll och övervakning av vvk-system
  - Ev. Energy Performance Contract
  `,

    qualify: ({ buildings }) => {
      const qualifyBuilding = ({
        floorArea,
        buildingType,
        combinedHvacOutput,
        automatedHvacControlSystem,
        hvacMonitoringProcess,
        energyPerformanceContract,
      }) => {
        if (!getValue(floorArea) || !getValue(buildingType)) {
          return requireAll({ floorArea, buildingType })
        } else if (
          getValue(floorArea) > 5000 &&
          getValue(buildingType) !== 'residential'
        ) {
          return requireAll({
            combinedHvacOutput,
            automatedHvacControlSystem,
            hvacMonitoringProcess,
            energyPerformanceContract,
          })
        }
      }
      return qualifyAll({ buildings }, qualifyBuilding)
    },
  },

  {
    label: 'Utvecklingsplan',
    object: 'asset',
    type: 'property',
    description: `Byggnader med energiklass D eller lägre ska ha en utvecklingsplan`,

    // NOTE: We cannot check the adequacy of the plan at this stage, only the existence
    qualify: ({ id, buildings, documents }, { plans: allPlans }) => {
      const buildingWithEnergyClassLowerThanD = Object.values(
        buildings.items ?? {}
      ).find(
        ({ energyClass }) =>
          getValue(energyClass) &&
          getValue(energyClass).charCodeAt(0) >= 'D'.charCodeAt(0)
      )
      if (!buildingWithEnergyClassLowerThanD) {
        return
      }

      const plans = pickBy(documents, d => d.type === 'plan')

      if (!Object.keys(plans ?? {}).length) {
        return { missing: ['plans'] }
      }
    },
  },

  {
    label: 'Kvantitativa mål',
    object: 'document',
    type: 'plan',
    description: `En plan som är knuten till en eller flera fastigheter ska ha minst ett kvantitativt mål`,
    qualify: plan => {
      const properties = pickBy(
        plan.links,
        l => l.class === 'asset' && l.type === 'property'
      )
      if (!Object.keys(properties).length) {
        return
      }
      if (
        !getValue(plan.primaryEnergyDemandTarget) &&
        !getValue(plan.certificationTarget)
      ) {
        return {
          missing: ['primaryEnergyDemandTarget', 'certificationTarget'],
        }
      }
    },
  },

  // We don't have the power to qualify this requirement at this time
  {
    label: 'Förbättring Energiprestanda',
    object: 'document',
    type: 'plan',
    disabled: true,
    description: `En plan som är knuten till en byggnad med energiklass lägre än D ska ha som mål
att förbättra energiprestanda med minst 30%`,
    qualify: (plan, { properties }) => {
      const property = properties[getValue(plan.property)]
      if (!property) {
        return
      }

      const buildingId = getValue(plan.building)
      if (!buildingId) {
        return
      }
      const building = property.buildings.items[buildingId]
      if (!building) {
        console.error(
          `Building ${buildingId} not found in property ${getValue(
            plan.property
          )}`
        )
        return
      }
      const energyClass = getValue(building.energyClass)
      if (!energyClass || energyClass.charCodeAt(0) < 'D'.charCodeAt(0)) {
        return
      }

      const currentPed =
        getValue(building.primaryEnergyDemand) ??
        getValue(building.specificEnergyDemand)

      const targetPed = getValue(plan.primaryEnergyDemandTarget)

      if (!targetPed) {
        return { missing: ['primaryEnergyDemandTarget'] }
      } else if (targetPed > 0.7 * currentPed) {
        return { inadequate: ['primaryEnergyDemandTarget'] }
      }
    },
  },

  {
    label: 'Klimatriskanalys',
    object: 'asset',
    type: 'property',
    disabled: true, // Cannot check completeness unless we put it in the cache summary, which I hesitate to do
    description: 'Alla fastigheter ska ha en komplett klimatriskanalys',
    qualify: (property, { riskAssessments: allRiskAssessments }) => {
      const riskAssessments = pickBy(
        allRiskAssessments,
        ra => getValue(ra.property) === property.id
      )

      const complete = findKey(
        riskAssessments,
        riskAssessment => getValue(riskAssessment.status) === 'complete'
      )
      if (complete) {
        return undefined
      }
      const allKeys = Object.keys(riskAssessments ?? {})
      if (!allKeys.length) {
        return {
          fulfilled: false,
          missing: ['riskAssessments'],
        }
      } else {
        return {
          fulfilled: false,
          inadequate: prefixStrings('riskAssessments.items.', allKeys),
        }
      }
    },
  },
  {
    label: 'Påverkan',
    object: 'document',
    type: 'riskAssessment',
    description: `Alla risker ska ha angiven påverkan`,
    qualify: ({ risks }) => {
      const qualifyRisk = ({ impact }) => {
        return requireAll({ impact })
      }
      return qualifyAll({ risks }, qualifyRisk)
    },
  },
]

import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { HelmetProvider } from 'react-helmet-async'
import { theme, GlobalStyle } from 'theme'
import { AuthProvider, UserProvider, TeamProvider } from 'providers/Session'
import { EventsProvider } from 'providers/Events'
import ErrorBoundary from 'components/ErrorBoundary'

const Root = ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyle />
      <ErrorBoundary>
        <HelmetProvider>
          <AuthProvider>
            <UserProvider>
              <TeamProvider>
                <EventsProvider>
                  <Router>{children}</Router>
                </EventsProvider>
              </TeamProvider>
            </UserProvider>
          </AuthProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </>
  </ThemeProvider>
)

export default Root

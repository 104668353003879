import { useTeam } from 'providers/Session'
import Card from 'components/Card'
import Loader from 'components/Loader'
import List from 'components/List'
import { Link } from 'react-router-dom'

const ListItem = ({ id, title }) => (
  <div>
    <Link to={`/reporter/${id}`}>{title}</Link>
  </div>
)

const ReporterList = () => {
  const { reporters, loading } = useTeam()
  const items = reporters
    ? Object.entries(reporters ?? {}).map(([id, reporter]) => {
        return {
          id,
          title: reporter.name.displayValue ?? '',
        }
      })
    : null

  const filterFn =
    items?.length > 7
      ? filterStr =>
          ({ title }) =>
            title.toLowerCase().includes(filterStr.toLowerCase())
      : undefined

  return (
    <Card title="Kunder">
      {loading ? (
        <Loader />
      ) : (
        <List
          data={items}
          filter={filterFn}
          sort={({ title: t1 }, { title: t2 }) => t1.localeCompare(t2, 'sv')}
          limit={7}
          emptyLabel="(Inga kunder)"
        >
          <ListItem />
        </List>
      )}
    </Card>
  )
}

export default ReporterList

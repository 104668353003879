import { pick, get } from 'lodash'
import Popup from 'components/Popup'
import Source from './Source'
import AttributeForm from './AttributeForm'
import Value from 'components/Value'
import Markdown from 'components/Markdown'
import BaseSection, { Label, RowWrapper, getTooltipText } from './BaseSection'

const SubSection = ({
  indent,
  save,
  attributes,
  attachments,
  filePath,
  ...attribute
}) => (
  <>
    <Row
      header={true}
      {...attribute}
      attachments={attachments}
      save={save}
      filePath={filePath}
    />
    {attributes.map((attr, idx) => (
      <Row
        key={idx}
        save={save}
        {...attr}
        editable={false}
        sourced={false}
        indent={1}
        filePath={filePath}
      />
    ))}
  </>
)

const Row = ({
  header,
  indent,
  attachments,
  validate,
  save,
  filePath,
  missing,
  inadequate,
  ...attribute
}) => (
  <RowWrapper missing={!!missing} inadequate={!!inadequate}>
    <Label
      indent={indent}
      header={header}
      label={attribute.label}
      tooltip={getTooltipText({
        missing,
        inadequate,
        ...pick(attribute, 'label', 'description'),
      })}
    />
    {!header && (
      <Value
        {...pick(attribute, [
          'value',
          'type',
          'unit',
          'link',
          'displayValue',
          'description',
          'comment',
        ])}
      />
    )}
    {attribute.sourced !== false && attribute.value !== undefined && (
      <Source
        source={attribute.source}
        attachment={attribute.attachment}
        filePath={filePath}
      />
    )}
    {attribute.editable && save && (
      <Popup icon="pencil">
        <AttributeForm
          {...attribute}
          attachments={attachments}
          save={args => save({ ...args, attrName: args.id })}
          validate={validate}
        />
      </Popup>
    )}
  </RowWrapper>
)

const getSubsectionAttributes = ({ section, fieldArray }) =>
  fieldArray
    ? fieldArray.map(value => ({
        ...value.value,
        label: value.key.displayValue,
      }))
    : Object.entries(section).map(([id, attr]) => ({ id, ...attr }))

const StaticSection = ({
  id,
  title,
  subtitle,
  status,
  attributes,
  attachments,
  footer,
  save,
  filePath,
  editable = true,
  collapsible,
  expanded,
  className,
  children,
}) => (
  <BaseSection
    className={className}
    id={id}
    title={title}
    subtitle={subtitle}
    status={status}
    collapsible={collapsible}
    expanded={expanded}
  >
    {attributes.map((attr, idx) =>
      attr.displaySection ? (
        <SubSection
          key={idx}
          save={save}
          {...attr}
          editable={attr.editable && editable}
          attributes={getSubsectionAttributes(attr)}
          attachments={attachments}
          filePath={filePath}
        />
      ) : (
        <Row
          key={idx}
          save={save}
          {...attr}
          editable={attr.editable && editable}
          attachments={attachments}
          filePath={filePath}
        />
      )
    )}
    {children && <RowWrapper>{children}</RowWrapper>}
    {footer && (
      <RowWrapper>
        <Markdown>{footer}</Markdown>
      </RowWrapper>
    )}
  </BaseSection>
)

export const StaticSections = ({
  viewModel,
  data,
  editable = true,
  save,
  filePath,
  qualificationResults,
}) =>
  Object.entries(viewModel).map(([sectionId, meta], idx) => (
    <StaticSection
      key={idx}
      title={meta.label}
      subtitle={meta.subtitle}
      status={meta.status}
      collapsible={meta.collapsible}
      attributes={meta.keys.map(key => {
        if (!get(data, key)) {
          console.log(`Could not get ${key}`)
        }
        return {
          id: key,
          ...get(data, key),
          editable: get(data, key).editable && editable,
          missing: qualificationResults?.missing[key],
          inadequate: qualificationResults?.inadequate[key],
        }
      })}
      attachments={data.attachments?.items}
      save={save}
      filePath={filePath}
    />
  ))

export default StaticSection

import { getValue } from 'utils/data'
import { addDates } from 'utils/date'
import { fulfilledAll, requireAll, requireAllInAll, qualifyAll } from '../utils'

export const title = 'Bank X baskrav'

export const description = `Bank X krav på samtliga låntagare

Samtliga krav måste uppfyllas`

export const calculateTotal = fulfilledAll

// If test passed, return undefined
// Otherwise, return why it did not pass
// { missing: [attr1, attr2]}
// { inadequate: [attr1, attr2]}

//

export const requirements = [
  {
    label: 'Fastighetsinformation',
    object: 'property',
    description: `Alla byggnader på alla fastigheter har följande data:
- Byggår (och tillbyggnadsår om applicerbart)
- Boyta
- Byggnadstyp`,
    qualify: ({ buildings }) => {
      return requireAllInAll({ buildings }, [
        'yearOfConstruction',
        'floorArea',
        'buildingType',
      ])
    },
  },

  // Buildings that need an EPC:
  // Above 250 sqm that are often visited by the public
  // All buildings with "nyttjanderätt", meaning all rented properties and BRs (and more)
  // All newly built buildings and all buildings that are to be sold

  {
    label: 'Energideklaration',
    object: 'property',
    description: `Alla byggnader har en giltig och komplett energideklaration, dvs:

*För alla byggnader på alla fastigheter:*
- A_{temp}
- Energideklarationens giltighetstid
- Huvudsaklig uppvärmning

*För byggnader energideklarerade efter 1/1 2019:*
- Energiklass
- Primärenergital

*För byggnader energideklarerade mellan 1/1 2014 och 1/1 2019:*
- Energiklass
- Specifik energianvändning

*För byggnader energideklarerade före 1/1 2014:*
- Specifik energianvändning
    `,
    qualify: ({ buildings }) => {
      const qualifyBuilding = ({
        hasEpc,
        tempArea,
        epcExpirationDate,
        mainHeatingType,
        energyClass,
        primaryEnergyDemand,
        specificEnergyDemand,
      }) => {
        if (getValue(hasEpc) === false) {
          return undefined
        }
        const d1 = new Date('2019-01-01')
        const d2 = new Date('2014-01-01')

        if (!getValue(epcExpirationDate)) {
          return requireAll(
            {
              hasEpc,
              tempArea,
              epcExpirationDate,
              mainHeatingType,
              energyClass,
              primaryEnergyDemand,
              specificEnergyDemand,
            },
            { requireTruthy: true }
          )
        }

        const alwaysRequired = {
          hasEpc,
          tempArea,
          epcExpirationDate,
          mainHeatingType,
        }
        const epcDate = addDates(getValue(epcExpirationDate), -10)

        return epcDate > d1
          ? requireAll(
              { ...alwaysRequired, energyClass, primaryEnergyDemand },
              { requireTruthy: true }
            )
          : epcDate > d2
          ? requireAll(
              { ...alwaysRequired, energyClass, specificEnergyDemand },
              { requireTruthy: true }
            )
          : requireAll(
              { ...alwaysRequired, specificEnergyDemand },
              { requireTruthy: true }
            )
      }
      return qualifyAll({ buildings }, qualifyBuilding)
    },
  },
  {
    label: 'Energiklassificering av VVK-utrustning',
    object: 'property',
    description: `Alla byggnader större än 5000 m^{2} som inte innehåller bostäder har information om:
  - Kombinerad energiklassning för vvk-system
  - Ev. Automatiserad kontroll-övervakningssystem för vvk
  - Ev. Process för kontroll och övervakning av vvk-system
  - Ev. Energy Performance Contract
  `,

    qualify: ({ buildings }) => {
      const qualifyBuilding = ({
        floorArea,
        buildingType,
        combinedHvacOutput,
        automatedHvacControlSystem,
        hvacMonitoringProcess,
        energyPerformanceContract,
      }) => {
        if (!getValue(floorArea) || !getValue(buildingType)) {
          return requireAll({ floorArea, buildingType })
        } else if (
          getValue(floorArea) > 5000 &&
          parseFloat(getValue(buildingType)?.category) !== 1
        ) {
          return requireAll({
            combinedHvacOutput,
            automatedHvacControlSystem,
            hvacMonitoringProcess,
            energyPerformanceContract,
          })
        }
      }
      return qualifyAll({ buildings }, qualifyBuilding)
    },
  },
]

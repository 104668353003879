import styled from 'styled-components'
import { theme, switchProp } from 'styled-tools'
import Card from 'components/Card'

const Wrapper = styled.div`
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;
  > div:last-child {
    justify-self: end;
  }
`

const Status = styled.span`
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  color: ${switchProp(
    'status',
    { open: theme('colors.brand') },
    theme('colors.primaryText')
  )};
  margin: 0;
`

const Info = styled.p`
  margin: 0.5rem 0;
`

const DataCoverage = styled.div`
  > p {
    margin: 0;
    &:first-child {
      font-size: 2rem;
      font-weight: 500;
    }
    &:last-child {
    }
  }
`

const GeneralStatus = ({ case: _case, qualificationResults: result }) => (
  <Card title="Ärende">
    <Wrapper>
      <div>
        <Info>
          Status:{' '}
          <Status status={_case.status.value}>
            {_case.status.displayValue}
          </Status>
        </Info>
        <Info>Kund: {_case.reporter.displayValue}</Info>
      </div>
      <div>
        <DataCoverage>
          <p>{result?.progressDisplay ?? `0 %`}</p>
          <p>klart</p>
        </DataCoverage>
      </div>
    </Wrapper>
  </Card>
)

export default GeneralStatus

import transform from '../transform'
import { toOptions } from 'utils/data'
import { truncateString } from 'utils/string'
import { formatDate } from 'utils/date'
import certificationTypes from 'data/certificationTypes'

const certLevels = type =>
  certificationTypes[type]?.levels
    ? certificationTypes[type].levels
    : { none: 'Ingen certifiering', certified: 'Certifierad' }

const meta = {
  type: 'primitive',
  links: 'primitive',

  title: {
    label: 'Titel',
    sourced: false,
  },
  summary: {
    label: 'Sammanfattning',
    type: 'textArea',
    format: value => truncateString(value),
    sourced: false,
  },

  // building: {
  //   label: 'Byggnad',
  //   sourced: false,
  //   type: 'select',
  //   options: ({ object: plan, team }) => {
  //     return toOptions(
  //       mapValues(
  //         team.assets[getValue(plan.property)]?.buildings ?? {},
  //         building => `${building.prefix}-${building.id}`
  //       )
  //     )
  //   },
  // },

  timespan: {
    label: 'Tidsspann',
    type: 'section',
    sourced: false,
    format: ({ from, to }) => `${formatDate(from)}—${formatDate(to)}`,
    fields: {
      from: {
        label: 'Från',
        type: 'date',
      },
      to: {
        label: 'Till',
        type: 'date',
      },
    },
  },
  planDocument: {
    type: 'file',
    label: 'Plan (dokument)',
    format: ({ name }) => name,
  },
  totalInvestment: {
    type: 'number.1',
    unit: 'MSEK',
    label: 'Total investering',
  },

  primaryEnergyDemandTarget: {
    type: 'number.0',
    unit: 'kWh/m^{2}',
    label: 'Mål Primärenergital',
  },
  certificationTarget: {
    type: 'section',
    label: 'Förbättring Certifiering',
    format: ({ type, fromLevel, toLevel }) =>
      `${certificationTypes[type].label}: ${certLevels(type)[fromLevel]} -> ${
        certLevels(type)[toLevel]
      }`,
    fields: {
      type: {
        label: 'Typ av certifiering',
        type: 'select',
        options: toOptions(certificationTypes),
      },
      fromLevel: {
        label: 'Startnivå',
        type: 'select',
        options:
          () =>
          ({ type }) =>
            type ? toOptions(certLevels(type)) : [],
        visible:
          () =>
          ({ type } = {}) =>
            !!type,
      },
      toLevel: {
        label: 'Målnivå',
        type: 'select',
        options:
          () =>
          ({ type } = {}) =>
            type ? toOptions(certLevels(type)) : [],
        visible:
          () =>
          ({ fromLevel } = {}) =>
            !!fromLevel,
      },
    },
  },

  climateRiskAnalysis: {
    label: 'Klimatriskanalys',
    type: 'boolean',
    labels: ['Finns', 'Finns ej'],
  },

  maxFlowTaps: { unit: 'l/min', label: 'Maximalt flöde kranar' },
  maxFlowShowers: { unit: 'l/min', label: 'Maximalt flöde duschar' },
  maxFlushWC: { unit: 'l', label: 'Max spolvolym toaletter' },
  avgFlushWC: { unit: 'l', label: 'Medel spolvolym toaletter' },
  maxUseUrinals: { unit: 'l/enh/min', label: 'Max vattenåtgång urinoarer' },
  maxFlushUrinals: { unit: 'l', label: 'Max spolvolym urinoarer' },

  shareRenewableMaterials: {
    type: 'percentage',
    unit: '%',
    label: 'Andel återvinningsbart material',
  },
  actionsMinimizeWaste: {
    type: 'file',
    label: 'Åtgärder för att begränsa avfall och maximera återvinning',
  },
  actionsCircularity: {
    type: 'file',
    label: 'Åtgärder för att stödja cirkularitet',
  },
  materialControls: {
    type: 'file',
    label: 'Kontrollprocess för byggmaterial',
  },
  materialControlsResidents: {
    label:
      'Kontrollprocess för byggmaterial som kan komma i kontakt med boende',
    type: 'file',
  },
  actionsPollution: {
    label: 'Åtgärder för att minska buller, damm och föroreningar',
    type: 'file',
  },

  attachments: {
    label: 'Dokument',
    type: 'list',
    itemType: 'section',
    formatItem: ({ name }) => name,
    fields: {
      name: {
        label: 'Namn',
      },
      description: {
        label: 'Beskrivning',
        type: 'textArea',
      },
    },
    sourced: false,
  },
}

export default transform(meta)

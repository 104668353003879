import { pick, mapValues } from 'lodash'
import { getValue } from 'utils/data'

const transform = (data, { reporters, creditors }) => {
  return {
    ...data,
    to: getTeam(data.to, { reporters, creditors }),
    from: getTeam(data.from, { reporters, creditors }),
    timestamp: data.timestamp?.toDate?.(),
  }
}

export default transform

const teamAttrs = ['name', 'orgNr']

const getTeam = (teamId, { reporters, creditors }) =>
  teamId
    ? {
        id: teamId,
        ...(reporters?.[teamId]
          ? mapValues(pick(reporters[teamId], teamAttrs), attr =>
              getValue(attr)
            )
          : creditors?.[teamId]
          ? mapValues(pick(creditors[teamId], teamAttrs), attr =>
              getValue(attr)
            )
          : {}),
      }
    : undefined

import { useRef, useEffect, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import ChartJS from 'chart.js/auto'
import { defaultsDeep } from 'lodash'

import { getLightened } from './colors'

const defaultOptions = (title, subtitle, primaryColor, data, onClick) => {
  const numberOfDatasets = data.datasets?.length ?? 0
  const colors = getLightened(primaryColor, numberOfDatasets)
  return {
    maintainAspectRatio: false,
    borderColor: (_, { color }) => color,
    backgroundColor: (_, { color }) => color,
    plugins: {
      title: {
        display: (_, { text }) => !!text,
        text: title,
      },
      subtitle: {
        display: (_, { text }) => !!text,
        text: subtitle,
      },
      legend: {
        position: 'top',
        display: numberOfDatasets > 1,
      },
      tooltip: {
        boxPadding: 6,
      },
    },
    color: ({ datasetIndex }) => colors[datasetIndex],
    animation: {
      duration: 100,
    },
    onClick: (_, [target]) => {
      const { datasetIndex, index } = target ?? {}
      if (onClick) {
        onClick({
          index,
          datasetIndex,
          labelId: data?.datasets?.[datasetIndex]?._labelIds?.[index],
          datasetId: data?.datasets?.[datasetIndex]?._id,
          label: data?.labels?.[index],
          value: data?.datasets?.[datasetIndex]?.data?.[index],
        })
      }
    },
  }
}

const useChart = (
  ref,
  type,
  plugins,
  options,
  title,
  subtitle,
  primaryColor,
  data,
  onClick
) => {
  const chartRef = useRef()
  useEffect(() => {
    chartRef.current = new ChartJS(ref.current, {
      type,
      plugins,
    })
    return () => {
      chartRef.current.destroy()
    }
  }, [ref, type, plugins])

  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current
      chart.options = defaultsDeep(
        {},
        options,
        defaultOptions(title, subtitle, primaryColor, data, onClick)
      )
      // console.log('Updating chart due to options')
      chart.update()
    }
  }, [options, title, subtitle, primaryColor, data, onClick])

  useEffect(() => {
    const chart = chartRef.current
    if (chart && data.datasets) {
      const sets = chart.data.datasets
      data.datasets.forEach((dataset, i) => {
        if (sets[i]) {
          Object.keys(sets[i]).forEach(key => {
            sets[i][key] = undefined
          })
          Object.entries(dataset).forEach(([key, value]) => {
            sets[i][key] = value
          })
        } else {
          sets[i] = dataset
        }
      })
      sets.length = data.datasets.length
      // console.log('updating chart due to datasets', data.datasets)
      chart.update()
    }
  }, [chartRef, data.datasets])

  useEffect(() => {
    const chart = chartRef.current
    if (chart && data.labels) {
      chart.data.labels = data.labels
      // console.log('updating chart due to labels', data.labels)
      chart.update()
    }
  }, [chartRef, data.labels])

  return chartRef
}

const Wrapper = styled.div`
  padding: 1rem;
  position: relative;
`
const Canvas = styled.canvas``

const Chart = ({
  type,
  data,
  options,
  className,
  title,
  subtitle,
  plugins,
  onClick,
}) => {
  const theme = useContext(ThemeContext)

  const canvasRef = useRef()

  useChart(
    canvasRef,
    type,
    plugins,
    options,
    title,
    subtitle,
    theme.colors.primary,
    data,
    onClick
  )

  return (
    <Wrapper className={className}>
      <Canvas ref={canvasRef} />
    </Wrapper>
  )
}

export default Chart

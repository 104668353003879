import { useMemo, createElement } from 'react'
import { useTeam, useAsset, addDocument } from 'providers/Session'
import { useRecent } from 'providers/Recent'
import { qualifyObject } from 'qualifier'
import { summarizeAsset, getAssetLabel } from 'utils/data'
import Loader from 'components/Loader'
import Page from 'components/Page'
import Debug from 'components/Debug'

import PropertyDetails from './Property'

const assetDetails = {
  property: PropertyDetails,
}

const AssetDetails = ({ match }) => {
  const { assetId, caseId } = match.params
  const { loading: loadingTeam, ...teamData } = useTeam()

  const {
    data: assetData,
    reporter,
    loading: loadingAsset,
    error,
    save,
  } = useAsset({ assetId, caseId }, teamData)
  const loading = loadingTeam || loadingAsset

  if (!loading && !assetData) {
    console.error(
      `Could not get asset ${assetId}${caseId ? ` in case ${caseId}` : ''}`
    )
    if (error) {
      throw error
    }
  }

  useRecent('asset', assetId, getAssetLabel(assetData), assetData?.type)

  const _addDocument = (type, title) =>
    addDocument(
      {
        type,
        teamId: teamData.id,
        title,
      },
      {
        [assetId]: {
          class: 'asset',
          type: assetData.type,
          ...summarizeAsset(assetData),
        },
      }
    )

  const { missing, inadequate } = useMemo(
    () => {
      return qualifyObject(assetId, 'asset', assetData, teamData)
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(assetData), teamData]
  )

  return (
    <Page title={getAssetLabel(assetData)}>
      {loading ? (
        <Loader />
      ) : assetData ? (
        createElement(assetDetails[assetData.type], {
          data: assetData,
          qualificationResults: { missing, inadequate },
          editable: !caseId,
          filePath: reporter,
          save,
          addDocument: _addDocument,
        })
      ) : (
        <div></div>
      )}
      <Debug value={{ missing, inadequate }} />
    </Page>
  )
}

export default AssetDetails

import { useState } from 'react'
import { theme } from 'styled-tools'
import styled from 'styled-components'
import {
  riskCategories,
  allRiskDrivers as riskDrivers,
} from 'data/riskCategories'
import Markdown from 'components/Markdown'
import Button from 'components/Button'
import { BaseGroupSection } from 'components/Section'
import { nanoid } from 'utils/data'
import { FieldValue } from 'utils/firebase'

const RiskWrapper = styled.div`
  padding: 0.5rem 1rem;
  border-bottom: 1px solid ${theme('colors.border')};
`
const Description = styled.div`
  font-weight: 500;
  cursor: pointer;
  color: ${theme('colors.primary')};
`
const Category = styled.div`
  font-size: 0.8rem;
  color: ${theme('colors.primaryMedium')};
`
const ActionTitle = styled.div`
  font-weight: 500;
`

const Motivation = styled(Markdown)`
  margin: 1rem 0;
`

const ButtonRow = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  > ${Button} {
    margin-right: 1rem;
  }
`

const Risk = ({ id, risk, action, expanded, onClick, save }) => {
  const [loading, setLoading] = useState(false)

  const addRisk = async () => {
    setLoading('risk')
    try {
      const riskId = nanoid()
      await save({
        subcollectionId: 'risks',
        itemId: riskId,
        value: {
          timestamp: FieldValue.serverTimestamp(),
          category: risk.category,
          description: risk.description,
          fromStandardRisk: id,
        },
      })
    } finally {
      setLoading(false)
    }
  }

  const addRiskAndAction = async () => {
    setLoading('riskAndAction')
    try {
      const riskId = nanoid()
      const actionId = nanoid()
      await save({
        subcollectionId: 'actions',
        itemId: actionId,
        value: {
          timestamp: FieldValue.serverTimestamp(),
          title: action.title,
          fromStandardRisk: id,
        },
      })
      await save({
        subcollectionId: 'risks',
        itemId: riskId,
        value: {
          timestamp: FieldValue.serverTimestamp(),
          category: risk.category,
          description: risk.description,
          fromStandardRisk: id,
          action: actionId,
        },
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <RiskWrapper>
      <Description onClick={onClick}>{risk.description}</Description>
      <Category>
        Kategori: {riskCategories[risk.category.category]}/
        {riskDrivers[risk.category.driver]}
      </Category>
      {expanded && (
        <>
          <Motivation>
            {'Utgör trolig risk pga:\n\n'}
            {risk.motivation}
          </Motivation>
          <ActionTitle>Förslag på Åtgärd: {action.title}</ActionTitle>
          <ButtonRow>
            <Button
              onClick={addRisk}
              loading={loading === 'risk'}
              disabled={loading === 'riskAndAction' || risk.added}
              title={risk.added ? 'Risken är redan tillagd' : 'Lägg till risk'}
            >
              Lägg till Risk
            </Button>
            <Button
              onClick={addRiskAndAction}
              loading={loading === 'riskAndAction'}
              disabled={loading === 'risk' || risk.added}
              title={
                risk.added
                  ? 'Risken är redan tillagd'
                  : 'Lägg till risk och åtgärd'
              }
            >
              Lägg till Risk och Åtgärd
            </Button>
          </ButtonRow>
        </>
      )}
    </RiskWrapper>
  )
}

const SuggestedRisksSection = ({
  title,
  collapsible,
  expanded: sectionExpanded,
  data,
  save,
}) => {
  const [expanded, setExpanded] = useState()

  return (
    <BaseGroupSection
      title={title}
      collapsible={collapsible}
      expanded={sectionExpanded}
    >
      {Object.entries(data)
        .filter(([, { risk }]) => !risk.added)
        .map(([id, { risk, action }]) => (
          <Risk
            key={id}
            id={id}
            risk={risk}
            action={action}
            expanded={expanded === id}
            onClick={() => setExpanded(expanded === id ? undefined : id)}
            save={save}
          />
        ))}
    </BaseGroupSection>
  )
}

export default SuggestedRisksSection

import { getValue } from 'utils/data'
import { addDates } from 'utils/date'
import { fulfilledAll, requireAll } from '../utils'

export const title = 'Dalslands Sparbanks Hållbarhetskrav'

export const description = `Dalslands Sparbanks Hållbarhetsdatakrav

Samtliga krav måste vara uppfyllda. Uppfyllda krav garanterar inte positivt kreditbeslut.`

export const calculateTotal = fulfilledAll

export const requirements = [
  {
    label: 'Fastighetsinformation',
    object: 'property',
    description: `Alla fastigheter har följande data:
- Byggår (och tillbyggnadsår om applicerbart)
- Boyta
- Fastighetstyp`,
    qualify: ({ yearOfConstruction, floorArea, propertyType }) => {
      return requireAll({ yearOfConstruction, floorArea, propertyType })
    },
  },
  {
    label: 'Energideklaration',
    object: 'property',
    description: `Alla fastigheter har en giltig och komplett energideklaration, dvs:

*För alla fastigheter:*
- A_{temp}
- energideklarationens giltighetstid
- Huvudsaklig uppvärmning

*För fastigheter energideklarerade efter 1/1 2019:*
- Energiklass
- Primärenergital

*För fastigheter energideklarerade mellan 1/1 2014 och 1/1 2019:*
- Energiklass
- Specifik energianvändning

*För fastigheter energideklarerade före 1/1 2014:*
- Specifik energianvändning
    `,
    qualify: ({
      tempArea,
      epcExpirationDate,
      mainHeatingType,
      energyClass,
      primaryEnergyDemand,
      specificEnergyDemand,
    }) => {
      const d1 = new Date('2019-01-01')
      const d2 = new Date('2014-01-01')

      if (!getValue(epcExpirationDate)) {
        return requireAll({
          tempArea,
          epcExpirationDate,
          mainHeatingType,
          energyClass,
          primaryEnergyDemand,
          specificEnergyDemand,
        })
      }

      const alwaysRequired = {
        tempArea,
        epcExpirationDate,
        mainHeatingType,
      }

      const epcDate = addDates(getValue(epcExpirationDate), -10)

      return epcDate > d1
        ? requireAll({ ...alwaysRequired, energyClass, primaryEnergyDemand })
        : epcDate > d2
        ? requireAll({ ...alwaysRequired, energyClass, specificEnergyDemand })
        : requireAll({ ...alwaysRequired, specificEnergyDemand })
    },
  },
  {
    label: 'Energiklassificering av VVK-utrustning',
    object: 'property',
    description: `Alla fastigheter större än 5000 m^{2} som inte innehåller bostäder har information om: 
- Kombinerad energiklassning för vvk-system
- Ev. Automatiserad kontroll-övervakningssystem för vvk
- Ev. Process för kontroll och övervakning av vvk-system
- Ev. Energy Performance Contract
`,
    qualify: ({
      floorArea,
      propertyType,
      combinedHvacOutput,
      automatedHvacControlSystem,
      hvacMonitoringProcess,
      energyPerformanceContract,
    }) => {
      if (!getValue(floorArea) || !getValue(propertyType)) {
        return requireAll({ floorArea, propertyType })
      } else if (
        getValue(floorArea) > 5000 &&
        ![313, 320].includes(parseFloat(getValue(propertyType)))
      ) {
        return requireAll({
          combinedHvacOutput,
          automatedHvacControlSystem,
          hvacMonitoringProcess,
          energyPerformanceContract,
        })
      }
    },
  },
]

import styled from 'styled-components'
import Card from 'components/Card'

const Wrapper = styled.div`
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;
  > div:last-child {
    justify-self: end;
  }
`

const Info = styled.p`
  margin: 0.5rem 0;
`

const DataCoverage = styled.div`
  > p {
    margin: 0;
    &:first-child {
      font-size: 2rem;
      font-weight: 500;
    }
    &:last-child {
    }
  }
`

const GeneralStatus = ({ qualificationResults: result }) => (
  <Card title="Sammanfattning">
    <Wrapper>
      <div>
        <Info>Kravmatris: demobank</Info>
      </div>
      <div>
        <DataCoverage>
          <p>{result.progressDisplay}</p>
          <p>klart</p>
        </DataCoverage>
      </div>
    </Wrapper>
  </Card>
)

export default GeneralStatus

import { useState } from 'react'
import styled from 'styled-components'
import { useTeam, sendReport, useCases } from 'providers/Session'
import { backendCall } from 'utils/firebase'
import { Link } from 'react-router-dom'
import Loader from 'components/Loader'
import Button, { ButtonLink } from 'components/Button'

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const GoToCase = ({ sender, data }) => (
  <div>
    <ButtonWrapper>
      <ButtonLink to={`/case/${data.caseId}`}>Öppna ärende</ButtonLink>
    </ButtonWrapper>
  </div>
)

const GoToCaseDiscussion = ({ sender, data }) => (
  <div>
    <ButtonWrapper>
      <ButtonLink to={`/case/${data.caseId}/discussion`}>
        Öppna ärende
      </ButtonLink>
    </ButtonWrapper>
  </div>
)

const ApproveRequest = ({ sender, data }) => {
  const teamData = useTeam()
  const { id: teamId, loading: loadingTeam } = teamData
  const { loading: loadingCases, data: cases } = useCases(teamData)
  const [state, setState] = useState({})
  const loading = loadingTeam || loadingCases

  const approveRequest = async () => {
    setState({ loading: true })
    try {
      const result = await sendReport({
        teamId,
        creditor: sender,
        caseId: data.caseId,
      })
      setState({ loading: false, result })
    } catch (error) {
      setState({ loading: false, error })
    }
  }
  return loading ? (
    <Loader />
  ) : (
    <div>
      <ButtonWrapper>
        {cases[data.caseId] ? (
          <ButtonLink to={`/case/${data.caseId}`}>Öppna ärende</ButtonLink>
        ) : (
          <Button
            onClick={approveRequest}
            disabled={state.loading}
            loading={state.loading}
          >
            {cases[data.caseId] ? 'Godkänd' : 'Godkänn'}
          </Button>
        )}
      </ButtonWrapper>
    </div>
  )
}

const ReporterRequestMessage = ({ sender }) => {
  const { id: teamId, creditors, loading: loadingTeam } = useTeam()
  const [state, setState] = useState({})

  const addCreditor = async () => {
    setState({ loading: true })
    try {
      const result = await backendCall('addCreditorToReporter', {
        creditor: sender,
        reporter: teamId,
      })
      setState({ loading: false, result })
    } catch (error) {
      setState({ loading: false, error })
    }
  }

  return (
    <div>
      {loadingTeam ? (
        <Loader />
      ) : creditors?.[sender] ? (
        <p>
          Ansluten! <Link to={`/creditor/${sender}`}>Gå till kreditgivare</Link>
        </p>
      ) : (
        <>
          <Button
            onClick={addCreditor}
            disabled={state.loading}
            loading={state.loading}
          >
            Anslut
          </Button>
        </>
      )}
    </div>
  )
}

const CreditorRequestMessage = ({ sender }) => {
  const { id: teamId, reporters, loading: loadingTeam } = useTeam()
  const [state, setState] = useState({})

  const addReporter = async () => {
    setState({ loading: true })
    try {
      const result = await backendCall('addReporterToCreditor', {
        reporter: sender,
        creditor: teamId,
      })
      setState({ loading: false, result })
    } catch (error) {
      setState({ loading: false, error })
    }
  }

  return (
    <div>
      {loadingTeam ? (
        <Loader />
      ) : reporters?.[sender] ? (
        <p>
          Ansluten! <Link to={`/reporter/${sender}`}>Gå till kund</Link>
        </p>
      ) : (
        <>
          <Button
            onClick={addReporter}
            disabled={state.loading}
            loading={state.loading}
          >
            Lägg till kund
          </Button>
        </>
      )}
    </div>
  )
}

const actions = {
  comment: GoToCaseDiscussion,
  case: GoToCase,
  reportRequest: ApproveRequest,
  reporterRequest: ReporterRequestMessage,
  creditorRequest: CreditorRequestMessage,
}

export default actions

import { getValue } from 'utils/data'
import { dateFromTimestamp } from 'utils/firebase'

const transform = (requestData, { team }) => {
  const { reporter, creditor, property, status, timestamp } = requestData
  const { reporters, creditors, properties } = team
  const { name, orgNr } = reporter ? reporters[reporter] : creditors[creditor]
  const propertyId = reporter
    ? reporters[reporter].properties[property]
    : getValue(properties[property].propertyId)

  const dateStr = dateFromTimestamp(timestamp)?.toLocaleDateString('sv')
  return {
    title: propertyId,
    subtitle: `${name} (${orgNr})`,
    label: `${name} ${dateStr ? `(${dateStr})` : ''}`,
    name,
    orgNr,
    propertyId,
    status,
    property,
    creditor,
    reporter,
    timestamp: dateFromTimestamp(timestamp),
    url: creditors ? `/property/${property}` : undefined,
  }
}

export default transform

// World Bank lending groups (approx, split up Europe & Central Asia)
const geographies = {
  sweden: 'Sverige',
  nordics: 'Norden',
  eu: 'EU',
  otherEurope: 'Europa utom EU',
  centralAsia: 'Centralasien',
  apac: 'Östra Asien och Stillahavsregionen',
  latinAmerica: 'Latinamerika och Karibien',
  mena: 'Mellanöstern och Nordafrika',
  northAmerica: 'Nordamerika',
  southAsia: 'Södra Asien',
  subSaharanAfrica: 'Afrika söder om Sahara',
}

export default geographies

import { lighten, tint, adjustHue, desaturate } from 'polished'

export const getLightened = (base, count) =>
  Array(count)
    .fill()
    .map((_, i, { length }) =>
      lighten(i / (Math.max(1, length - 1) * 1.7), base)
    )

export const getTinted = (base, count) =>
  Array(count)
    .fill()
    .map((_, i, { length }) => tint(i / length, base))

export const getHues = (base, count, spread = 360) =>
  Array(count)
    .fill()
    .map((_, i, { length }) =>
      adjustHue((i / length) * spread - spread / 2, lighten(0.1, base))
    )

export const getDesaturated = (base, count) =>
  Array(count)
    .fill()
    .map((_, i, { length }) => desaturate(i / length, base))

import {
  pick,
  pickBy,
  isArray,
  isFunction,
  mapValues,
  defaultsDeep,
} from 'lodash'
import { isNumber, getValue } from 'utils/data'
import { enumerateString } from 'utils/string'
import { getScaleFactor } from 'utils/transform'
import { formatDate } from 'utils/date'
import { CodeError } from 'utils/error'

const transform = meta => (data, env) =>
  pickBy(
    mapValues(meta, (attrMeta, key) =>
      addAttributeMeta(data[key], attrMeta, env)
    ),
    v => v
  )

const sources = {
  roaring: {
    label: 'Roaring',
    description: timestamp =>
      `Sammanställd data från skatteverket, bolagsverket m. fl. Hämtad ${timestamp}`,
  },
  // Backwards compatibility
  lmv_api: {
    label: 'Fastighetsregistret (LMV)',
    description: timestamp => `Direktåtkomst, hämtad ${timestamp}`,
  },
  lmvapi: {
    label: 'Fastighetsregistret (LMV)',
    description: timestamp => `Direktåtkomst, hämtad ${timestamp}`,
  },
  lmvbatch: {
    label: 'Fastighetsregistret (LMV)',
    description: timestamp => `Uttag, hämtat ${timestamp}`,
  },
  infotrader: {
    label: 'Infotrader',
    description: timestamp =>
      `Sammanställd data från Lantmäteriet, Skatteverket m. fl. Hämtad ${timestamp}`,
  },
  bv: {
    label: 'Boverket',
    description: timestamp => `Energideklaration utfärdad ${timestamp}`,
  },
  sgbc: {
    label: 'Swedish Green Building Council',
    description: timestamp => `Hämtad ${timestamp}`,
  },
}

const carriedMetaAttributes = [
  'label',
  'placeholder',
  'type',
  'multiple',
  'unit',
  'sourced',
  'labels',
  'transform',
  'description',
  'commented',
]

const addAttributeMeta = (attr, meta, env) => {
  if (meta === 'primitive') {
    return attr
  }
  if (meta.type === 'subcollection') {
    return {
      items: mapValues(attr?.items ?? {}, item =>
        mapValues(meta.item, (attrMeta, key) =>
          addAttributeMeta(item[key], attrMeta, { ...env, item })
        )
      ),
    }
  }

  const options = isFunction(meta.options) ? meta.options(env) : meta.options
  const visible = isFunction(meta.visible) ? meta.visible(env) : meta.visible
  const validate = isFunction(meta.validate)
    ? value => meta.validate(value, env)
    : undefined

  const value = getValue(attr)

  const displayValue = getDisplayValue({
    value,
    items: attr?.items,
    options,
    env,
    ...pick(meta, ['type', 'unit', 'format', 'labels', 'multiple']),
  })

  return defaultsDeep(
    {
      items:
        meta.type === 'list' ? getItems(attr?.items, meta, env) : undefined,
      value,
      displayValue,
      comment: attr?.comment,
      options,
      visible,
      validate,
      editable: meta.editable !== false,
      timestamp: attr?.timestamp,
      link: isFunction(meta.link) && value ? meta.link(value, env) : meta.link,
      section:
        meta.type === 'section' || meta.type === 'fieldArray'
          ? createSection(value, meta.fields)
          : undefined,
      fieldArray:
        meta.type === 'fieldArray'
          ? createFieldArray(value, meta.fields)
          : undefined,
      source:
        meta.sourced !== false
          ? getSource(
              attr?.sourcedData,
              attr?.value,
              attr?.attachment,
              env?.object?.attachments
            )
          : undefined,
      displaySection:
        (meta.type === 'section' || meta.type === 'fieldArray') && !meta.format
          ? true
          : undefined,
    },
    // attr,
    pick(meta, carriedMetaAttributes)
  )
}

const getSource = (sourcedData, value, attachment, attachments) => {
  if (value !== undefined) {
    return {
      type: 'manual',
      label: 'Manuell inmatning',
      attachment: getAttachment(attachment, attachments),
      reliability: attachment ? 'supported' : 'unsupported',
      originalSource: sourcedData?.value
        ? sources[sourcedData.type]
        : undefined,
    }
  }
  if (!sourcedData) {
    return {}
  }
  if (!sources[sourcedData.type]) {
    throw new CodeError('unknown', `Unknown source ${sourcedData.type}`)
  }
  const { label, description } = sources[sourcedData.type]
  const timestamp = sourcedData.timestamp

  return {
    type: sourcedData.type,
    label,
    timestamp,
    description: isFunction(description)
      ? description(timestamp?.toLocaleDateString() ?? '[okänt datum]')
      : description,
    details: sourcedData?.details,
    reliability: 'verified',
  }
}

const getAttachment = (id, attachments) => {
  if (attachments?.items[id]) {
    const item = attachments?.items[id]
    const value = getValue(item)
    const displayValue = value.name
    return {
      id,
      value,
      displayValue,
    }
  }
}

const defaultBooleanLabels = ['Ja', 'Nej']

const displayValueSelectOptions = (value, { options, multiple }) =>
  isArray(options)
    ? multiple && isArray(value)
      ? enumerateString(
          options.filter(o => value.includes(o.value)).map(o => o.label)
        )
      : options.find(o => o.value === value)?.label ?? undefined
    : undefined

const displayValueSectionFieldArray = value =>
  value && !(isArray(value) && value.length === 0) ? '(Flera värden)' : '(Tom)'

const displayValueGenerators = {
  file: value => (value?.hash ? '(Fil)' : undefined),
  section: displayValueSectionFieldArray,
  fieldArray: displayValueSectionFieldArray,
  options: displayValueSelectOptions,
  select: displayValueSelectOptions,
  boolean: (value, { labels }) =>
    labels ? labels[+!value] : defaultBooleanLabels[+!value],
}

const getDisplayValue = ({
  value,
  items,
  type,
  unit,
  format,
  options,
  labels,
  multiple,
  env,
}) => {
  const displayValue =
    value === undefined || value === ''
      ? undefined
      : type === 'list'
      ? isFunction(format)
        ? format(
            mapValues(items, item => getValue(item)),
            env
          )
        : undefined
      : isFunction(format)
      ? format(value, env)
      : displayValueGenerators[type]
      ? displayValueGenerators[type](value, {
          options,
          labels,
          multiple,
          env,
        })
      : formatValue(value, type, unit)

  return displayValue ? `${displayValue}${unit ? ` ${unit}` : ''}` : undefined
}

const formatValue = (value, type, unit) => {
  if (type?.indexOf('number') === 0) {
    const decimals = type.split('.')[1] ?? 0
    const scaleFactor = getScaleFactor(unit)
    return isNumber(value)
      ? (parseFloat(value) * scaleFactor)
          .toLocaleString('en-US', {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals,
          })
          .replaceAll(',', ' ')
      : value
  } else if (type?.indexOf('percentage') === 0) {
    const decimals = type.split('.')[1] ?? 0
    return formatValue(100 * value, `number.${decimals}`)
  } else if (type === 'date') {
    return value ? formatDate(value) : undefined
  } else {
    return value
  }
}

const getItems = (items, meta, env) =>
  items
    ? mapValues(items, (item, itemId) =>
        addAttributeMeta(
          item,
          {
            ...pick(meta, [
              'transform',
              'options',
              'visible',
              'labels',
              'fields',
              'unit',
              'sourced',
              'link',
            ]),
            format: meta.formatItem,
            type: meta.itemType,
          },
          { itemId, ...env }
        )
      )
    : undefined

const createSection = (data, meta, env) =>
  !meta
    ? data
    : mapValues(meta, (attrMeta, key) =>
        addAttributeMeta({ value: data?.[key] ?? undefined }, attrMeta, env)
      )

const createFieldArray = (data, meta, env) => {
  return !meta
    ? data
    : data?.length > 0
    ? data.map(value =>
        mapValues(meta, (attrMeta, key) =>
          addAttributeMeta({ value: value?.[key] ?? undefined }, attrMeta, env)
        )
      )
    : []
}

export default transform

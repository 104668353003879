import { pickBy } from 'lodash'
import { getAssetLabel } from 'utils/data'
import {
  StaticSections,
  AttachmentsSection,
  StaticGroupSections,
} from 'components/Section'
import CertificationsSection from './CertificationsSection'
import DocumentsSection from './DocumentsSection'
import viewModel from './viewModel'

const AssetDetails = ({
  data,
  qualificationResults,
  editable,
  filePath,
  save,
  addDocument,
}) => {
  const { buildings: buildingsViewModel, ...assetViewModel } = data
    ? viewModel(data, qualificationResults)
    : {}

  const addPlan = () => addDocument('plan', `Plan för ${getAssetLabel(data)}`)
  const addRiskAssessment = () =>
    addDocument('riskAssessment', `Riskanalys för ${getAssetLabel(data)}`)

  return (
    <>
      <StaticSections
        viewModel={assetViewModel}
        data={data}
        save={save}
        editable={editable}
        filePath={filePath}
        qualificationResults={qualificationResults}
      />
      {buildingsViewModel && (
        <StaticGroupSections
          title="Byggnader"
          viewModel={buildingsViewModel}
          data={data}
          save={save}
          editable={editable}
          filePath={filePath}
          qualificationResults={qualificationResults}
        />
      )}
      <CertificationsSection
        key="certifications"
        title="Certifieringar"
        certifications={data.certifications}
        editable={editable}
        missing={qualificationResults.missing.certifications}
        inadequate={qualificationResults.inadequate.certifications}
        attachments={data.attachments?.items}
        save={save}
      />
      <AttachmentsSection
        key="attachments"
        title="Dokument"
        attachments={data.attachments}
        parent={data}
        save={save}
        editable={editable}
        filePath={filePath}
      />
      <DocumentsSection
        key="plans"
        type="plan"
        title="Planer"
        emptyLabel="Inga planer"
        createLabel="Skapa plan"
        qualificationResults={qualificationResults}
        collection={pickBy(data.documents, ({ type }) => type === 'plan')}
        editable={editable}
        add={addPlan}
      />
      <DocumentsSection
        key="riskAssessments"
        type="riskAssessment"
        title="Riskanalys"
        emptyLabel="Ingen riskanalys"
        createLabel="Skapa riskanalys"
        qualificationResults={qualificationResults}
        collection={pickBy(
          data.documents,
          ({ type }) => type === 'riskAssessment'
        )}
        editable={editable}
        add={addRiskAssessment}
      />
    </>
  )
}

export default AssetDetails

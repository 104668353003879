import { useContext } from 'react'
import { pickBy } from 'lodash'
import {
  getLatestSnapshotId,
  toPlural,
  getValue,
  nanoid,
  summarizeDocument,
} from 'utils/data'
import { FieldPath } from 'utils/firebase'
import { AuthContext, AuthProvider } from './Auth'
import { UserContext, UserProvider } from './User'
import { TeamContext, TeamProvider } from './Team'
import { useTeamObject, useTeamObjects } from './utils'
import { useCase } from './Case'
import useSnapshot from './Snapshot'

import { getFirebase } from 'utils/firebase'

export { useCases, sendReport, requestReport, closeCase } from './Case'
export { AuthProvider, UserProvider, TeamProvider }

export const useAuth = () => useContext(AuthContext)
export const useUser = () => useContext(UserContext)
export const useTeam = () => useContext(TeamContext)
export { useCase, useSnapshot }

/******

There are three main classes of objects that hold the sustainability data in a team
1. Organizations
2. Assets
3. Documents

An organization is a legal entity denoting an organization, usually a company. We're normally
only concerned with mother companies of company groups, but theoretically a team could contain
multiple organizations. 

An asset is an economic asset ("a resource with economic value") managed by the team, typically 
belonging to one of the organizations or a subsidiary of one of the organizations in the team.
The behavior and properties of the asset comes down to the asset type (i.e. real estate property, 
vehicle, etc.).  

Currently available asset types: 'property'

A document (for lack of a better word) is any abstract entity that affects the sustainability level
of the team, typically indirectly via assets or organizations. Simple examples are development plans
and risk assessments, but we could imagine other types of assessments, policies and documents that 
contain data that pertain to assets or organizations. 

Common for documents is that they can be linked to one or more organization and/or asset. I.e. a 
development plan could relate to one single piece of real estate property, or to a larger part of 
the portfolio. A risk assessment could relate to one piece of property, or to an entire organization.

Documents are doubly linked: the comment has links to the organizations and assets it affects, and the 
organizations and assets have links to the comments. 

Currently available document type: 'propertyPlan', 'propertyRiskAssessment'

*****/

// Get object from db or from snapshot (if caseId is present)
const useTeamOrCaseObject = ({ type, objectId, caseId }, teamData) => {
  const {
    loading: loadingObject,
    data: objectData,
    error: objectError,
    ...objectAttributes
  } = useTeamObject(objectId, teamData, type, { supressErrors: true })

  const {
    loading: loadingCase,
    data: caseData,
    error: caseError,
  } = useCase(caseId, teamData)

  const snapshotId = getLatestSnapshotId(caseData)
  const reporterId = caseData?.reporter.value

  const {
    loading: loadingSnapshot,
    data: snapshotData,
    error: snapshotError,
  } = useSnapshot(reporterId, snapshotId, caseId)

  const loading =
    loadingObject || (loadingCase && caseId) || (loadingSnapshot && caseId)

  const data = objectData
    ? { data: objectData }
    : getSnapshotData(snapshotData, { type, objectId })

  return {
    loading,
    ...data,
    error: objectError ?? caseError ?? snapshotError,
    reporter: caseData ? getValue(caseData.reporter) : undefined,
    ...(objectData ? objectAttributes : {}),
  }
}

const getSnapshotData = (data, { type, objectId, linkedObjectTypes }) =>
  data
    ? {
        data: data[toPlural(type)][objectId],
        ...(linkedObjectTypes
          ? Object.fromEntries(
              linkedObjectTypes.map(oType => {
                const pType = toPlural(oType)
                const objects = pickBy(
                  data[pType],
                  obj => getValue(obj[type]) === objectId
                )
                return [pType, objects]
              })
            )
          : {}),
      }
    : {}

export const useAsset = ({ assetId, caseId }, teamData) =>
  useTeamOrCaseObject(
    {
      objectId: assetId,
      caseId,
      type: 'asset',
    },
    teamData
  )

export const useDocument = ({ documentId, caseId }, teamData) =>
  useTeamOrCaseObject(
    {
      objectId: documentId,
      caseId,
      type: 'document',
    },
    teamData
  )

// Links is {[id]: {label, class, type?}}
export const addDocument = async ({ type, teamId, title, ...data }, links) => {
  const { db } = getFirebase()
  const documentId = nanoid()
  const batch = db.batch()
  batch.set(db.doc(`/teams/${teamId}/documents/${documentId}`), {
    type,
    title,
    ...data,
    links,
  })
  Object.entries(links ?? {}).forEach(
    ([id, { class: linkedClass, type: linkedType, label }]) => {
      batch.set(
        db.doc(`/teams/${teamId}/${toPlural(linkedClass)}/${id}`),
        {
          documents: {
            [documentId]: summarizeDocument({ type, title, ...data }),
          },
        },
        { merge: true }
      )
    }
  )
  await batch.commit()
}

export const useAssets = teamData =>
  useTeamObjects([FieldPath.documentId(), '!=', 'dontexist'], teamData, 'asset')

export const useDocuments = (ids, teamData) =>
  useTeamObjects(
    ids?.length > 0
      ? [FieldPath.documentId(), 'in', ids]
      : [FieldPath.documentId(), '!=', 'null'],
    teamData,
    'document'
  )

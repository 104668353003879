import { useMemo } from 'react'
import { pickBy } from 'lodash'
import styled from 'styled-components'
import Switch from 'components/Switch'
import Loader from 'components/Loader'
import Debug from 'components/Debug'
import {
  getValue,
  getLatestSnapshotId,
  isCaseOpen,
  getAssetLabel,
} from 'utils/data'
import { useSnapshot } from 'providers/Session'
import { GeneralStatus, SummaryReport, PropertyReport } from 'visualization'
import { qualify } from 'qualifier'
import ActionWrapper from 'components/Section/ActionWrapper'

import {
  exportCaseReportPdf,
  exportCaseReportExcel,
} from 'visualization/export'

import UnstyledExportButton from 'components/ExportButton'
import CloseCaseButton from './CloseCaseButton'

const ExportButton = styled(UnstyledExportButton)`
  margin-left: 1rem;
`

const CreditorView = ({ teamData, case: _case }) => {
  const snapshotId = getLatestSnapshotId(_case)

  const { data: snapshot, loading } = useSnapshot(
    getValue(_case.reporter),
    snapshotId,
    _case.id
  )

  if (snapshot) {
    snapshot.asset = Object.values(snapshot.assets)[0]
  }

  const result = useMemo(() => {
    if (snapshot) {
      const { assets, organizations, plans, riskAssessments } = snapshot
      const requirementMatrices = teamData.teamData.requirements
      const result = qualify(
        { assets, organizations, plans, riskAssessments },
        requirementMatrices
      )
      return result
    } else {
      console.log('No snapshot!')
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snapshot])

  const requested = getValue(_case.status) === 'requested'

  const assetList = Object.entries(snapshot?.assets ?? {})
    .map(([id, asset]) => ({
      id,
      focus: id === _case.data?.asset,
      ...asset,
    }))
    .sort((p1, p2) =>
      p1.focus
        ? -1
        : p2.focus
        ? 1
        : getAssetLabel(p1).localeCompare(getAssetLabel(p2), 'sv')
    )

  const exportActions = {
    pdf: {
      label: 'Ladda ner PDF',
      icon: 'pdf',
      download: data => exportCaseReportPdf('Sciigo case report', data),
    },
    excel: {
      label: 'Ladda ner excel',
      icon: 'excel',
      download: data => exportCaseReportExcel('Sciigo excel report', data),
    },
  }

  const exportData = { ...snapshot, case: _case, qualificationResults: result }

  return loading ? (
    <Loader />
  ) : (
    <>
      {!requested && (
        <Switch
          labels={['Data', 'Kommentarer', 'Diskussion']}
          urls={[
            `/case/${_case.id}`,
            `/case/${_case.id}/review`,
            `/case/${_case.id}/discussion`,
          ]}
          activeIndex={0}
        />
      )}
      <ActionWrapper compact={!requested}>
        <CloseCaseButton
          disabled={!isCaseOpen(_case)}
          case={_case}
          teamId={teamData.id}
        />
        {result && <ExportButton actions={exportActions} data={exportData} />}
      </ActionWrapper>
      <GeneralStatus case={_case} qualificationResults={result} />
      {loading ? (
        <Loader />
      ) : requested || !snapshot ? null : (
        <>
          <SummaryReport
            organizations={snapshot.organizations}
            assets={snapshot.assets}
            documents={snapshot.documents}
            case={_case}
            qualificationResults={result}
          />
          {assetList.map(({ id, ...asset }) => (
            <PropertyReport
              key={id}
              property={{ id, ...asset }}
              plans={pickBy(
                snapshot.documents,
                d => d.type === 'plan' && d.links[id]
              )}
              riskAssessments={pickBy(
                snapshot.documents,
                d => d.type === 'riskAssessment' && d.links[id]
              )}
              case={_case}
              qualificationResults={result}
            />
          ))}
        </>
      )}
      <Debug value={snapshot.assets} />
    </>
  )
}

export default CreditorView

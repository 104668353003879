import styled from 'styled-components'
import { theme, ifProp } from 'styled-tools'
import { Link } from 'react-router-dom'

const OptionsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
`

const OptionsLink = styled(Link)`
  box-sizing: border-box;
  border: 1px solid ${theme('colors.primary')};
  border-left-width: 0;
  text-align: center;
  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-left-width: 1px;
  }
  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  padding: 0.9rem 0;
  width: 10rem;
  text-align: center;
  color: ${ifProp('active', theme('colors.white'), theme('colors.primary'))};
  background-color: ${ifProp(
    'active',
    theme('colors.primary'),
    theme('colors.white')
  )};
  font-weight: 500;
`

const Switch = ({ labels, urls, activeIndex, className, children }) => (
  <OptionsWrapper className={className}>
    {labels.map((label, idx) => (
      <OptionsLink
        key={idx}
        to={urls[idx]}
        active={activeIndex === idx ? 'true' : undefined}
      >
        {label}
      </OptionsLink>
    ))}
  </OptionsWrapper>
)

export default Switch

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import styled from 'styled-components'

import PrivateRoute from './components/PrivateRoute'
import { Page as PageWrapper } from 'components/Layout'
import { useEvents } from 'providers/Events'
import Header from 'components/Header'
import Menu from 'components/Menu'
import { CodeError } from 'utils/error'

import test from './common/test'

import {
  Admin,
  Dashboard,
  CreditorDetails,
  CreditorList,
  Login,
  Forgot,
  Messages,
  AssetDetails,
  AssetList,
  DocumentDetails,
  OrganizationDetails,
  Case,
  CaseList,
  CaseReview,
  CaseDiscussion,
  ReporterDetails,
  ReporterList,
  Completeness,
  Report,
  Debug,
} from './routes'

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  flex-grow: 1;
  overflow: hidden;
`

function App() {
  const { fire } = useEvents()
  test()
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/forgot" component={Forgot} />
      <PrivateRoute path="/admin" component={Admin} />
      <PrivateRoute path="/debug/:itemType/:itemId" component={Debug} />

      <Route path="/">
        <PageWrapper onClick={() => fire('click')}>
          <Header />
          <Wrapper>
            <Menu />
            <Switch>
              <PrivateRoute path="/report" component={Report} />
              <PrivateRoute path="/completeness" component={Completeness} />

              <PrivateRoute path="/asset/list" component={AssetList} />
              <PrivateRoute
                path="/case/:caseId/asset/:assetId"
                component={AssetDetails}
              />
              <PrivateRoute path="/asset/:assetId" component={AssetDetails} />

              <PrivateRoute
                path="/case/:caseId/document/:documentId"
                component={DocumentDetails}
              />
              <PrivateRoute
                path="/document/:documentId"
                component={DocumentDetails}
              />

              <PrivateRoute
                path="/case/:caseId/organization/:orgId"
                component={OrganizationDetails}
              />
              <PrivateRoute
                path="/organization/:orgId"
                component={OrganizationDetails}
              />

              <PrivateRoute path="/reporter/list" component={ReporterList} />
              <PrivateRoute path="/reporter/:id" component={ReporterDetails} />
              <PrivateRoute path="/creditor/list" component={CreditorList} />
              <PrivateRoute path="/creditor/:id" component={CreditorDetails} />

              <PrivateRoute path="/messages/:id?" component={Messages} />
              <PrivateRoute path="/case/list" component={CaseList} />
              <PrivateRoute
                path="/case/:id/discussion"
                component={CaseDiscussion}
              />
              <PrivateRoute path="/case/:id/review" component={CaseReview} />
              <PrivateRoute path="/case/:id" component={Case} />
              <Route
                path="/:misstypedRoute"
                render={() => {
                  throw new CodeError('not-found', 'Route not found')
                }}
              />

              <PrivateRoute path="/" component={Dashboard} />
            </Switch>
          </Wrapper>
        </PageWrapper>
      </Route>
    </Switch>
  )
}

export default App

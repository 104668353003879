import { useMemo } from 'react'
import { useTeam, useCase, useSnapshot } from 'providers/Session'
import config from 'config'
import Loader from 'components/Loader'
import Page from 'components/Page'
import { StaticSections, AttachmentsSection } from 'components/Section'
import { getLatestSnapshotId, getValue } from 'utils/data'
import { CodeError } from 'utils/error'
import { qualifyObject } from 'qualifier'

const viewModel = {
  general: {
    label: 'Organisation och verksamhet',
    keys: [
      'name',
      'orgNr',
      'activityDescription',
      'mainActivity',
      'otherActivities',
      // 'geographies',
      // 'taxRates',
    ],
  },
  sustainabilityWork: {
    label: 'Hållbarhetsarbete',
    keys: ['sustainabilityProcessDescription'],
  },
  minimumSafeguards: {
    label: 'Minimiskyddsåtgärder (Minimum Safeguards)',
    keys: [
      'humanRightsPolicy',
      'dueDiligenceProcess',
      'processToTakeAction',
      'trackingProcess',
      'publicCommunication',
    ],
  },
}

const useOrganization = ({ orgId, caseId }, teamData) => {
  const {
    loading: loadingCase,
    data: caseData,
    error: caseError,
  } = useCase(caseId, teamData)

  const snapshotId = getLatestSnapshotId(caseData)
  const reporterId = caseData?.reporter.value

  const {
    loading: loadingSnapshot,
    data: snapshotData,
    error: snapshotError,
  } = useSnapshot(reporterId, snapshotId, caseId)

  const loading = caseId
    ? loadingCase || loadingSnapshot
    : !teamData.organizations

  const data = loading
    ? undefined
    : (caseId ? snapshotData : teamData)?.organizations[orgId]

  return {
    error: snapshotError ?? caseError,
    loading,
    data,
    reporter: caseData ? getValue(caseData.reporter) : undefined,
  }
}

const OrganizationDetails = ({ match }) => {
  const { caseId, orgId } = match.params
  const { loading: loadingTeam, save: _save, ...teamData } = useTeam()
  const {
    data: orgData,
    reporter,
    loading: loadingOrg,
    error,
  } = useOrganization({ orgId, caseId }, teamData)

  const loading = loadingTeam || loadingOrg

  if (error) {
    throw error
  }
  if (!loading && !orgData) {
    if (caseId) {
      throw new CodeError(
        'not-found',
        `Organization ${orgId} for case ${caseId} not found in snapshot`
      )
    } else {
      throw new CodeError(
        'not-found',
        `Organization ${orgId} not found in team`
      )
    }
  }

  const save = ({ id, ...args }) =>
    _save({
      objectType: 'organization',
      objectId: orgId,
      attrName: id,
      ...args,
    })

  const { missing, inadequate } = useMemo(() => {
    return (
      qualifyObject(orgId, 'organization', orgData, teamData) ?? {
        missing: {},
        inadequate: {},
      }
    )
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(orgData), teamData])

  return (
    <Page title={orgData?.name?.displayValue}>
      {loading ? (
        <Loader />
      ) : orgData ? (
        <>
          <StaticSections
            viewModel={viewModel}
            data={orgData}
            editable={!caseId}
            save={save}
            filePath={reporter}
            qualificationResults={{ missing, inadequate }}
          />
          <AttachmentsSection
            key="attachments"
            title="Dokument"
            attachments={orgData.attachments}
            parent={orgData}
            save={save}
            editable={!caseId}
            filePath={reporter}
          />
        </>
      ) : (
        <div></div>
      )}
      {orgData && config.debug && (
        <>
          <hr />
          <pre>
            {JSON.stringify(
              orgData,
              (key, value) => (key === 'options' ? '' : value),
              2
            )}
          </pre>
        </>
      )}
    </Page>
  )
}

export default OrganizationDetails

import { useContext, useMemo } from 'react'
import { ThemeContext } from 'styled-components'
import { shade, transparentize } from 'polished'
import { maxBy, groupBy, sumBy } from 'lodash'
import { BarChart } from 'components/Chart'
import { getValue } from 'utils/data'
import certificationTypes, {
  unifiedLevels,
  getUnifiedLevel,
} from 'data/certificationTypes'
import { generateLegendLabelFromSeriesConfig } from './chartUtils'
import { getArea } from './utils'

import { SetThree12 as colorScheme } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.brewer'

const CertificationBarChart = ({ assets, areaHeight }) => {
  const theme = useContext(ThemeContext)
  const chartParams = useMemo(
    () => getChartParams(assets, areaHeight, theme),
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [assets]
  )
  return <BarChart {...chartParams} />
}

export default CertificationBarChart

const getChartParams = (assets, areaHeight, theme) => {
  const levels = Object.keys(unifiedLevels)
  const labels = [...Object.values(unifiedLevels).map(({ label }) => label)]
  const types = {
    ...certificationTypes,
    none: { label: 'Ocertifierad' },
  }

  const grouped = groupBy(
    Object.entries(assets).map(([id, prop]) => ({
      _id: id,
      type: 'none',
      level: 'none',
      area: getArea(prop),
      ...getLastCertification(prop),
    })),
    ({ level }) => level
  )

  const lineData = {
    label: 'Yta [m2]',
    type: 'line',
    yAxisID: 'yArea',
    _labelIds: levels,
    borderColor: transparentize(0.5, theme.colors.blue),
    data: levels.map(level => sumBy(grouped[level] ?? [], ({ area }) => area)),
  }

  const backgroundColor = Object.keys(types).map((type, idx) =>
    type === 'none' ? theme.colors.light : colorScheme[idx]
  )

  const barData = Object.entries(types)
    .map(([type, { label }], idx) => ({
      label,
      yAxisID: 'yNumber',
      _id: type,
      stack: 0,
      backgroundColor: backgroundColor[idx],
      borderColor: shade(0.15, backgroundColor[idx]),
      borderWidth: 2,
      data: levels.map(level =>
        grouped[level]
          ? grouped[level].filter(entry => entry.type === type).length
          : 0
      ),
    }))
    .filter(({ data }) => data.some(el => el))

  const datasets = [lineData, ...barData]

  const chartOptions = {
    plugins: {
      legend: {
        position: 'top',
        align: 'end',
        labels: {
          usePointStyle: true,
          generateLabels: generateLegendLabelFromSeriesConfig,
        },
      },
    },
    scales: {
      yNumber: {
        beginAtZero: true,
        suggestedMax: 2,
        position: 'left',
        stacked: true,
        ticks: {
          callback: value => (value % 1 === 0 ? value : undefined),
        },
        afterFit: (axis, ...args) => {
          axis.paddingBottom = axis.height - areaHeight
        },
      },
      yArea: {
        beginAtZero: true,
        suggestedMax: 1000,
        position: 'right',
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  }

  return {
    data: { datasets, labels },
    options: chartOptions,
  }
}

const getLastCertification = prop =>
  maxBy(
    Object.values(prop.certifications.items ?? {}).map(item => {
      const { type, level, dateIssued } = getValue(item)
      return {
        type,
        level: getUnifiedLevel(type, level),
        dateIssued,
      }
    }),
    'dateIssued'
  )

import { useContext, useState, useEffect } from 'react'
import { ThemeContext } from 'styled-components'
import styled from 'styled-components'
import { defaultsDeep } from 'lodash'
import { getHues } from './colors'
import Chart from './Chart'

const StyledChart = styled(Chart)`
  aspect-ratio: 1;
  max-width: 70vh;
  max-height: 70vw;
`

const BubbleChart = ({ options: _options, ...props }) => {
  const [options, setOptions] = useState({})
  const theme = useContext(ThemeContext)

  useEffect(() => {
    const colors = getHues(
      theme.colors.secondary,
      props.data.datasets?.length ?? 0,
      360
    )

    const merged = defaultsDeep({}, _options, {
      color: ({ datasetIndex }) => colors[datasetIndex],
      plugins: {
        tooltip: {
          callbacks: {
            beforeBody: ([ctx]) => ctx?.raw?.subtitle,
            title: ([ctx]) => ctx?.raw?.title ?? ctx?.label,
            label: () => undefined,
          },
        },
      },
      x: {
        position: { y: 0 },
      },
      y: {
        position: { x: 0 },
      },
    })
    setOptions(merged)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_options, props.data])

  return <StyledChart type="bubble" options={options} {...props} />
}

export default BubbleChart

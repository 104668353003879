import { StaticSections, AttachmentsSection } from 'components/Section'
import LinksSection from '../LinksSection'

const viewModel = {
  overview: {
    label: 'Plan',
    keys: ['title', 'summary', 'timespan', 'planDocument', 'totalInvestment'],
  },
  targets: {
    label: 'Mål',
    keys: ['primaryEnergyDemandTarget', 'certificationTarget'],
  },
  // misc: {
  //   label: 'Analyser',
  //   keys: ['climateRiskAnalysis'],
  // },
  // water: {
  //   label: 'Vatten',
  //   keys: [
  //     'maxFlowTaps',
  //     'maxFlowShowers',
  //     'maxFlushWC',
  //     'avgFlushWC',
  //     'maxUseUrinals',
  //     'maxFlushUrinals',
  //   ],
  // },
  // materials: {
  //   label: 'Material och Avfall',
  //   keys: [
  //     'shareRenewableMaterials',
  //     'actionsMinimizeWaste',
  //     'actionsCircularity',
  //     'materialControls',
  //     'materialControlsResidents',
  //     'actionsPollution',
  //   ],
  // },
}

const PlanDetails = ({
  data,
  qualificationResults,
  editable,
  filePath,
  save,
}) => {
  return (
    <>
      <StaticSections
        viewModel={viewModel}
        data={data}
        editable={editable}
        save={save}
        filePath={filePath}
        qualificationResults={qualificationResults}
      />
      <LinksSection
        title="Omfattade fastigheter"
        data={data.links}
        emptyLabel="Inga fastigheter"
      />
      <AttachmentsSection
        key="attachments"
        title="Dokument"
        attachments={data.attachments}
        parent={data}
        save={save}
        editable={editable}
        filePath={filePath}
      />
    </>
  )
}

export default PlanDetails

import { getFirebase, TaskState } from 'utils/firebase'

const arrayBufferToHexString = buffer =>
  Array.from(new Uint8Array(buffer))
    .map(b => b.toString(16).padStart(2, '0'))
    .join('')

const getHash = async blob => {
  const buffer = await blob.arrayBuffer()
  return crypto.subtle.digest('SHA-256', buffer).then(arrayBufferToHexString)
}

export const getSafeFileName = name =>
  name.replace(/[^a-zA-ZåäöÅÄÖ0-9\-_ ]/g, '_')

export const download = async (path, name, type) => {
  const a = document.createElement('a')
  a.href = await getObjectUrl(path)
  a.setAttribute('download', `${getSafeFileName(name)}`)
  a.click()
}

export const upload = async (teamId, blob, metadata, setProgress) => {
  const { storage } = getFirebase()
  const hash = await getHash(blob)
  const ref = storage.ref().child(`${teamId}/${hash}`)
  const uploadTask = ref.put(blob, metadata)

  uploadTask.on('state_changed', snapshot => {
    if (snapshot.state === TaskState.RUNNING) {
      setProgress(snapshot.bytesTransferred / snapshot.totalBytes)
    }
  })

  await uploadTask

  return {
    hash,
    name: blob.name ?? 'Dokument',
    type: blob.type,
  }
}

// TODO: only handles json files for now
export const get = async path => {
  const objectUrl = await getObjectUrl(path)
  const data = await fetch(objectUrl)
  return data.json()
}

const getObjectUrl = async path => {
  const { storage } = getFirebase()
  const ref = storage.ref().child(path)

  const url = await ref.getDownloadURL()
  const response = await fetch(url)
  const blob = await response.blob()
  return URL.createObjectURL(blob)
}

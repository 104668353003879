import transform from '../transform'
import { mapValues, pickBy } from 'lodash'
import { toOptions, getValue } from 'utils/data'
import {
  riskCategories,
  riskDriversByCategory,
  allRiskDrivers,
} from '../../riskCategories'

const meta = {
  type: 'primitive',
  links: 'primitive',

  title: {
    sourced: false,
  },

  property: {
    label: 'Fastighet',
    sourced: false,
    editable: false,
    format: (id, { team }) => getValue(team.properties[id].propertyId),
    link: (value, { caseId }) =>
      caseId ? `/report/${caseId}/property/${value}` : `/property/${value}`,
  },

  status: {
    label: 'Status',
    type: 'options',
    description: `Status Öppen innebär att arbete pågår med att komplettera risk- och åtgärdslista. 
Status Komplett innebär att alla risker som identifierats har listats i analysen, tillsammans med de åtgärder som planeras. 

Vid framtida uppdateringar av analysen sätts status till Öppen igen medan arbetet pågår.`,
    options: toOptions({
      open: 'Öppen',
      complete: 'Komplett',
    }),
  },

  topography: {
    label: 'Topografi',
    type: 'options',
    description:
      'Hur ser terrängen runt byggnaden ut? Ligger den högt/lågt? Terrängen påverkar t.ex. översvämningsrisk.',
    options: toOptions({
      hill: 'Höjd',
      slope: 'Sluttning',
      depression: 'Svacka',
      flat: 'Plan yta',
    }),
  },

  environment: {
    label: 'Omgivande miljö',
    type: 'options',
    description: 'Hur ser miljön runt byggnaden ut?',
    options: toOptions({
      concrete: `Betong eller asfalt`,
      mixed: 'Blandad',
      urbanVegetation: 'Gräsmatta/urban äng',
      mediumVegetation: 'Buskar/ris',
      forest: 'Skog/träd',
    }),
  },

  buildingCharacteristics: {
    label: 'Byggnadsegenskaper',
    description: `Ange om någon av byggnaderna på fastigheten har dessa riskpåverkande egenskaper`,
    type: 'select',
    multiple: 'true',
    options: toOptions({
      flatRoof: 'Platt tak',
      turfRoof: 'Sedumtak/torvtak',
      basement: 'Källarplan',
      basementEntrance: 'Trappbassäng',
    }),
  },

  risks: {
    label: 'Risker',
    type: 'subcollection',
    item: {
      timestamp: 'primitive',
      fromStandardRisk: 'primitive',
      title: {
        label: 'Rubrik',
      },
      category: {
        type: 'section',
        label: 'Typ av risk',
        format: ({ category, driver }) =>
          driver
            ? allRiskDrivers[driver]
            : category
            ? `${riskCategories[category]}`
            : undefined,
        fields: {
          category: {
            type: 'select',
            label: 'Kategori',
            options: toOptions(riskCategories),
          },
          driver: {
            type: 'select',
            label: 'Risktyp',
            options:
              () =>
              ({ category }) =>
                toOptions(riskDriversByCategory[category]),
            visible:
              () =>
              ({ category }) =>
                category,
          },
        },
      },

      description: {
        label: 'Beskrivning',
        type: 'textArea',
      },
      // probability ?
      impact: {
        label: 'Påverkan',
        description:
          'Riskens potentiella finansiella påverkan, angivet i procent av fastighetsvärdet',
        type: 'select',
        options: toOptions({
          tiny: '0-5%',
          low: '5-25%',
          medium: '25-50%',
          high: '50-80%',
          total: '80-100%',
        }),
      },

      action: {
        label: 'Åtgärd',
        description:
          'Åtgärd för att minska riskens sannolikhet och/eller potentiell påverkan på fastighetens värde',
        type: 'select',
        options: ({ object }) =>
          toOptions({
            ...mapValues(object?.actions?.items, a => getValue(a.title)),
            new: 'Ny Åtgärd',
          }),
      },
      residualImpact: {
        label: 'Återstående påverkan',
        description:
          'Återstående potentiell påverkan på fastighetens värde efter att åtgärden har genomförts',
        type: 'select',
        options: toOptions({
          zero: '0%',
          tiny: '0-5%',
          low: '5-25%',
          medium: '25-50%',
          high: '50-80%',
          total: '80-100%',
        }),
      },
    },
  }, // risks
  actions: {
    label: 'Åtgärder',
    type: 'subcollection',
    item: {
      timestamp: 'primitive',
      title: {
        label: 'Rubrik',
      },
      timescale: {
        label: 'Varighet',
        description:
          'Är åtgärden en engångsinsats/projekt som avslutas, eller handlar det om att införa ett kontinuerligt arbete?',
        type: 'options',
        options: toOptions({
          continuous: 'Kontinuerlig insats',
          oneOff: 'Engångsinsats',
        }),
      },
      description: {
        label: 'Beskrivning',
        type: 'textArea',
      },
      plan: {
        label: 'Associerad plan',
        type: 'select',
        description:
          'Är åtgärden associerad med en befintlig utvecklingsplan på fastigheten?',
        options: ({ team, object }) =>
          // All plans with the same associated property as this risk assessment
          toOptions(
            mapValues(
              pickBy(
                team.plans,
                plan => getValue(plan.property) === getValue(object.property)
              ),
              plan => getValue(plan.title)
            )
          ),

        link: (value, { caseId }) =>
          caseId ? `/report/${caseId}/plan/${value}` : `/plan/${value}`,
      },
    },
  },
  attachments: {
    label: 'Dokument',
    type: 'list',
    itemType: 'section',
    formatItem: ({ name }) => name,
    fields: {
      name: {
        label: 'Namn',
      },
      description: {
        label: 'Beskrivning',
        type: 'textArea',
      },
    },
    sourced: false,
  },
}

export default transform(meta)

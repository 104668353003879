import styled from 'styled-components'
import { theme } from 'styled-tools'

const ErrorMsg = styled.div`
  color: ${theme('colors.error')};
  padding: 0.3rem;
`

const Errors = ({ errors, format }) =>
  Object.entries(errors ?? {}).map(([id, error]) => (
    <ErrorMsg key={id}>{format?.(error) ?? error.message ?? error}</ErrorMsg>
  ))

export default Errors

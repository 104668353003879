import styled, { css } from 'styled-components'
import { theme, ifProp, ifNotProp } from 'styled-tools'
import { formatDate } from 'utils/date'

const MessageWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  opacity: ${ifProp('$outdated', 0.6, 1)};
  ${ifNotProp(
    '$self',
    css`
      border-left: 3px solid ${theme('colors.primaryLight')};
      padding-left: 1rem;
    `
  )}
`

const Title = styled.p`
  font-weight: 500;
`

const Sender = styled.p`
  font-size: 0.8rem;
  color: ${theme('colors.medium')};
  margin: 0;
`

const OutdatedLabel = styled.div`
  position: absolute;
  font-size: 0.6rem;
  padding: 0.1rem 0.3rem;
  background-color: ${theme('colors.warning')};
  color: ${theme('colors.white')};
  top: 0;
  right: 0;
  text-transform: uppercase;
  border-radius: 2px;
`

const Message = ({
  id,
  subject,
  message,
  sender,
  read,
  timestamp,
  self,
  data,
  outdated,
}) => (
  <MessageWrapper $self={self} $outdated={outdated}>
    {outdated && <OutdatedLabel>Inaktuell</OutdatedLabel>}
    <Sender>
      {sender.orgName} – {timestamp ? formatDate(timestamp) : 'Nu'}
    </Sender>
    {data.type !== 'comment' && <Title>{subject}</Title>}
    <p>{message}</p>
  </MessageWrapper>
)

export default Message

import { customAlphabet } from 'nanoid'
import {
  has,
  get,
  extendWith,
  isArray,
  maxBy,
  union,
  mean,
  isPlainObject,
} from 'lodash'

// Avoid the risk of leadingn underscore in ids
const alphabet =
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-'
export const nanoid = customAlphabet(alphabet, 21)

export const addReducer = (a, b) => a + b

const pluralMap = {
  asset: 'assets',
  document: 'documents',
  organization: 'organizations',
  team: 'teams',
  property: 'properties',
  user: 'users',
  account: 'accounts',
  request: 'requests',
  case: 'cases',
  reporter: 'reporters',
  creditor: 'creditors',

  // Remove?
  riskAssessment: 'riskAssessments',
  plan: 'plans',
}

export const toSingular = plural =>
  Object.entries(pluralMap).find(([s, p]) => p === plural)?.[0] ?? plural

export const toPlural = singular => pluralMap[singular]

// Also admits strings with numbers, e.g. '1'
//eslint-disable-next-line eqeqeq
export const isNumber = n => parseFloat(n) == n

export const defaultSort = (a, b) =>
  a.name
    ? a.name.localeCompare(b.name, 'sv')
    : a.title
    ? a.title.localeCompare(b.title, 'sv')
    : a.label
    ? a.label.localeCompare(b.label, 'sv')
    : 0

export const sortByDate = (key, desc) => (a, b) =>
  (desc ? -1 : 1) *
  (key ? _sortByDate(get(a, key), get(b, key)) : _sortByDate(a, b))

const _sortByDate = (a, b) =>
  a && b
    ? (a.getTime?.() ?? a.toMillis?.()) - (b.getTime?.() ?? b.toMillis?.())
    : a
    ? -1
    : b
    ? 1
    : 0

export const toList = (obj, sort) =>
  Object.entries(obj ?? {})
    .map(([id, value]) => ({ id, ...value }))
    .sort(sort ?? defaultSort)

export const toOptions = obj =>
  Object.entries(obj ?? {}).map(([value, label]) => ({
    value,
    label: label.label ?? label.title ?? label.name ?? label,
  }))

// Merges objects, and if both objects have the same property, they are put in an array
export const addObjects = (obj1, obj2) =>
  extendWith(obj1, obj2, (v1, v2) => [
    ...(isArray(v1) ? v1 : v1 != null ? [v1] : []), //eslint-disable-line eqeqeq
    ...(isArray(v2) ? v2 : v2 != null ? [v2] : []), //eslint-disable-line eqeqeq
  ])

// Create an object with the given keys and the (optional) value
export const objectWithKeys = (keys = [], value) =>
  Object.fromEntries(keys.map(key => [key, value]))

export const minToMax = (items, sort) => {
  const sorted = items.sort(sort)
  return sorted[0] === sorted[sorted.length - 1]
    ? sorted[0]
    : `${sorted[0]} - ${sorted[sorted.length - 1]}`
}

export const histogram = (data, buckets, bucketFn) => {
  const freq = Object.values(data)
    .map(bucketFn)
    .reduce((acc, cur) => {
      const bucket = has(cur, 'bucket') ? cur.bucket : cur
      const value = has(cur, 'value') ? cur.value : 1

      return {
        ...acc,
        [bucket]: acc[bucket] ? acc[bucket] + value : value,
      }
    }, {})
  return buckets.map(label => freq[label] ?? 0)
}

export const clamp = (number, lower, upper) =>
  Math.max(Math.min(number, upper), lower)

export const getBounds = (data, props = ['x', 'y']) =>
  Object.assign(
    {},
    ...props.map(prop => {
      const T = data.map(e => e[prop])
      const t1 = Math.max(...T)
      const t0 = Math.min(...T)
      return {
        [`${prop}0`]: t0,
        [`${prop}1`]: t1,
        [`${prop}r`]: t1 - t0,
      }
    })
  )

export const propwiseAdd = (o1, o2, props) =>
  Object.fromEntries(
    (props ?? union(Object.keys(o1), Object.keys(o2))).map(prop => [
      prop,
      (o1[prop] ?? 0) + (o2[prop] ?? 0),
    ])
  )

// Works with array or object
export const propAverage = (collection, paths) =>
  mean(
    Object.values(collection)
      .map(obj =>
        isArray(paths)
          ? mean(
              paths
                .map(path => parseFloat(getValue(get(obj, path))))
                .filter(v => isNumber(v))
            )
          : parseFloat(getValue(get(obj, paths)))
      )
      .filter(v => isNumber(v))
  )

export const mergeAttributes = collection => {
  return Object.values(collection).reduce((acc, cur) => {
    const attrs = union(Object.keys(acc), Object.keys(cur))
    return Object.fromEntries(
      attrs.map(attr => [
        attr,
        [
          ...(acc[attr] ?? []),
          ...(isArray(cur[attr])
            ? cur[attr]
            : cur[attr] !== undefined
            ? [cur[attr]]
            : []),
        ],
      ])
    )
  }, {})
}

// Percentage of values in the collection that have a defined value at path
export const shareWithDefined = (collection, path) =>
  Object.values(collection)
    .map(object => getValue(get(object, path)))
    .filter(v => v || v === 0 || v === false).length /
  Object.keys(collection).length

const isEmptyObject = o => isPlainObject(o) && Object.keys(o).length === 0

export const getValue = attr =>
  attr?.value !== undefined
    ? attr.value
    : attr?.sourcedData?.value !== undefined
    ? attr.sourcedData.value
    : has(attr, 'value')
    ? attr.value
    : isEmptyObject(attr)
    ? undefined
    : attr

const assetLabelFns = {
  property: ({ propertyId }) => propertyId.displayValue ?? propertyId,
}

export const getAssetLabel = assetData =>
  assetData
    ? assetData.type
      ? assetLabelFns[assetData.type]
        ? assetLabelFns[assetData.type](assetData)
        : `Okänd objektstyp '${assetData.type}'`
      : 'Objekt utan typ'
    : undefined

const documentLabelFns = {
  plan: ({ title }) => title.displayValue ?? title,
  riskAssessment: ({ title }) => title.displayValue ?? title,
}

export const getDocumentLabel = documentData =>
  documentData
    ? documentData.type
      ? documentLabelFns[documentData.type]
        ? documentLabelFns[documentData.type](documentData)
        : `Okänd objektstyp '${documentData.type}'`
      : 'Objekt utan typ'
    : undefined

export const getOrgName = team =>
  team.teamData.name ??
  getValue(Object.values(team.organizations ?? {})[0]?.name)

export const getOrgNr = team =>
  team.teamData.orgNr ??
  getValue(Object.values(team.organizations ?? {})[0]?.orgNr)

export const isCaseOpen = _case =>
  !['closed', 'canceled'].includes(getValue(_case.status))

export const getLatestSnapshotId = _case =>
  maxBy(Object.entries(_case?.snapshots ?? {}), '1')?.[0]

// Summarize document for team cache
// Make more sophisticated depending on document type as needed
export const summarizeDocument = data => ({
  label: getDocumentLabel(data),
  type: data.type,
})

// Summarize property for document link cache
// Make more sophisticated depending on asset type as needed
export const summarizeAsset = data => ({
  label: getAssetLabel(data),
  type: data.type,
})

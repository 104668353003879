import styled from 'styled-components'
import { theme } from 'styled-tools'

const EnergyClassValue = styled.div`
  font-size: 3rem;
  font-weight: 600;
  width: 5rem;
  height: 5rem;
  line-height: 5rem;
  text-align: center;
  padding: 0;
  background-color: ${({ value }) =>
    value ? theme(`colors.energyClass.${value}`) : theme('colors.light')};
  border-radius: 5px;
`

const EnergyClass = ({ className, value }) => (
  <EnergyClassValue
    className={className}
    value={value}
    title={value ? `Energiklass ${value}` : 'Energiklass saknas'}
  >
    {value ?? '?'}
  </EnergyClassValue>
)

export default styled(EnergyClass)``

import styled from 'styled-components'
import { ifProp } from 'styled-tools'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: right;
`

const InnerWrapper = styled.div`
  position: ${ifProp('$compact', 'absolute', 'relative')};
  bottom: 0rem;
  right: 0;
  margin: 0;
  padding: 0;
  display: flex;
`

const ActionWrapper = ({ compact, children }) => (
  <Wrapper>
    <InnerWrapper $compact={compact}>{children}</InnerWrapper>
  </Wrapper>
)

export default ActionWrapper

const config = {
  firebase: {
    apiKey: 'AIzaSyAJyCmHfwWvF17AEvw2H8qfGRqN4pZrL4Y',
    authDomain: 'sciigo-dev.firebaseapp.com',
    projectId: 'sciigo-dev',
    storageBucket: 'sciigo-dev.appspot.com',
    documentBucket: 'sciigo-dev-documents',
    messagingSenderId: '974510228297',
    appId: '1:974510228297:web:180927d5121a34a5165098',
  },
  functions: {
    origin: 'https://europe-west1-sciigo-dev.cloudfunctions.net',
    region: 'europe-west1',
  },
  sentry: {
    dsn: 'https://2ecf1383c21e46c8843724a91b461399@o1281565.ingest.sentry.io/6487587',
    tracesSampleRate: 1.0,
    release: '34513727d9d640fb0ba51b216a5d680c9f044bed',
  },
  mixpanel: {
    token: '95379848f5c54aa5014eb1f6eecef9a0',
  },
  streamValidation: true,
  debug: false,
  tracking: false,
}

export default config

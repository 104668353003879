import { Formik, Form } from 'formik'
import FormField from 'components/form/FormField'
import { toOptions, getValue } from 'utils/data'
import { sendReport } from 'providers/Session'
import FormPopup, { ButtonRow } from 'components/Popup/FormPopup'
import { Errors } from 'components/form'
import { wrapSubmit } from 'utils/form'

const SendButton = ({ case: _case, teamId, className, ...props }) => (
  <FormPopup
    icon="send"
    label="Skicka"
    dialogWidth="20rem"
    buttonStyle="normal"
    className={className}
    {...props}
  >
    <SendForm case={_case} teamId={teamId} />
  </FormPopup>
)

const SendForm = ({ teamId, case: _case, close }) => {
  const submit = ({ type, verified }) =>
    sendReport({
      teamId,
      type,
      verified,
      caseId: _case.id,
      creditor: getValue(_case.creditor),
    })

  return (
    <Formik
      initialValues={{ type: 'draft', verified: false }}
      onSubmit={wrapSubmit(submit, close, { timeout: 1000 })}
    >
      {({ isSubmitting, values, errors, status, setValues }) => (
        <Form>
          <h2>Skicka rapport</h2>
          <FormField
            name="type"
            label="Typ:"
            type="select"
            options={toOptions({ draft: 'Utkast', final: 'Slutgiltig' })}
          />
          {values.type === 'final' && (
            <FormField
              name="verified"
              label="Jag intygar att alla uppgifter är korrekta"
              type="checkbox"
            />
          )}
          <Errors errors={errors} />
          <ButtonRow
            disabled={
              isSubmitting || (values.type === 'final' && !values.verified)
            }
            loading={isSubmitting}
            title="Skicka"
            done={status?.done}
            successMessage="Rapporten skickades"
          />
        </Form>
      )}
    </Formik>
  )
}

export default SendButton

import { isArray, groupBy, uniq, mapValues } from 'lodash'

const summarizeAddresses = addresses => {
  if (!addresses?.length) {
    return
  }
  if (!isArray(addresses)) {
    return addresses
  }

  const streets = groupBy(addresses, a => a.street)
  const streetAddresses = Object.entries(streets)
    .sort((a, b) => a[0].localeCompare(b[0]))
    .map(([street, addresses]) => {
      const numbers = uniq(addresses.map(a => a.number))
      return `${street} ${summarizeStreetNumbers(numbers)}`
    })
    .join(', ')

  return streetAddresses
}

const summarizeStreetNumbers = numbers => {
  const pureNumbers = numbers.filter(a => `${parseInt(a)}` === a)
  const alphaNumbers = numbers
    .map(a => ({
      number: parseInt(a),
      letter: a.slice(`${parseInt(a)}`.length),
    }))
    .filter(a => a.letter)

  return [
    ...(pureNumbers.length
      ? pureNumbers
          .sort((a, b) => parseFloat(a) - parseFloat(b))
          .reduce((acc, cur) => {
            if (!acc) {
              return [[cur]]
            }

            const last = Math.max(...acc[acc.length - 1])
            if (cur - last <= 2) {
              acc[acc.length - 1].push(cur)
            } else {
              acc.push([cur])
            }
            return acc
          }, 0)
          .map(list =>
            list.length === 1 ? list[0] : `${list[0]}-${list[list.length - 1]}`
          )
      : []),

    ...(alphaNumbers.length
      ? Object.entries(
          mapValues(groupBy(alphaNumbers, 'number'), ans =>
            ans.map(an => an.letter)
          )
        ).map(
          ([number, letters]) => `${number}${groupLetters(letters).join(', ')}`
        )
      : []),
  ]
    .sort((a, b) => a.localeCompare(b, 'sv'))
    .join(', ')
}

const groupLetters = alphas =>
  alphas
    .sort((a, b) => a.localeCompare(b, 'sv'))
    .reduce((acc, cur) => {
      if (!acc) {
        return [cur]
      }
      const lastSeq = acc.pop()
      const lastChar = lastSeq.split('').pop()
      if (charDiff(lastChar, cur) === 1) {
        return [...acc, `${lastSeq.split('-')[0]}-${cur}`]
      } else {
        return [...acc, lastSeq, cur]
      }
    }, 0)

// TODO fix umlauts
const charDiff = (s1, s2) => s2.charCodeAt(0) - s1.charCodeAt(0)

export default summarizeAddresses

import { mapValues } from 'lodash'

// const timescales = {
//   cronic: 'Kronisk',
//   acute: 'Akut',
// }

export const riskCategories = {
  temperature: 'Temperatur',
  wind: 'Vind',
  water: 'Vatten',
  solidMass: 'Jord/Snö',
}

export const riskDrivers = {
  solidMass: {
    acute: {
      subsidence: 'Sättning',
      landslide: 'Jordskred',
      avalance: 'Lavin',
    },
    cronic: {
      solifluction: 'Jordflytning',
      soilErosion: 'Jorderosion',
      soilDegradation: 'Markförsämring',
      coastalErosion: 'Kusterosion',
    },
  },
  temperature: {
    acute: {
      wildfire: 'Skogsbrand',
      heatWave: 'Värmebölja',
      coldWave: 'Köldknäpp',
    },
    cronic: {
      temperatureVariability: 'Temperaturvariation',
      permafrostThawing: 'Tinande permafrost',
      heatStress: 'Värmestress',
      changingTemperature: 'Ändrade temperaturer',
    },
  },
  water: {
    acute: {
      heavyPrecipitation: 'Skyfall (regn, hagel, snö)',
      glacialLakeOutburst: 'Glaciärsjöutbrott/jökellop (GLOF)',
      flood: 'Översvämning',
      drought: 'Torka',
    },
    cronic: {
      waterStress: 'Vattenstress',
      seaLevelRise: 'Höjda havsnivåer',
      salineIntrusion: 'Saltvatteninträngning',
      precipitation: 'Nederbörd/variation i hydrologi',
      oceanAcidification: 'Försurning i hav',
      changingPrecipitationPatterns: 'Ändrade nederbördsmönster',
    },
  },
  wind: {
    acute: {
      tornado: 'Tornado',
      storm: 'Storm (inkl snöstorm, sandstorm)',
      cyclone: 'Orkan',
    },
    cronic: {
      changingWindPatterns: 'Förändrade vindmönster',
    },
  },
}

export const riskDriversByCategory = mapValues(riskDrivers, category =>
  Object.assign(...Object.values(category))
)

export const allRiskDrivers = Object.fromEntries(
  Object.values(riskDrivers).flatMap(category =>
    Object.values(category).flatMap(timescale => Object.entries(timescale))
  )
)

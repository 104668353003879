import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { useUser } from 'providers/Session'
import { download } from 'utils/storage'
import Popup from 'components/Popup'
import { NakedButton } from 'components/Button'
import Markdown from 'components/Markdown'

const SourceDetails = styled.div`
  h2 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  p,
  li {
    font-size: 1rem;
    margin-bottom: 0.3rem;
  }
  ul {
    list-style: disc;
    padding-left: 1rem;
    li {
      line-height: normal;
    }
  }
`

const StyledPopup = styled(Popup)`
  > button {
    color: ${theme('colors.medium')};
  }
`

const SupportingAttachment = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  div {
    margin-right: 0.5rem;
  }
`

const DownloadButton = styled(NakedButton)`
  font-size: 1rem;
  display: flex;
  flex-shrink: 1;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  div {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 0.5rem;
  }
  span,
  svg {
    flex-grow: 0;
    flex-shrink: 0;
  }
`

const icons = {
  verified: 'verified',
  supported: 'attachment',
  unsupported: 'user',
}

const Source = ({ className, source, filePath }) => {
  const { team } = useUser()

  const downloadAttachment = ({ hash, name, type }) => {
    download(`${filePath ?? team}/${hash}`, name, type)
  }

  return (
    <StyledPopup
      icon={icons[source.reliability]}
      reliability={source.reliability}
      dialogWidth="20rem"
    >
      <SourceDetails>
        <h2>{source.label}</h2>
        <p>{source.description ?? ''}</p>
        {source.details && <Markdown>{source.details.description}</Markdown>}

        {source.attachment?.id && (
          <SupportingAttachment>
            <div>Stöd:</div>
            <DownloadButton
              icon="download"
              onClick={e => downloadAttachment(source.attachment.value)}
            >
              <div>{source.attachment.displayValue}</div>
            </DownloadButton>
          </SupportingAttachment>
        )}
      </SourceDetails>
    </StyledPopup>
  )
}

export default Source

import { useTeam, useCase } from 'providers/Session'
import { useRecent } from 'providers/Recent'

import Loader from 'components/Loader'
import Page from 'components/Page'
import CreditorView from './CreditorView'
import ReporterView from './ReporterView'

const Case = ({ match }) => {
  const { id: caseId } = match.params
  const { loading: loadingTeam, ...teamData } = useTeam()
  const { data: _case, loading: loadingCase } = useCase(caseId, teamData)
  const loading = loadingTeam || loadingCase

  useRecent('case', caseId, _case?.title.displayValue)

  return (
    <Page title={_case?.title.displayValue}>
      {loading ? (
        <Loader />
      ) : teamData.teamData.type === 'creditor' ? (
        <CreditorView teamData={teamData} case={{ id: caseId, ..._case }} />
      ) : (
        <ReporterView teamData={teamData} case={{ id: caseId, ..._case }} />
      )}
    </Page>
  )
}

export default Case

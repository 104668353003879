import { createElement } from 'react'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { Link } from 'react-router-dom'
import Markdown, { InlineMarkdown } from 'components/Markdown'
import { NakedButton } from 'components/Button'
import { isNumber } from 'utils/data'

const FileValue = ({ value, type, format, download }) => (
  <ValueWrapper type={type}>
    {value?.hash && (
      <NakedButton
        icon="attachment"
        title="Ladda ned dokument"
        onClick={() => download(value)}
      />
    )}
  </ValueWrapper>
)

const MarkdownValue = ({ displayValue }) => <Markdown>{displayValue}</Markdown>

const valueComponents = {
  file: FileValue,
  markdown: MarkdownValue,
}

export const ValueWrapper = styled.div`
  white-space: pre-wrap;
  flex-grow: 1;
`

const guessType = value =>
  isNumber(value) ? 'number' : typeof value === 'boolean' ? 'boolean' : 'string'

const ValuesWrapper = styled.div`
  display: block;
  flex-grow: 1;
  ${ValueWrapper}+${ValueWrapper} {
    margin-top: 0.5rem;
    margin-left: 0;
  }
  opacity: ${ifProp('empty', 0.4, 1)};
`

const Value = ({
  value,
  displayValue,
  type,
  link,
  comment,
  component,
  interactive = true,
  className,
}) =>
  displayValue === undefined ? (
    <ValuesWrapper empty className={className} title="Värde saknas">
      —
    </ValuesWrapper>
  ) : Array.isArray(displayValue) ? (
    <ValuesWrapper className={className}>
      {displayValue.map((v, i) => (
        <Value key={i} {...v} />
      ))}
    </ValuesWrapper>
  ) : component ? (
    createElement(component, {
      value,
      displayValue,
      type,
      link,
      className,
    })
  ) : valueComponents[type] && interactive ? (
    createElement(valueComponents[type], {
      value,
      displayValue,
      type,
      link,
      className,
    })
  ) : (
    <ValueWrapper
      type={type ?? guessType(value)}
      title={comment ? `Kommentar: ${comment}` : undefined}
      className={className}
    >
      {link ? (
        <Link to={link}>
          <InlineMarkdown>{displayValue}</InlineMarkdown>
        </Link>
      ) : (
        <InlineMarkdown>{displayValue}</InlineMarkdown>
      )}
    </ValueWrapper>
  )

export default styled(Value)``

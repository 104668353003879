import { useTeamObject, useTeamObjects } from './utils'

import { backendCall } from 'utils/firebase'

export const useCases = teamData =>
  useTeamObjects(['status.value', '!=', 'undefined'], teamData, 'case')

export const useCase = (caseId, teamData) =>
  useTeamObject(caseId, teamData, 'case')

export const sendReport = async ({
  teamId,
  creditor,
  caseId,
  type,
  verified,
}) =>
  backendCall(
    'sendReport',
    {
      reporter: teamId,
      creditor,
      caseId,
      type,
      verified,
    },
    null,
    { batch: true }
  )

export const requestReport = async ({
  teamId,
  reporterId,
  caseId,
  data,
  type,
}) =>
  backendCall('requestReport', {
    creditor: teamId,
    reporter: reporterId,
    caseId,
    data,
    type,
  })

export const closeCase = async ({ teamId, caseId, result, note }) =>
  backendCall('closeCase', {
    creditor: teamId,
    caseId,
    result,
    note,
  })

import { useState } from 'react'
import styled from 'styled-components'
import { theme, ifProp } from 'styled-tools'
import { addPropsToChildren } from 'utils/react'
import { Link } from 'react-router-dom'

const StyledOl = styled.ol`
  padding: 0;
  margin: 0;
  li {
    margin: 0;
    list-style: none;
    display: flex;
    text-align: left;
    align-items: center;
    border-color: ${theme('colors.borderLight')};
    border-style: solid;
    border-width: 0;
    border-bottom-width: 1px;
    padding: 0.6rem 1.2rem;
    justify-content: space-between;
    &:last-child {
      border-bottom-width: 0;
    }
  }
`

const InputWrapper = styled.div`
  padding: 1.2rem;
  border-bottom: 1px solid ${theme('colors.primaryLight')};
`
const StyledInput = styled.input`
  width: 100%;
`

const filterData = (data, filter, filterStr) =>
  !data
    ? []
    : filter && filterStr.length > 1
    ? data.filter(filter(filterStr))
    : data

const sortData = (data, sort) => (!data ? [] : sort ? data.sort(sort) : data)

const limitData = (data, limit) =>
  !data ? [] : limit ? data.slice(0, limit) : data

const getData = (data, { filter, filterStr, sort, limit }) =>
  limitData(sortData(filterData(data, filter, filterStr), sort), limit)

const ListWrapper = styled.div``

const List = ({
  data,
  onEdit,
  onDelete,
  filter,
  sort,
  limit,
  emptyLabel,
  className,
  children,
}) => {
  const [filterStr, setFilterStr] = useState('')

  const finalData = getData(data, { filter, filterStr, sort, limit })

  return (
    <ListWrapper className={className}>
      {filter && (
        <InputWrapper>
          <StyledInput
            value={filterStr}
            placeholder={'Filtrera'}
            onChange={e => setFilterStr(e.target.value)}
          />
        </InputWrapper>
      )}
      <StyledOl $filter={filter}>
        {finalData.length ? (
          finalData.map(item => (
            <li key={item.id}>{addPropsToChildren(children, item)}</li>
          ))
        ) : emptyLabel ? (
          <li key="empty">
            <EmptyLabel>{emptyLabel}</EmptyLabel>
          </li>
        ) : (
          ''
        )}
      </StyledOl>
    </ListWrapper>
  )
}

export default List

// List route styles. Might be better off somewhere else, but putting them here for now

export const ListRouteWrapper = styled.div``

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1.2rem;
  h1 {
    flex-grow: 1;
    margin-bottom: 0;
  }
  a {
    flex-grow: 0;
    margin: 0;
  }
`

export const StyledLink = styled(Link)`
  flex-grow: 1;
  overflow: hidden;
  color: black;
  &:hover {
    text-decoration: none;
  }
`

const NotALink = styled.div`
  flex-grow: 1;
  overflow: hidden;
  color: ${ifProp(
    '$disabled',
    theme('colors.mediumLight'),
    theme('colors.primaryText')
  )};
  &:hover {
    text-decoration: none;
  }
`

export const Title = styled.h2`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
`

export const Subtitle = styled.p`
  color: ${theme('colors.medium')};
  margin: 0;
  margin-top: 0.2rem;
`

export const EmptyLabel = styled.p`
  width: 100%;
  margin: 0;
`

export const Source = ({ id, title, subtitle, url, disabled }) =>
  url && !disabled ? (
    <StyledLink to={url}>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </StyledLink>
  ) : (
    <NotALink $disabled={disabled}>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </NotALink>
  )

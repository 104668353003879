import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Tooltip from 'components/Tooltip'
import BaseSection, {
  Empty,
  RowWrapper,
  getTooltipText,
} from 'components/Section/BaseSection'

const Title = styled.div`
  flex-shrink: 1;
  flex-grow: 0;
  margin-right: 1rem;
  font-weight: 500;
`

const Row = ({ title, missing, inadequate, link, ...args }) => {
  const tooltip = getTooltipText({ label: title, missing, inadequate })
  return (
    <RowWrapper missing={missing} inadequate={inadequate}>
      {link ? (
        <Title>
          <Link to={link}>{title}</Link>
          {tooltip && <Tooltip contents={tooltip} />}
        </Title>
      ) : (
        <Title>
          {title}
          {tooltip && <Tooltip contents={tooltip} />}
        </Title>
      )}
    </RowWrapper>
  )
}

const LinksSection = ({ title, data, emptyLabel }) => {
  const items = Object.entries(data ?? {}).map(
    ([id, { class: klass, label, type }]) => ({
      id,
      title: label,
      link: `/${klass}/${id}`,
      type,
    })
  )

  return (
    <BaseSection title={title}>
      {items.length > 0 ? (
        items.map((item, idx) => <Row key={idx} {...item} />)
      ) : (
        <Empty>{`(${emptyLabel})`}</Empty>
      )}
    </BaseSection>
  )
}

export default LinksSection

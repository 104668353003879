import styled from 'styled-components'
import { useMessages } from 'providers/Messaging'
import { Formik, Form } from 'formik'
import { FormField } from 'components/form'

import { getLatestSnapshotId } from 'utils/data'
import Button from 'components/Button'

const Wrapper = styled(Form)`
  padding: 1.2rem;
`

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    margin: 0rem;
    &:last-child {
      margin-left: 1rem;
    }
  }
`

const NewMessageForm = ({ caseId, case: _case, teamData, close }) => {
  const { sendMessage } = useMessages()
  const snapshotId = getLatestSnapshotId(_case)

  return (
    <Formik
      initialValues={{ comment: '', title: '' }}
      onSubmit={async ({ comment }, { setSubmitting, resetForm }) => {
        const recipient =
          !_case.reporter.value || _case.reporter.value === teamData.id
            ? _case.creditor.value
            : _case.reporter.value

        await sendMessage(
          recipient,
          `Nytt meddelande i ärende '${_case.title.displayValue}'`,
          comment,
          {
            type: 'comment',
            caseId: caseId,
            snapshot: snapshotId,
          }
        )
        resetForm()
        setSubmitting(false)
        close()
      }}
    >
      {({ values, isSubmitting }) => (
        <Wrapper>
          <FormField
            name="comment"
            type="textArea"
            placeholder="Kommentar"
            autoFocus={true}
            tabIndex={1}
          />
          <ButtonRow>
            <Button
              onClick={close}
              disabled={isSubmitting}
              type="button"
              tabIndex={3}
              inverted={true}
            >
              Avbryt
            </Button>
            <Button
              disabled={isSubmitting || !values.comment}
              type="submit"
              tabIndex={2}
            >
              Skicka
            </Button>
          </ButtonRow>
        </Wrapper>
      )}
    </Formik>
  )
}

export default NewMessageForm

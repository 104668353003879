import writeExcelFile from 'write-excel-file'
import {
  getBuildingTypes,
  getAverageEnergyClass,
  getCertifications,
} from './utils'

export const exportCaseReportPdf = async (
  title,
  { organizations, assets, documents, case: _case, qualificationResults }
) => {
  const { download: downloadPdf } = await import('components/Pdf')
  const { default: CaseReport } = await import('./CaseReportPdf')

  await downloadPdf(
    <CaseReport
      organizations={organizations}
      assets={assets}
      documents={documents}
      case={_case}
      qualificationResults={qualificationResults}
      title={title}
    />,
    title
  )
}

export const exportCaseReportExcel = async (title, { assets }) => {
  const headerRow = [
    'Fastighetsbeteckning',
    'Typ',
    'Energiklass',
    'Certifiering',
  ].map(label => ({ value: label }))

  const assetRows = Object.values(assets).map(prop => [
    { value: prop.propertyId.displayValue },
    { value: getBuildingTypes(prop) },
    { value: getAverageEnergyClass(prop) },
    { value: getCertifications(prop) },
  ])
  const data = [headerRow, ...assetRows]

  await writeExcelFile(data, { fileName: `${title}.xlsx` })
}

import styled, { useTheme } from 'styled-components'
import { Link as UnstyledLink } from 'react-router-dom'
import { DonutChart as UnstyledDonutChart } from 'components/Chart'

const Link = styled(UnstyledLink)`
  display: block;
  margin-top: 1rem;
`

const Wrapper = styled.div`
  min-width: 0; // Hack to make chart resize properly
`

const DonutChart = styled(UnstyledDonutChart)`
  max-height: 15rem;
`

const QualificationPie = ({ values, detailsPath, title, className }) => {
  const theme = useTheme()

  const data = {
    labels: ['Uppfyller krav', 'Uppfyller ej krav', 'Data saknas'],
    datasets: [
      {
        data: values,
        backgroundColor: [
          theme.colors.brand,
          theme.colors.black,
          theme.colors.medium,
        ],
      },
    ],
  }

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'right',
        align: 'start',
      },
      datalabels: {
        display: true,
        color: theme.colors.white,
        formatter: val => (val ? `${(100 * val).toFixed()}%` : ''),
      },
    },
  }

  return (
    <Wrapper className={className}>
      {title && <h3>Datatäckning</h3>}
      <DonutChart data={data} options={options} />
      {detailsPath && <Link to={detailsPath}>Detaljer</Link>}
    </Wrapper>
  )
}

export default QualificationPie

import { getValue } from 'utils/data'
import { prefixStrings } from 'utils/string'

const viewModel = (data, qualificationResult) => {
  const buildingCount = Object.keys(data.buildings.items ?? {}).length
  return buildingCount === 0
    ? noBuildings(data, qualificationResult)
    : buildingCount === 1
    ? singleBuilding(
        data,
        ...Object.entries(data.buildings.items)[0],
        qualificationResult
      )
    : multipleBuildings(data, qualificationResult)
}

// Cirkusängen 6 has multiple buildings
// Solna Kassaskåpet 15 has one building
// Solna Gelbgjutaren 10 has no buildings

export default viewModel

const d1 = new Date('2029-01-01')
const d2 = new Date('2024-01-01')

const epc = ({ hasEpc, epcExpirationDate }, prefix) => ({
  label: 'Energideklaration',
  keys: prefixStrings(prefix, [
    'hasEpc',
    ...(!getValue(hasEpc)
      ? []
      : [
          'epcExpirationDate',
          'tempArea',
          ...(getValue(epcExpirationDate) <= d2
            ? ['specificEnergyDemand']
            : getValue(epcExpirationDate) <= d1
            ? ['energyClass', 'specificEnergyDemand']
            : ['energyClass', 'primaryEnergyDemand']),
          'mainHeatingType',
        ]),
  ]),
})

const noBuildings = data => {
  return {
    propertyData: {
      label: 'Fastighetsdata',
      keys: [
        'propertyId',
        'organization',
        'owner',
        'address',
        'isJointTaxed',
        'taxedValue',
        //...(getValue(data.isJointTaxed) ? [] : ['taxedValue']),
      ],
    },
  }
}

const singleBuilding = (data, buildingId, building) => {
  const prefix = `buildings.items.${buildingId}.`
  return {
    propertyData: {
      label: 'Fastighetsdata',
      keys: [
        'propertyId',
        'organization',
        'owner',
        // 'ownershipShare',
        'address',
        'isJointTaxed',
        'taxedValue',
        ...prefixStrings(prefix, [
          'buildingType',
          'floorArea',
          'yearOfConstruction',
          'yearOfModification',
          'taxedValue',
        ]),
      ],
    },
    epc: epc(building, prefix),
    // energyConsumption: {
    //   label: 'Energianvändning',
    //   keys: ['yearlyEnergyConsumption', 'energyConsumptionByType', 'energyMix'],
    // },
    hvac: {
      label: 'Värme, ventilation och Kylning',
      keys: prefixStrings(prefix, [
        'airPermeabilityTested',
        'thermography',
        'uMean',
        'thermographyAlternative',
        'cooling',
        'combinedHvacOutput',
        'automatedHvacControlSystem',
        'hvacMonitoringProcess',
        'energyPerformanceContract',
      ]),
    },
    // misc: {
    //   label: 'Analyser',
    //   keys: ['gwp', 'climateRiskAnalysis'],
    // },
  }
}

const multipleBuildings = (data, qualificationResult) => {
  return {
    propertyData: {
      label: 'Fastighetsdata',
      keys: [
        'propertyId',
        'organization',
        'owner',
        // 'ownershipShare',
        'address',
        'isJointTaxed',
        'taxedValue',
      ],
    },
    buildings: Object.fromEntries(
      Object.entries(data.buildings.items)
        .sort(([, b1], [, b2]) =>
          b1.houseNr.displayValue.localeCompare(b2.houseNr.displayValue)
        )
        .map(([id, building]) => [
          id,
          getBuilding(id, building, qualificationResult),
        ])
    ),
  }
}

const getBuilding = (id, data, { missing, inadequate }) => {
  const missingCount = Object.keys(missing).filter(key =>
    key.startsWith(`buildings.items.${id}`)
  ).length

  return {
    label: `Byggnad ${data.buildingId.displayValue}`,
    subtitle: `${data.energyClass.label}: ${
      data.energyClass.displayValue ?? '—'
    }`,
    status: missingCount ? `${missingCount} återstår` : 'Komplett',
    highlight: !!missingCount,
    collapsible: true,
    keys: prefixStrings(`buildings.items.${id}.`, [
      'houseNr',
      'buildingId',
      'isMainBuilding',
      'address',
      'buildingType',
      'floorArea',
      'yearOfConstruction',
      'yearOfModification',
      'taxedValue',
      ...(epc(data)?.keys ?? []),
      'airPermeabilityTested',
      'thermography',
      'uMean',
      'thermographyAlternative',
      'cooling',
      'combinedHvacOutput',
      'automatedHvacControlSystem',
      'hvacMonitoringProcess',
      'energyPerformanceContract',
    ]),
  }
}

import config from 'config'

const Debug = ({ value }) =>
  config.debug ? (
    <pre>
      {JSON.stringify(
        value,
        (k, v) => (v === undefined ? '[undefined]' : v),
        2
      )}
    </pre>
  ) : null

export default Debug

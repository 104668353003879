export const stripNewLines = () => (tree, file) => {
  traverse(tree, (node, parent) => {
    if (node.type === 'text' && node.value.match(/^\s*$/)) {
      parent.children.splice(parent.children.indexOf(node), 1)
    }
  })
}

const script = (regex, tagName) => () => (tree, file) => {
  traverse(tree, (node, parent) => {
    if (node.type === 'text') {
      let match
      const children = []
      let start = 0

      //eslint-disable-next-line no-cond-assign

      while ((match = regex.exec(node.value))) {
        children.push(
          textNode(
            node.value.slice(start, match.index),
            start,
            node.position.start
          )
        )
        children.push(
          childNode(tagName, match[1], match.index, node.position.start)
        )
        start = match.index + match[0].length
      }
      if (children.length) {
        children.push(
          textNode(
            node.value.slice(start),
            children[children.length - 1].position.end.offset,
            node.position.start
          )
        )
        parent.children.splice(parent.children.indexOf(node), 1, ...children)
      }
    }
  })
}

export const subscript = script(/_\{([A-Za-zÅÄÖåäö1-9+-]+)\}/g, 'sub')
export const superscript = script(/\^\{([A-Za-zÅÄÖåäö1-9+-]+)\}/g, 'sup')

const traverse = (node, cb, parent) => {
  if (node.children) {
    for (let i = node.children.length - 1; i >= 0; i--) {
      // console.log(i)
      traverse(node.children[i], cb, node)
    }
  } else {
    cb(node, parent)
  }
}

const textNode = (value, startOffset, start) => ({
  type: 'element',
  tagName: 'span',
  position: {
    start: {
      line: start.line,
      column: start.column + startOffset,
      offset: startOffset,
    },
    end: {
      line: start.line,
      column: start.column + startOffset + value.length,
      offset: startOffset + value.length,
    },
  },
  children: [
    {
      type: 'text',
      value: value,
      position: {
        start: {
          line: start.line,
          column: start.column + startOffset,
          offset: startOffset,
        },
        end: {
          line: start.line,
          column: start.column + startOffset,
          offset: startOffset + value.length,
        },
      },
    },
  ],
})

const childNode = (tagName, value, startOffset, start) => ({
  type: 'element',
  tagName,
  position: {
    start: {
      line: start.line,
      column: start.column + startOffset,
      offset: startOffset,
    },
    end: {
      line: start.line,
      column: start.column + startOffset + value.length + 3,
      offset: startOffset + value.length + 3,
    },
  },
  children: [
    {
      type: 'text',
      value: value,
      position: {
        start: {
          line: start.line,
          column: start.column + startOffset + 2,
          offset: startOffset + 2,
        },
        end: {
          line: start.line,
          column: start.column + startOffset + value.length + 2,
          offset: startOffset + value.length + 2,
        },
      },
    },
  ],
})

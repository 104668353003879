import { useTeam } from 'providers/Session'
import Card from 'components/Card'
import Loader from 'components/Loader'
import List from 'components/List'
import { Link } from 'react-router-dom'

const ListItem = ({ id, title }) => (
  <div>
    <Link to={`/asset/${id}`}>{title}</Link>
  </div>
)

const AssetList = () => {
  const { assets, loading } = useTeam()
  const items = assets
    ? Object.entries(assets ?? {}).map(([id, asset]) => {
        return {
          id,
          title: asset.propertyId ?? '',
        }
      })
    : undefined

  const filterFn =
    filterStr =>
    ({ title }) =>
      title.toLowerCase().includes(filterStr.toLowerCase())

  return (
    <Card title="Fastigheter">
      {loading ? (
        <Loader />
      ) : (
        <List
          data={items}
          filter={items.length > 7 ? filterFn : undefined}
          sort={({ title: t1 }, { title: t2 }) => t1.localeCompare(t2, 'sv')}
          limit={7}
          emptyLabel="(Inga fastigheter)"
        >
          <ListItem />
        </List>
      )}
    </Card>
  )
}

export default AssetList

import styled from 'styled-components'
import UnstyledPopup from 'components/Popup'
import UnstyledMarkdown from 'components/Markdown'

// Complicated styling because otherwise text jumps
const Popup = styled(UnstyledPopup)`
  display: inline;
  > button {
    display: inline;
    margin-left: 0;
    position: relative;
    > label {
      position: absolute;
      top: -1.3rem;
      font-size: 75%;
      font-weight: 500;
      margin: 0;
      cursor: pointer;
    }
  }
  margin-left: 0.2em;
`

const Markdown = styled(UnstyledMarkdown)`
  font-weight: 300;
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
  ol {
    padding-left: 1.5rem;
  }
`

const Tooltip = ({ contents, ...props }) => (
  <Popup label="?" {...props}>
    <Markdown>{contents}</Markdown>
  </Popup>
)

export default Tooltip

import { NakedButton } from 'components/Button'
import CertificationForm from './CertificationForm'
import { CodeError } from 'utils/error'
import BaseSection, {
  ToolsWrapper,
  StyledList,
  ItemTitle,
  Empty,
} from 'components/Section/BaseSection'

import Source from 'components/Section/Source'

const Tools = ({
  id,
  type,
  level,
  source,
  attachment,
  delete: _delete,
  attachments,
  filePath,
}) => {
  const canDelete = source.type === 'manual'
  return (
    <ToolsWrapper>
      <Source
        source={source}
        attachment={
          attachment
            ? { id: attachment, ...attachments[attachment] }
            : undefined
        }
        filePath={filePath}
      />
      {_delete && (
        <NakedButton
          icon="trash"
          kind="warning"
          disabled={!canDelete}
          title={
            canDelete
              ? 'Ta bort certifiering'
              : 'Kan inte ta bort certifiering från verifierad källa'
          }
          onClick={e => _delete(id, source)}
        />
      )}
    </ToolsWrapper>
  )
}

const CertificationsSection = ({
  title,
  certifications,
  attachments,
  editable,
  save,
  filePath,
}) => {
  const deleteItem = async (id, source) => {
    if (source.type !== 'manual') {
      throw new CodeError(
        'permission-denied',
        "Trying to delete a certification which wasn't added manually"
      )
    }
    save({
      attrName: 'certifications',
      itemId: id,
      value: undefined,
    })
  }

  const items = Object.entries(certifications.items ?? {}).map(
    ([id, item]) => ({ id, ...item })
  )

  return (
    <BaseSection title={title}>
      {items.length === 0 && !(certifications.editable && editable) && (
        <Empty>(Inga certifieringar)</Empty>
      )}
      <StyledList data={items}>
        <ItemTitle />
        <Tools
          delete={editable && deleteItem}
          attachments={attachments}
          filePath={filePath}
        />
      </StyledList>
      {certifications.editable && editable && (
        <CertificationForm save={save} attachments={attachments} />
      )}
    </BaseSection>
  )
}

export default CertificationsSection

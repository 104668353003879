import styled from 'styled-components'
import { theme, switchProp } from 'styled-tools'
import { BaseGroupSection } from 'components/Section'
import Markdown from 'components/Markdown'

const impactValues = {
  1: 'Låg',
  3: 'Medel',
  5: 'Hög',
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 1rem;
  border-bottom: 1px solid ${theme('colors.border')};
  > div {
    padding: 0.3rem;
  }
  > div:first-child {
    width: 10rem;
  }
  > div:nth-child(2) {
    width: 10rem;
  }
  > div:nth-child(3) {
  }
`

const Title = styled.div`
  padding-top: 0.5rem;
  font-weight: 500;
`

const Parameter = styled.div`
  font-weight: 500;
`

const Comment = styled(Markdown)``

const Circle = styled.div`
  position: relative;
  top: 0.1rem;
  height: 1rem;
  width: 1rem;
  border-radius: 0.5rem;
  background-color: black;
  margin-right: 0.5rem;
`

const ImpactWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  > ${Circle} {
    background-color: ${switchProp('$value', {
      1: theme('colors.ragGBg'),
      3: theme('colors.ragABg'),
      5: theme('colors.ragRBg'),
    })};
  }
`

const Impact = ({ value }) => (
  <ImpactWrapper $value={value}>
    <Circle />
    <div>{impactValues[value]}</div>
  </ImpactWrapper>
)

const ClimateScenario = ({ title, collapsible, expanded, data: scenario }) => {
  return (
    <BaseGroupSection
      title={title}
      collapsible={collapsible}
      expanded={expanded}
    >
      <Row>
        <Title>Parameter</Title>
        <Title>Påverkan</Title>
        <Title>Kommentar</Title>
      </Row>
      {Object.entries(scenario).map(
        ([key, { label, impact, comment }], idx) => (
          <Row key={idx}>
            <Parameter>{label}</Parameter>
            <Impact value={impact} />
            <Comment>{comment}</Comment>
          </Row>
        )
      )}
    </BaseGroupSection>
  )
}

export default ClimateScenario

import ErrorMessage from './ErrorMessage'
import * as Sentry from '@sentry/react'

const ErrorBoundary = props => (
  <Sentry.ErrorBoundary
    fallback={({ error }) => <ErrorMessage error={error} />}
    {...props}
  />
)

export default ErrorBoundary

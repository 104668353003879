import styled from 'styled-components'
import { sortByDate } from 'utils/data'
import { useTeam, useCases } from 'providers/Session'
import { useRecent } from 'providers/Recent'
import Loader from 'components/Loader'
import Page from 'components/Page'
import Debug from 'components/Debug'
import { BaseSection, StaticSections } from 'components/Section'
import List, { Source } from 'components/List'
import { NakedButton } from 'components/Button'

const CreditorWrapper = styled.div`
  h1 {
    margin-bottom: 2rem;
  }
`

const StatusWrapper = styled.div`
  div {
    text-align: right;
    &:first-child {
      font-weight: 500;
      margin-bottom: 1rem;
    }
    &:last-child {
      display: flex;
      justify-content: flex-end;
    }
  }
`

const Status = ({ className, id, timestamp, status, deleteItem }) => (
  <StatusWrapper className={className}>
    {status && <div>Status: {status.displayValue}</div>}
    {deleteItem && (
      <div>
        <NakedButton icon="trash" kind="light" onClick={e => deleteItem(id)} />
      </div>
    )}
  </StatusWrapper>
)

const StyledList = styled(List)`
  h2 {
    font-size: 1.3rem;
  }
  margin-bottom: 0.5rem;
`

const viewModel = {
  general: {
    label: 'Information om Kreditgivaren',
    keys: ['name', 'orgNr'],
  },
}

const CreditorDetails = ({ match }) => {
  const { id: creditorId } = match.params

  const { save, loadingTeam, ...teamData } = useTeam()
  const { data: cases, loading: loadingCases } = useCases(teamData) // TODO: filter
  const loading = loadingTeam || loadingCases

  const creditorData = teamData.creditors
    ? { id: creditorId, ...teamData.creditors[creditorId] }
    : null

  useRecent('creditor', creditorId, creditorData?.name.displayValue)

  const caseItems =
    Object.keys(cases ?? {}).length > 0
      ? Object.entries(cases)
          .map(([id, item]) => ({
            id,
            title: item.title.displayValue,
            subtitle: `${item.timestamp.displayValue}`,
            status: item.status,
            url: `/case/${id}`,
          }))
          .filter(({ status }) => status !== 'deleted')
          .sort(sortByDate('timestamp', true))
      : [{ id: 'none', title: '(Inga ärenden)' }]

  const saveProp = args =>
    save({ objectType: 'creditor', objectId: creditorId, ...args })

  return (
    <Page title={creditorData?.name.displayValue}>
      {loading ? (
        <Loader />
      ) : !creditorData ? (
        <div></div>
      ) : (
        <CreditorWrapper>
          <StaticSections
            viewModel={viewModel}
            data={creditorData}
            save={saveProp}
          />

          <BaseSection title="Ärenden">
            <StyledList data={caseItems}>
              <Source />
              <Status />
            </StyledList>
          </BaseSection>
        </CreditorWrapper>
      )}
      <Debug value={cases} />
    </Page>
  )
}

export default CreditorDetails

import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { theme, ifProp, withProp } from 'styled-tools'
import { tint } from 'polished'
import List from 'components/List'
import { InlineMarkdown } from 'components/Markdown'
import Tooltip from 'components/Tooltip'
import Card from 'components/Card'
import Value from 'components/Value'

const LabelWrapper = styled.div`
  width: ${ifProp('header', 'auto', '20rem')};
  flex-shrink: 0;
  flex-grow: ${ifProp('header', '1', '0')};
  margin-right: 1rem;
  font-weight: ${ifProp('header', 'bold', '500')};
  padding-left: ${withProp('indent', indent =>
    indent ? `${indent}rem` : '0'
  )};
  > ${InlineMarkdown} p {
    font-weight: 500;
  }
`

// header attr doesn't come through because of markdown. Might be fixable.
export const Label = ({ label, tooltip, indent, header, className }) => (
  <LabelWrapper indent={indent} header={header} className={className}>
    <InlineMarkdown>{label}</InlineMarkdown>
    {tooltip && <Tooltip contents={tooltip} />}
  </LabelWrapper>
)

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid ${theme('colors.borderLight')};
  padding: 0.6rem 1.2rem;
  &:last-child {
    border-bottom: none;
  }
  background-color: ${withProp(
    ['missing', 'inadequate'],
    (missing, inadequate) =>
      missing || inadequate ? theme('colors.highlight') : 'transparent'
  )};

  > ${Value} {
    margin-right: 1rem;
  }

  > div {
    margin-right: 0.5rem;
    &:last-child {
      margin-right: 0;
    }
  }

  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`

export const StyledList = styled(List)`
  border-bottom: 2px solid ${withProp('theme.colors.primary', tint(0.8))};
  &:last-child {
    border-bottom: none;
  }
  >li {
    padding: 0.6rem 1.2rem;
    margin: 0;
    >div, >button {
      &:first-child {
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    &["missing"] {
      background-color: pink;
    }
  }
`

export const ItemTitle = ({ displayValue, link }) => (
  <div>{link ? <Link to={link}>{displayValue}</Link> : displayValue}</div>
)

export const ToolsWrapper = styled.div`
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  button {
    margin-left: 0.7rem;
    margin-top: 0.15rem;
  }
`

export const ListFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem 2rem;
  border-top: 1ps solid ${theme('colors.primary')};
`

export const Empty = styled.div`
  padding: 1rem;
`

export const getTooltipText = ({ label, description, missing, inadequate }) =>
  `${
    description
      ? `

**Förklaring**

${description}`
      : ''
  }${
    missing
      ? '\n\n' +
        Object.values(missing)
          .map(
            ({ label, description }) => `**Krav: ${label}**\n\n ${description}`
          )
          .join('\n\n')
      : ''
  }${
    inadequate
      ? '\n\n' +
        Object.values(inadequate)
          .map(
            ({ label, description }) => `**Krav: ${label}**\n\n ${description}`
          )
          .join('\n\n')
      : ''
  }`

const BaseSection = ({
  id,
  title,
  subtitle,
  status,
  collapsible,
  expanded,
  children,
  className,
}) => (
  <Card
    className={className}
    collapsible={collapsible}
    expanded={expanded}
    id={id}
    title={title}
    subtitle={subtitle}
    status={status}
  >
    {children}
  </Card>
)

export default BaseSection

import { toTimestamp } from './firebase'

const prefix = {
  G: 1000000000,
  M: 1000000,
  k: 1000,
  m: 1 / 1000,
}

export const getScaleFactor = unit =>
  unit?.indexOf('SEK') === 1 ? 1 / prefix[unit.charAt(0)] : 1

export const scale = factor => ({
  read: value => (value ? value * factor : undefined),
  write: value => (value ? value / factor : undefined),
})

const defaultTransform = {
  read: v => v,
  write: v => v,
}

export const getTransform = ({ type, unit }) => {
  if (type === 'percentage') {
    return scale(100)
  }
  if (type === 'date') {
    return {
      read: v => v?.toISOString().slice(0, 10),
      write: v => toTimestamp(v),
    }
  }
  const scaleFactor = getScaleFactor(unit)
  if (scaleFactor !== 1) {
    return scale(scaleFactor)
  }
  return defaultTransform
}

import { createGlobalStyle } from 'styled-components'
import { theme } from 'styled-tools'
import { normalize } from 'polished'
import WebFont from 'webfontloader'

WebFont.load({
  google: {
    families: [
      'Josefin Sans:300,400,500,700',
      'Open Sans:300,300i,400,400i,500,500i,600,600i,700,700i',
    ],
  },
})

export default createGlobalStyle`
  ${normalize()};

  *,
  *:after,
  *:before {
    box-sizing: border-box;
    line-height: normal;
  }

  :root {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-smoothing: antialiased;
    letter-spacing: 0.015em;
    line-height: normal;
    color: ${theme('colors.primaryText')};
    overflow: hidden;
    height: 100%;
  }

  body {
    margin: 0;
    font-size: 1rem;
    text-align: left;
    overflow: hidden;
    background: #f7f7f7;
    height: 100%;
  }

  #root {
    height: 100%;
  }

  h1 {
    font-family: 'Josefin Sans';
    font-size: 3rem;
    font-weight: 500;
    color: ${theme('colors.primary')};
    margin: 0 0 1rem 0;
  }

  h2 {
    font-family: 'Josefin Sans';
    font-size: 1.7rem;
    margin: 0.2rem 0;
    font-weight: 400;
  }

  h3 {
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    color: ${theme('colors.primary')};
    margin: 0.1rem 0;
  }

  h4 {
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
  }

  p {
    font-size: 1rem;
    font-weight: 300;
    margin: 0.5rem 0;
  }

  strong {
    font-weight: 600;
  }

  a {
    color: ${theme('colors.primary')};
    text-decoration: none;
    font-weight: 400;
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }

  ul {
    margin: 0;
  }

  ol {
    margin: 0;
  }

  li {
    margin-bottom: 0.3em;
  }

  button {
    border-radius: 4px;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    padding: 0.9em 1.6em;
    cursor: pointer;
    color: ${theme('colors.primary')};
    background: transparent;
  }

  label {
    display: block;
    width: 100%;
    text-align: left;
    font-size: 0.8rem;
    margin: 0 0 0.25rem 0.1rem;
    @media (min-width: ${theme('breakpoints.sm')}) {
      margin-bottom: 0.5rem;
    }
  }

  input {
    display: block;
    width: 100%;
    text-align: left;
    padding: 0.4rem;
    border: 1px solid ${theme('colors.border')};
    border-radius: 4px;
    box-shadow: none;

    &[type='text'] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    &[type='number'] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &::placeholder {
      color: ${theme('colors.mediumLight')};
    }
  }


input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

  textarea {
    display: block;
    width: 100%;
    text-align: left;
    padding: 0.5rem;
    border: 1px solid ${theme('colors.border')};
    border-radius: 4px;
    box-shadow: none;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

`

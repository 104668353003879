import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { useAuth } from 'providers/Session'
import Button from 'components/Button'
import Icon from 'components/Icon'

const LoginLink = styled(Link)`
  font-size: 1rem;
  text-decoration: none !important;
  display: block;
  display: flex;
  flex-direction: row;
  align-items: center;
  div {
    margin-right: 0.5rem;
  }
`

const UserWrapper = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  label {
    margin: 0 0.5rem 0 0;
    font-size: 1rem;
  }
`

const HeaderButton = styled(Button)`
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  width: auto;
  height: auto;
  min-width: 2rem;
  color: ${theme('colors.medium')};
`

const UserMenu = props => {
  const { user, logout } = useAuth()
  const location = useLocation()

  return user ? (
    <UserWrapper>
      <label>{user.email}</label>
      <HeaderButton onClick={logout} title="Log out" icon="logout" />
    </UserWrapper>
  ) : (
    <UserWrapper>
      <LoginLink
        to={{
          pathname: '/login',
          state: { redirect: location.pathname },
        }}
        title="Log in"
      >
        <div>Log in</div>
        <Icon type="login" />
      </LoginLink>
    </UserWrapper>
  )
}

export default UserMenu

export const formatDate = date => {
  const now = new Date()
  if (now.getFullYear() !== date.getFullYear()) {
    return date.toLocaleDateString('sv')
  } else if (
    now.getMonth() !== date.getMonth() ||
    now.getDate() !== date.getDate()
  ) {
    return date.toLocaleDateString('sv', { day: 'numeric', month: 'short' })
  } else {
    return date.toLocaleTimeString('sv', { timeStyle: 'short' })
  }
}

export const addDates = (
  d1,
  years = 0,
  months = 0,
  days = 0,
  hours = 0,
  minutes = 0,
  seconds = 0
) => {
  const d = new Date(d1)
  d.setFullYear(d1.getFullYear() + years)
  d.setMonth(d1.getMonth() + months)
  d.setDate(d1.getDate() + days)
  d.setHours(d1.getHours() + hours)
  d.setMinutes(d1.getMinutes() + minutes)
  d.setSeconds(d1.getSeconds() + seconds)
  return d
}

import { createContext, useContext } from 'react'
import { nanoid } from 'utils/data'

const subscribers = {}

const subscribe = (topic, callback) => {
  const id = nanoid()
  subscribers[id] = { topic, callback }
  return id
}

const unsubscribe = id => {
  delete subscribers[id]
}

const fire = (_topic, exclude) => {
  Object.entries(subscribers)
    .filter(([id, { topic }]) => topic === _topic && id !== exclude)
    .forEach(([id, { callback }]) => callback())
}

const EventsContext = createContext({})

export const EventsProvider = ({ children }) => (
  <EventsContext.Provider value={{ subscribe, unsubscribe, fire }}>
    {children}
  </EventsContext.Provider>
)

export const useEvents = () => useContext(EventsContext)

import plan from './plan'
import riskAssessment from './riskAssessment'

const documentTransformers = {
  plan,
  riskAssessment,
}

const transform = (data, env) => documentTransformers[data.type](data, env)

export default transform

import { useState } from 'react'
import styled from 'styled-components'
import { pickBy } from 'lodash'
import { sortByDate, getValue } from 'utils/data'
import { useTeam, requestReport, useCases } from 'providers/Session'
import { useRecent } from 'providers/Recent'
import Loader from 'components/Loader'
import Page from 'components/Page'
import Debug from 'components/Debug'
import { BaseSection, StaticSections } from 'components/Section'
import List, { Source } from 'components/List'
import Button, { NakedButton } from 'components/Button'
import { statusLabels } from 'data/meta/case'
import NewPropertyButton from './NewPropertyButton'

const ReporterWrapper = styled.div`
  h1 {
    margin-bottom: 2rem;
  }
`

const StatusWrapper = styled.div`
  div {
    text-align: right;
    &:first-child {
      font-weight: 500;
      margin-bottom: 1rem;
    }
    &:last-child {
      display: flex;
      justify-content: flex-end;
    }
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin: 1rem;
  ${Button} {
    margin: 0;
  }
`

const Status = ({ className, id, timestamp, status, deleteItem }) => (
  <StatusWrapper className={className}>
    {status && <div>Status: {statusLabels[status]}</div>}
    {deleteItem && (
      <div>
        <NakedButton icon="trash" kind="light" onClick={e => deleteItem(id)} />
      </div>
    )}
  </StatusWrapper>
)

const StyledList = styled(List)`
  h2 {
    font-size: 1.3rem;
  }
  margin-bottom: 0.5rem;
`

const viewModel = {
  general: {
    label: 'Kundinformation',
    keys: ['name', 'orgNr', 'contact'],
  },
}

const useRequestReport = () => {
  const [state, setState] = useState('init')
  const mutate = async (...args) => {
    setState('loading')
    try {
      await requestReport(...args)
      setState('done')
    } catch (e) {
      console.error(e)
      setState('error')
    }
  }

  return {
    mutate,
    error: state === 'error',
    loading: state === 'loading',
    done: state === 'done',
  }
}

const ReporterDetails = ({ match }) => {
  const { id: reporterId } = match.params
  const teamData = useTeam()
  const {
    reporters,
    // cases: allCases,
    save,
    loading: loadingTeam,
    id: teamId,
  } = teamData

  const { loading: loadingCases, data: allCases } = useCases(teamData)

  const reporterData = reporters
    ? { id: reporterId, ...reporters[reporterId] }
    : null

  const cases = allCases
    ? pickBy(allCases, ({ reporter }) => getValue(reporter) === reporterId)
    : null

  useRecent('reporter', reporterId, reporterData?.name.displayValue)
  const loading = loadingTeam || loadingCases

  const caseItems =
    Object.keys(cases ?? {}).length > 0
      ? Object.entries(cases)
          .map(([id, item]) => ({
            id,
            title: item.title.displayValue,
            subtitle: item.timestamp.displayValue,
            status: item.status.value,
            url: `/case/${id}`,
            timestamp: getValue(item.timestamp),
          }))
          .filter(({ status }) => status !== 'deleted')
          .sort(sortByDate('timestamp', true))
      : [{ id: 'none', title: '(Inga ärenden)' }]

  const saveAttr = args =>
    save({ objectType: 'reporter', objectId: reporterId, ...args })

  const { loading: requestLoading, mutate: requestReport } = useRequestReport()

  return (
    <Page title={reporterData?.name.value}>
      <ButtonWrapper>
        <NewPropertyButton reporterId={reporterId} />
        <Button
          onClick={() =>
            requestReport({ teamId, reporterId, type: 'annualReport' })
          }
          disabled={requestLoading}
          loading={requestLoading}
        >
          Ny omföredragning
        </Button>
      </ButtonWrapper>
      {loading ? (
        <Loader />
      ) : !reporterData ? (
        <div></div>
      ) : (
        <ReporterWrapper>
          <StaticSections
            viewModel={viewModel}
            data={reporterData}
            save={saveAttr}
          />
          <BaseSection title="Ärenden">
            <StyledList data={caseItems}>
              <Source />
              <Status />
            </StyledList>
          </BaseSection>
          <Debug value={cases} />
        </ReporterWrapper>
      )}
    </Page>
  )
}

export default ReporterDetails

import styled from 'styled-components'
import { theme, ifProp } from 'styled-tools'

import { useTeam, useCases } from 'providers/Session'
import Card from 'components/Card'
import Loader from 'components/Loader'
import List from 'components/List'
import { Link as UnstyledLink } from 'react-router-dom'
import { isCaseOpen, getValue, sortByDate } from 'utils/data'

const Link = styled(UnstyledLink)`
  color: ${ifProp(
    '$closed',
    theme('colors.primaryLight'),
    theme('colors.primary')
  )};
`

const ListItem = ({ id, title, isOpen }) => (
  <div>
    <Link to={`/case/${id}`} $closed={!isOpen}>
      {title}
    </Link>
  </div>
)

const CaseList = () => {
  const { loading: loadingTeam, ...teamData } = useTeam()
  const { data: cases, loading: loadingCases } = useCases(teamData)
  const loading = loadingTeam || loadingCases
  const items = cases
    ? Object.entries(cases ?? {})
        .map(([id, _case]) => {
          return {
            id,
            title:
              `${_case.title.displayValue} ${
                isCaseOpen(_case) ? '' : `(${_case.status.displayValue})`
              }` ?? '',
            timestamp: getValue(_case.timestamp),
            isOpen: isCaseOpen(_case),
            status: _case.status,
          }
        })
        .filter(item => isCaseOpen(item))
        .sort(sortByDate('timestamp', false))
    : null

  const filterFn =
    filterStr =>
    ({ title }) =>
      title.toLowerCase().includes(filterStr.toLowerCase())
  return (
    <Card title="Pågående Ärenden">
      {loading ? (
        <Loader />
      ) : (
        <List
          data={items}
          filter={items.length > 7 ? filterFn : undefined}
          sort={sortByDate('timestamp', true)}
          limit={7}
          emptyLabel="(Inga pågående ärenden)"
        >
          <ListItem />
        </List>
      )}
    </Card>
  )
}

export default CaseList

import styled from 'styled-components'
import { InlineMarkdown } from 'components/Markdown'
const Unit = styled(({ unit, wrap, className }) => (
  <span className={className}>
    {wrap && <span>[</span>}
    <InlineMarkdown>{unit}</InlineMarkdown>
    {wrap && <span>]</span>}
  </span>
))`
  margin-left: 0.2rem;
`

export default Unit

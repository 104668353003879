import styled from 'styled-components'
import Page from 'components/Page'

const Wrapper = styled.div`
  min-width: 50vw;
  max-width: 30rem;
  margin: auto;
  height: 100vh;
`

const LogoWrapper = styled.div`
  margin-top: 6rem;
  display: flex;
  justify-content: center;
  img {
    width: 50%;
  }
  margin-bottom: 3rem;
`

const BigLogo = ({ src }) => (
  <LogoWrapper>
    <img src={src} alt="Sciigo Logo" />
  </LogoWrapper>
)

const Title = styled.h2`
  margin-bottom: 1rem;
`

const LoginWrapper = ({ title, children }) => (
  <Page title="Logga in" showTitle={false}>
    <Wrapper>
      <BigLogo src="/sciigo.svg" />
      <Title>{title}</Title>
      {children}
    </Wrapper>
  </Page>
)

export default LoginWrapper

export const ButtonRow = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
`

import { isArray } from 'lodash'

export const generateLegendLabelFromSeriesConfig = chart => {
  const data = chart.data.datasets.map((dataset, index) => ({
    text: dataset.label,
    fillStyle: isArray(dataset.backgroundColor)
      ? dataset.backgroundColor[dataset.backgroundColor.length - 1]
      : dataset.backgroundColor,
    strokeStyle: isArray(dataset.borderColor)
      ? dataset.borderColor[dataset.backgroundColor.length - 1]
      : dataset.borderColor,
    lineWidth: dataset.borderWidth ?? 3,
    pointStyle: dataset.type === 'line' ? 'line' : 'rect',
    hidden: false,
    lineCap: 'round',
    maxHeight: 2000,
  }))
  return data
}

import { useState, useEffect } from 'react'
import { getFirebase } from 'utils/firebase'
import { CodeError } from 'utils/error'

const useBatchFunction = () => {
  const [state, setState] = useState({})
  const { batchFunctionId } = state

  useEffect(() => {
    const { db } = getFirebase()
    const unsubscribe = batchFunctionId
      ? db.doc(`functions/${batchFunctionId}`).onSnapshot(doc => {
          const data = doc.data()
          if (data.error) {
            setState(data)
          } else if (data.done) {
            setState(data)
          } else {
            setState({ batchFunctionId, ...data, loading: true })
          }
        })
      : null

    return () => {
      unsubscribe?.()
    }
  }, [batchFunctionId])

  const run = async (path, data, args) => {
    try {
      setState({ loading: true })
      const { functions } = getFirebase()
      if (batchFunctionId) {
        throw new CodeError(
          'resource-exhausted',
          `Batch function ${path} already running, no parallell calls (yet)`
        )
      }
      const fn = functions.httpsCallable(path, args)
      const result = await fn(data)
      if (!result?.data?.fnid) {
        throw new CodeError(
          'internal',
          `Result from callable function ${path} is not a function id: ${JSON.stringify(
            result
          )}`
        )
      }
      setState({ batchFunctionId: result.data.fnid, loading: true })
    } catch (e) {
      setState({ error: 'Nåt gick fel' })
    }
  }
  return { run, ...state }
}

export default useBatchFunction

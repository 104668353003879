import { useEffect, useRef } from 'react'
import { getFirebase } from 'utils/firebase'
import { Formik, Form } from 'formik'
import FormField from 'components/form/FormField'
import Button, { ButtonLink } from 'components/Button'
import { Errors } from 'components/form'
import LoginWrapper, { ButtonRow } from './LoginWrapper'
import { track } from 'utils/tracking'

export const Login = ({ history, location, title, subtitle, submitLabel }) => {
  const emailField = useRef()
  useEffect(() => emailField.current?.focus())
  const { auth } = getFirebase()

  return (
    <LoginWrapper title="Logga in">
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
          try {
            if (!values.email) {
              setErrors({ err: { message: 'Email saknas' } })
              return
            }
            const { email, password } = values
            await auth.signInWithEmailAndPassword(email, password)
            track('login')
            setSubmitting(false)
            setErrors({})
            history.replace('/')
          } catch (e) {
            console.error('Error in login form submit, ', e)
            if (
              [
                'auth/wrong-password',
                'auth/no-such-account',
                'auth/user-not-found',
                'auth/invalid-email',
              ].includes(e.code)
            ) {
              setErrors({ err: { message: 'Felaktig email eller lösenord' } })
            } else {
              setErrors({ err: { message: `Okänt fel: "${e.message}"` } })
            }
          }
        }}
      >
        {({
          isSubmitting,
          errors,
          submitForm,
          resetForm,
          values,
          setValues,
        }) => (
          <Form noValidate>
            <Errors errors={errors} />
            <FormField
              name="email"
              type="email"
              label="Email"
              tabIndex="1"
              inputRef={emailField}
            />
            <FormField
              name="password"
              type="password"
              label="Password"
              tabIndex="2"
            />
            <ButtonRow>
              <ButtonLink to="/forgot" tabIndex="4" inverted={true}>
                Glömt lösenord?
              </ButtonLink>
              <Button
                disabled={isSubmitting || Object.keys(errors).length}
                type="submit"
                tabIndex="3"
              >
                Logga in
              </Button>
            </ButtonRow>
          </Form>
        )}
      </Formik>
    </LoginWrapper>
  )
}

export default Login

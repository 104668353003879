import { useMemo } from 'react'
import { pickBy } from 'lodash'
import styled from 'styled-components'
import { useTeam, useAssets, useDocuments } from 'providers/Session'
import { getValue } from 'utils/data'

import Card from 'components/Card'
import Loader from 'components/Loader'
import KpiTable from 'visualization/KpiTable'
import { getAverageEnergyClass } from 'visualization/utils'

const Wrapper = styled.div`
  padding: 0.5rem 1rem;
  a {
    text-align: right;
  }
`

const Report = () => {
  const teamData = useTeam()
  const { data: assets } = useAssets(teamData)
  const { data: documents } = useDocuments([], teamData)

  const plans = pickBy(documents, d => d.type === 'plan')
  const riskAssessments = pickBy(documents, d => d.type === 'riskAssessment')

  const assetStr = JSON.stringify(assets)
  const plansStr = JSON.stringify(plans)
  const raStr = JSON.stringify(riskAssessments)
  const report = useMemo(() => {
    if (!assets) {
      return {}
    }
    const kpis = Object.values(getKpis(assets))
    return {
      kpis,
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetStr, plansStr, raStr])

  return (
    <Card title="Översikt" link="/report">
      {!report ? (
        <Loader />
      ) : (
        <Wrapper>
          <KpiTable items={report.kpis} />
        </Wrapper>
      )}
    </Card>
  )
}

export default Report

const getKpis = assets => {
  const allBuildings = Object.values(assets).flatMap(asset =>
    Object.values(asset.buildings.items)
  )

  return {
    numberOfProperties: {
      label: 'Antal fastigheter',
      value: Object.keys(assets).length,
    },
    withCert: {
      label: 'Certifierade',
      value: Object.values(assets).filter(
        a => Object.values(a.certifications.items).length
      ).length,
      // `${(
      //   (100 *
      //     Object.values(assets).filter(
      //       a => Object.values(a.certifications.items).length
      //     ).length) /
      //   Object.keys(assets).length
      // ).toFixed()}%`,
      indent: 1,
    },

    numberOfBuildings: {
      label: 'Antal byggnader',
      value: allBuildings.length,
    },
    withEnergyClass: {
      label: 'Med energiklass',
      value: allBuildings.filter(b => getValue(b.energyClass)).length,
      // `${(
      //   (100 * allBuildings.filter(b => getValue(b.energyClass)).length) /
      //   allBuildings.length
      // ).toFixed()} %`,
      indent: 1,
    },
    avgEnergyClass: {
      label: 'Energiklass snitt',
      value: getAverageEnergyClass(Object.values(assets)),
    },
  }
}

import { createContext, useEffect, useState, useContext } from 'react'
import { toPlural, getOrgName, getOrgNr, nanoid } from 'utils/data'
import { addTeamMeta } from 'data/meta'
import {
  getFirebase,
  cleanData,
  FieldValue,
  convertDates,
} from 'utils/firebase'
import { setContext } from 'utils/tracking'
import { UserContext } from './User'
import { AuthContext } from './Auth'

export const TeamContext = createContext({
  loading: true,
})

export const TeamProvider = ({ children }) => {
  const { teams, activeTeam } = useContext(UserContext)
  const team = teams
    ? teams[activeTeam]
      ? activeTeam
      : Object.keys(teams).sort()[0]
    : undefined

  const { refreshAuth } = useContext(AuthContext)

  const [state, setState] = useState({
    loading: true,
  })

  const { db } = getFirebase()

  useEffect(() => {
    const unsubscribe = team
      ? db
          .collection('teams')
          .doc(team)
          .onSnapshot(async doc => {
            if (!doc.data()) {
              await refreshAuth()
            }

            const teamData = addTeamMeta(
              { id: doc.id, ...convertDates(doc.data()) },
              ['assets', 'plans', 'cases']
            )
            await setContext('team', { id: team, name: getOrgName(teamData) })
            setState({
              loading: false,
              id: team,
              name: getOrgName(teamData),
              orgNr: getOrgNr(teamData),
              ...teamData,
            })
          })
      : null

    return () => {
      unsubscribe?.()
      setState({ loading: true })
    }
  }, [db, team, refreshAuth])

  const set = data =>
    db.collection('teams').doc(team).set(data, { merge: true })

  // args = {
  //   objectType,
  //   objectId,
  //   attrName,
  //   subcollectionId,
  //   itemId,
  //   value,
  //   attachment,
  //   comment,
  // }

  const save = args =>
    args.subcollectionId
      ? saveSubcollectionItemAttr(args)
      : args.itemId
      ? saveListItem(args)
      : saveAttr(args)

  const saveAttr = ({
    objectType,
    objectId,
    attrName,
    value,
    attachment,
    comment,
  }) =>
    set({
      [toPlural(objectType)]: {
        [objectId]: {
          [attrName]: cleanData(
            {
              value,
              attachment: attachment || undefined,
              comment: comment || undefined,
            },
            true
          ),
        },
      },
    })

  const saveListItem = ({
    objectType,
    objectId,
    attrName,
    itemId,
    value,
    attachment,
    comment,
  }) =>
    set({
      [toPlural(objectType)]: {
        [objectId]: {
          [attrName]: {
            items: {
              [itemId]: value
                ? cleanData(
                    {
                      value,
                      attachment: attachment || undefined,
                      comment: comment || undefined,
                    },
                    true
                  )
                : FieldValue.delete(),
            },
          },
        },
      },
    })

  const saveSubcollectionItemAttr = ({
    objectType,
    objectId,
    subcollectionId,
    attrName,
    itemId,
    value,
    attachment,
    comment,
  }) =>
    set({
      [toPlural(objectType)]: {
        [objectId]: {
          [subcollectionId]: {
            items: {
              [itemId]: value
                ? cleanData(
                    {
                      value,
                      attachment: attachment || undefined,
                      comment: comment || undefined,
                    },
                    true
                  )
                : FieldValue.delete(),
            },
          },
        },
      },
    })

  const addSubcollectionItem = ({ objectType, objectId, subcollectionId }) =>
    set({
      [toPlural(objectType)]: {
        [objectId]: {
          [subcollectionId]: {
            items: {
              [nanoid()]: {},
            },
          },
        },
      },
    })

  const deleteSubcollectionItem = ({
    objectType,
    objectId,
    subcollectionId,
    itemId,
  }) =>
    set({
      [toPlural(objectType)]: {
        [objectId]: {
          [subcollectionId]: {
            items: {
              [itemId]: FieldValue.delete(),
            },
          },
        },
      },
    })

  return (
    <TeamContext.Provider
      value={{
        ...state,
        set,
        save,
        addSubcollectionItem,
        deleteSubcollectionItem,
      }}
    >
      {children}
    </TeamContext.Provider>
  )
}

import styled from 'styled-components'
import { theme, ifProp } from 'styled-tools'
import { get } from 'lodash'
import BaseSection from './BaseSection'
import StaticSection from './StaticSection'

export const BaseGroupSection = styled(BaseSection)`
  margin: 0;
  hgroup {
    border-radius: 0;
    background: ${ifProp(
      'highlight',
      theme('colors.highlight'),
      theme('colors.primaryThin')
    )};
    border-top: 1px solid ${theme('colors.primaryMediumLight')};
    color: ${theme('colors.primary')};
    h2 {
      font-size: 1.3rem;
    }
  }
  border-radius: 0;
  &:last-child {
    border-radius: 1rem;
  }
`

export const StaticGroupSection = styled(StaticSection)`
  margin: 0;
  hgroup {
    border-radius: 0;
    background: ${ifProp(
      'highlight',
      theme('colors.highlight'),
      theme('colors.primaryThin')
    )};
    border-top: 1px solid ${theme('colors.primaryMediumLight')};
    color: ${theme('colors.primary')};
    h2 {
      font-size: 1.3rem;
    }
  }
  border-radius: 0;
  &:last-child {
    border-radius: 1rem;
  }
`

export const StaticGroupSections = ({
  title,
  viewModel,
  data,
  editable = true,
  save,
  filePath,
  qualificationResults,
}) => (
  <BaseSection title={title}>
    {Object.entries(viewModel).map(([sectionId, meta], idx) => (
      <StaticGroupSection
        key={idx}
        title={meta.label}
        subtitle={meta.subtitle}
        status={meta.status}
        highlight={meta.highlight}
        collapsible={meta.collapsible}
        attributes={meta.keys.map(key => ({
          id: key,
          ...get(data, key),
          editable: get(data, key).editable && editable,
          missing: qualificationResults?.missing[key],
          inadequate: qualificationResults?.inadequate[key],
        }))}
        attachments={data.attachments?.items}
        save={save}
        filePath={filePath}
      />
    ))}
  </BaseSection>
)
